<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table #sortTable [nzData]="getConfig" nzTableLayout="fixed" [nzScroll]="{ y: '50vh' }" nzSize="small"
          [nzLoading]="isLoading" [nzTitle]="headerTable" [nzPageSize]="pageSize">
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumn">
                <th [nzWidth]="column.width" [nzSortFn]="column.compare" [nzSortPriority]="column.priority">
                  {{ column.title }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of sortTable.data">
              <td>
                <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Configuration">
                  <button class="btn btn-sm btn-light mr-2" (click)="viewConfig(data.configuration)">
                    <i class="fe fe-eye"></i>
                  </button>
                </span>
                <!-- <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Edit Configuration">
                  <button class="btn btn-sm btn-light mr-2" (click)="updateConfig(data.configuration)">
                    <i class="fe fe-more-vertical"></i>
                  </button>
                </span>
                <span *ngIf="data.configuration !== 'Company' else elseBlock" zTooltipPlacement="top" nz-tooltip
                  nzTooltipTitle="Delete Configuration">
                  <button class="btn btn-sm btn-light mr-2" (click)="deleteConfig(data.configuration)">
                    <i class="fe fe-trash-2"></i>
                  </button>
                </span>
                <ng-template #elseBlock>
                  <span zTooltipPlacement="top" nz-tooltip nzTooltipTitle="Can't Delete This Configuration">
                    <button class="btn btn-sm btn-light mr-2" [disabled]="true">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </span>
                </ng-template> -->
                <span style="margin: 0 2px;"></span>
              </td>
              <td>{{ data.configuration }}</td>
              <td>{{ data.created_by }}</td>
              <td>{{ data.updated_by }}</td>
              <td>{{ data.created_at }}</td>
              <td>{{ data.updated_at }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<ng-template #headerTable>
  <nz-spin [nzSpinning]="isDownloading">
    <div class="row justify-content-between">
      <div class="col">
        <!-- <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Add Configuration">
          <a class="btn btn-lg btn-light mr-2" (click)="addConfig()">
            <i class="fe fe-plus-circle"></i>
          </a>
        </span> -->
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Refresh Data">
          <a class="btn btn-lg btn-light mr-2" (click)="ngOnInit()">
            <i class="fe fe-refresh-cw"></i>
          </a>
        </span>
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Export Config">
          <a class="btn btn-lg btn-light mr-2" (click)="exportConfig()">
            <i class="fe fe-download"></i>
          </a>
        </span>
      </div>

      <div class="col">
        <form nz-form [nzLayout]="'inline'" [formGroup]="filterForm" (ngSubmit)="searchConfig()"
          style="display: flex; flex-direction: row; justify-content: flex-end;">
          <nz-form-label nzFor="company" class="text-left">Company</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <nz-select id="company" style="width: 130px;" formControlName="company">
                <ng-container *ngFor="let item of companyData">
                  <nz-option [nzValue]="item" [nzLabel]="item"></nz-option>
                </ng-container>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <nz-input-group id="search" style="width: 150px;">
                <input type="text" nz-input placeholder="Search" style=" border-radius: 8px;"
                  formControlName="search" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <button type="submit" class="btn btn-primary">
                Search
              </button>
            </nz-form-control>
          </nz-form-item>
        </form>
      </div>
    </div>
  </nz-spin>
</ng-template>


<router-outlet></router-outlet>


<nz-modal [(nzVisible)]="isAddConfigVisible" [nzTitle]="modalTitle" [nzContent]="modalContent" [nzFooter]="modalFooter"
  (nzOnCancel)="handleAddCancel()">
  <ng-template #modalTitle>Add Configuration</ng-template>

  <ng-template #modalContent>
    <form nz-form [formGroup]="addForm" (ngSubmit)="apply()">
      <nz-form-label nzFor="new_config" class=" text-left">Configuration</nz-form-label>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group id="new_config">
            <input type="text" nz-input formControlName="new_config" style=" border-radius: 8px;" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleAddCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="handleAddOk()" [nzLoading]="isAddConfigConfirm">Confirm</button>
  </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="isEditConfigVisible" [nzTitle]="editModalTitle" [nzContent]="editModalContent"
  [nzFooter]="editModalFooter" (nzOnCancel)="handleEditCancel()">
  <ng-template #editModalTitle>
    Edit Configuration
  </ng-template>

  <ng-template #editModalContent>
    <form nz-form [formGroup]="editForm">

      <nz-form-label nzFor="new_config" class=" text-left">Configuration</nz-form-label>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group id="new_config">
            <input type="text" nz-input [(ngModel)]="displayedConfig" formControlName="new_config"
              style=" border-radius: 8px;" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>

  <ng-template #editModalFooter>
    <button nz-button nzType="default" (click)="handleEditCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="handleEditOk()" [nzLoading]="isEditConfigConfirm">Confirm</button>
  </ng-template>
</nz-modal>


<ng-template #toasterConfig>
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon">
        <span nz-icon [nzType]="iconConfig" [ngStyle]="{ color: colorConfig }"></span>
      </span>
      <div class="ant-notification-notice-message">Message :</div>
      <div class="ant-notification-notice-description">
        {{messageConfig}}
      </div>
    </div>
  </div>
</ng-template>