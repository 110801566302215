<nz-spin [nzSpinning]="isLoading">
    <form nz-form [formGroup]="form">
        <div class="col-6">
            <nz-form-item>
                <nz-form-label nzFor="name" class="text-left" nzRequired>Name</nz-form-label>
                <nz-form-control nzErrorTip="Please input name correctly!">
                    <input nz-input id="name'" formControlName="name" placeholder="Input name ..." />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div class="row">
            <div class="col-12">
                <div formGroupName="output">
                    <nz-tabset (nzSelectChange)="newTab($event)" nzType='card'>
                        <nz-tab *ngFor='let tab of tabs; let i = index' [nzTitle]='tab'>
                            <div [formGroupName]="tab">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="col-6">
                                            <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="checked($event)">
                                                <label nz-checkbox formControlName="holiday" nzValue=true>Tidak
                                                    Kerja</label>
                                            </nz-checkbox-wrapper>
                                        </div>
                                    </div>
                                    <nz-divider></nz-divider>
                                    <div *ngIf="!val; else elseBlock">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-4">
                                                    <nz-form-item>
                                                        <nz-form-label nzFor="timeIn" class="text-left" nzRequired>Time
                                                            In
                                                        </nz-form-label>
                                                        <nz-form-control nzErrorTip="Please enter a valid time">
                                                            <div>
                                                                <nz-time-picker id="'timeIn'"
                                                                    (ngModelChange)="time_in($event)"
                                                                    formControlName="timeIn" nzFormat="HH:mm">
                                                                </nz-time-picker>
                                                            </div>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div class="col-7">
                                                    <nz-form-item>
                                                        <nz-form-label nzFor="status" class="text-left" nzRequired>Time
                                                            Out
                                                        </nz-form-label>
                                                        <nz-form-control nzErrorTip="Please enter a valid time">
                                                            <div>
                                                                <nz-time-picker id="'timeOut'"
                                                                    (ngModelChange)="time_out($event)"
                                                                    formControlName="timeOut" nzFormat="HH:mm">
                                                                </nz-time-picker>
                                                            </div>
                                                        </nz-form-control>
                                                        <nz-form-control nzErrorTip="Select available mode!">
                                                            <nz-select (ngModelChange)="clicked($event)"
                                                                formControlName="status"
                                                                nzPlaceHolder="Choose Day Status">
                                                                <nz-option nzLabel="Same Day" [nzValue]="false">
                                                                </nz-option>
                                                                <nz-option nzLabel="Next Day" [nzValue]="true">
                                                                </nz-option>
                                                            </nz-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #elseBlock>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-4">
                                                    <nz-form-item>
                                                        <nz-form-label nzFor="timeIn" class="text-left">Time In
                                                        </nz-form-label>
                                                        <div>
                                                            <nz-time-picker id="'timeIn'" formControlName="timeIn"
                                                                nzDisabled nzFormat="HH:mm"></nz-time-picker>
                                                        </div>
                                                    </nz-form-item>
                                                </div>
                                                <div class="col-7">
                                                    <nz-form-item>
                                                        <nz-form-label nzFor="timeOut" class="text-left">Time Out
                                                        </nz-form-label>
                                                        <div>
                                                            <nz-time-picker id="'timeOut'" formControlName="timeOut"
                                                                nzDisabled nzFormat="HH:mm"></nz-time-picker>
                                                        </div>
                                                        <nz-form-control nzErrorTip="Select available mode!">
                                                            <nz-select formControlName="status" nzDisabled
                                                                nzPlaceHolder="Choose Day Status">
                                                                <nz-option nzLabel="Same Day" [nzValue]="false">
                                                                </nz-option>
                                                                <nz-option nzLabel="Next Day" [nzValue]="true">
                                                                </nz-option>
                                                            </nz-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </nz-tab>
                    </nz-tabset>
                </div>
            </div>
        </div>
    </form>
</nz-spin>