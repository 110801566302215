import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { CTAService } from 'src/app/services/cta.service'

@Component({
  selector: 'app-export-business-inquiry',
  templateUrl: './export-business-inquiry.component.html',
  styleUrls: ['./export-business-inquiry.component.css'],
})
export class ExportBusinessInquiryComponent implements OnInit {
  isLoading: boolean = false
  form: FormGroup
  constructor(
    private fb: FormBuilder,
    private ctaService: CTAService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
  ) {
    this.form = this.fb.group({
      range_date: [null, Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
  }

  submitForm(): boolean {
    this.isLoading = true
    var blankPage = window.open('http://example.com/waiting.html', '_blank')
    blankPage!.document.write('Loading preview...')

    if (this.form.valid) {
      this.ctaService.exportBusinessInquiry(this.form.get('range_date').value).subscribe(
        r => {
          this.isLoading = false
          blankPage!.location.href = r.link
          this.notification.success('Success', r.message)
        },
        err => {
          blankPage.close()
          this.isLoading = false
          this.notification.error('Error', err.error.message)
        },
      )

      return true
    } else {
      this.msg.error('Please Input the Date Range')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }
}
