<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table
          #fixedTable
          class="table-Employeeing"
          [nzData]="listOfData"
          [nzFrontPagination]="false"
          [nzLoading]="isLoading"
          [nzTotal]="totalData"
          [nzPageSize]="pageSize"
          [nzPageIndex]="pageIndex"
          nzShowPagination
          nzShowSizeChanger
          [nzPageSizeOptions]="[25, 50, 100, 250]"
          (nzQueryParams)="onQueryParamsChange($event)"
          [nzScroll]="{ y: '40vh' }"
          [nzTitle]="headerTable"
          nzSize="small"
        >
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <th
                  *ngIf="column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzSortFn]="column.sortFn"
                  [(nzSortOrder)]="column.sortOrder"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
                <th
                  *ngIf="!column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of fixedTable.data">
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <td *ngIf="column.key == 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Employee">
                    <a class="btn btn-sm btn-light mr-2" (click)="viewEmployee(data.code)">
                      <i class="fe fe-eye"></i>
                    </a>
                  </span>
                </td>
                <td *ngIf="column.key != 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  {{ data[column.key] }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<ng-template #headerTable>
  <div class="row justify-content-between">
    <div class="col">
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Add Employee">
        <a class="btn btn-lg btn-light mr-2" (click)="addEmployee(tplFooter)">
          <i class="fe fe-plus-circle"></i>
        </a>
      </span>
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View placement location">
        <a class="btn btn-lg btn-light mr-2" (click)="viewPlacement()">
          <i class="fe fe-map-pin"></i>
        </a>
      </span>
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Employee Type">
        <a class="btn btn-lg btn-light mr-2" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
          <i class="fe fe-users"></i>
        </a>
      </span>
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Employee Status">
        <a
          class="btn btn-lg btn-light mr-2"
          nz-dropdown
          [nzDropdownMenu]="menuStatus"
          nzTrigger="click"
        >
          <i nz-icon nzType="control" nzTheme="outline"></i>
        </a>
      </span>
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Restart Filter & Sort">
        <a class="btn btn-lg btn-light mr-2" (click)="reset()">
          <i class="fe fe-refresh-cw"></i>
        </a>
      </span>
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Export Employee">
        <a class="btn btn-lg btn-light mr-2" (click)="exportEmployee()">
          <i class="fe fe-download"></i>
        </a>
      </span>
    </div>
    <div class="col">
      <form
        nz-form
        [nzLayout]="'inline'"
        [formGroup]="form"
        (ngSubmit)="search()"
        class="float-right"
      >
        <nz-form-item>
          <nz-form-label nzFor="prefix" class="text-left">Search</nz-form-label>
          <nz-form-control>
            <!-- <nz-select id="prefix" [formControl]="form.controls['prefix']" nzPlaceHolder="Select Prefix ..."
              style="width: 150px;">
              <nz-option nzValue="name" nzLabel="Name"></nz-option>
              <nz-option nzValue="code" nzLabel="Code"></nz-option>
              <nz-option nzValue="position" nzLabel="Position"></nz-option>
              <nz-option nzValue="role" nzLabel="Role"></nz-option>
              <nz-option nzValue="username" nzLabel="Username"></nz-option>
            </nz-select> -->
            <input [formControl]="form.controls['keyword']" nz-input placeholder="Keyword ..." />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
              Search
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</ng-template>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <ng-container *ngFor="let menu of typeEmployee">
      <li
        nz-menu-item
        [nzSelected]="menu.selected"
        [nzDisabled]="menu.disabled"
        (click)="changeType(menu)"
      >
        {{ menu.title }}
      </li>
    </ng-container>
  </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #menuStatus="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <ng-container *ngFor="let menu of statusEmployee">
      <li
        nz-menu-item
        [nzSelected]="menu.selected"
        [nzDisabled]="menu.disabled"
        (click)="changeStatus(menu)"
      >
        {{ menu.title }}
      </li>
    </ng-container>
  </ul>
</nz-dropdown-menu>
