<nz-tabset>
    <nz-tab nzTitle="User Lists">
      <ng-template nz-tab>
        <app-new-user-lists></app-new-user-lists>
      </ng-template>
    </nz-tab>
    <nz-tab nzTitle="Configs">
      <ng-template nz-tab>
        <app-new-user-configs></app-new-user-configs>
      </ng-template>
    </nz-tab>
  </nz-tabset>