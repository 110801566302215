<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu">
    <ng-container *ngIf="(profile_img != null); else elseBlock">
      <nz-avatar nzIcon="user" [nzSrc]=profile_img.url [nzShape]="'square'" [nzSize]="'large'" class="avatar"></nz-avatar>
    </ng-container>
    <ng-template #elseBlock>
      <nz-avatar nzIcon="user" nzSrc='https://sunshineapi.onindonesia.id/api/employee/image/default.png' [nzShape]="'square'" [nzSize]="'large'" class="avatar"></nz-avatar>
    </ng-template>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <strong>{{ 'topBar.profileMenu.hello' | translate }}, {{ name || 'Anonymous' }}</strong>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <div>
          <strong class="mr-1"> {{ 'topBar.profileMenu.username' | translate }}: </strong>
          {{ username || '—' }}
          <br />
          <strong> {{ 'topBar.profileMenu.role' | translate }}: </strong>
          {{ role || '—' }}
          <br />
          <strong class="mr-1"> {{ 'topBar.profileMenu.code' | translate }}: </strong>
          {{ code || '—' }}
        </div>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <div>
          <strong>{{ 'topBar.profileMenu.email' | translate }}: </strong>
          {{ email || '—' }}
          <br />
          <strong>{{ 'topBar.profileMenu.phone' | translate }}: </strong>
          {{ phone || '—' }}
        </div>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item href="javascript: void(0);" (click)="editProfile()">
        <a>
          <i class="fe fe-user mr-2"></i>
          {{ 'topBar.profileMenu.editProfile' | translate }}
        </a>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item href="javascript: void(0);" (click)="logout()">
        <a>
          <i class="fe fe-log-out mr-2"></i>
          {{ 'topBar.profileMenu.logout' | translate }}
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
