import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzTableQueryParams, NzTableFilterList, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormGroup } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { ScheduleRoomService } from 'src/app/services/schedule-room.service'
import { ScheduleRoom } from 'src/app/services/interface/schedule-room.model'
import { QueryTable } from 'src/app/services/interface/general.model'
import { AddScheduleComponent } from './add-schedule/add-schedule.component'
import { EditScheduleComponent } from './edit-schedule/edit-schedule.component'
import { DatePipe } from '@angular/common'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}
@Component({
  selector: 'app-schedule-room-management',
  templateUrl: './schedule-room.component.html',
  styleUrls: ['./schedule-room.component.scss'],
})
export class ScheduleRoomManagementComponent implements OnInit {
  listOfData: ScheduleRoom[] = []

  isLoading: boolean = false
  totalData: number = 0
  pageSize: number = 5
  pageIndex: number = 1

  user: any

  calendarStart: any
  calendarEnd: any

  rangeStart: any
  rangeEnd: any

  endGame: any
  startGame: any

  form: FormGroup
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private scheduleRoomService: ScheduleRoomService,
    private notification: NzNotificationService,
  ) {
    this.scheduleRoomService.username().subscribe(data => {
      this.user = data
    })
  }

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  pipe = new DatePipe('en-Us')
  selectedValue = new Date()
  valueBefore = new Date()

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '55px',
    },
    {
      name: 'Meeting Room',
      key: 'meeting_room',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '170px',
    },
    {
      name: 'Booked By',
      key: 'username',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '80px',
    },
    {
      name: 'Division',
      key: 'division',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '110px',
    },
    {
      name: 'Date Start',
      key: 'date_start',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Date End',
      key: 'date_end',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Time Start',
      key: 'time_start',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '65px',
    },
    {
      name: 'Time End',
      key: 'time_end',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '65px',
    },
    {
      name: 'Updated By',
      key: 'updated_by',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '80px',
    },
  ]

  ngOnInit(): void {
    this.fetchScheduleRoom()
    this.fetchCalendarStart()
    this.fetchCalendarEnd()
  }

  fetchScheduleRoom() {
    this.isLoading = true
    this.scheduleRoomService.viewlistScheduleRoom(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      this.listOfData = r.data
      this.totalData = r.total
    })
  }

  fetchCalendarStart() {
    this.isLoading = true
    this.scheduleRoomService
      .calendarStart(
        this.pipe.transform(this.selectedValue, 'yyyy'),
        this.pipe.transform(this.selectedValue, 'MM'),
      )
      .subscribe(r => {
        this.calendarStart = r.calendar
        this.rangeStart = r.list
        this.endGame = this.pipe.transform(r.date_end, 'DD')
      })
  }

  fetchCalendarEnd() {
    this.isLoading = true
    this.scheduleRoomService
      .calendarEnd(
        this.pipe.transform(this.selectedValue, 'yyyy'),
        this.pipe.transform(this.selectedValue, 'MM'),
      )
      .subscribe(r => {
        this.calendarEnd = r.calendar
        this.rangeEnd = r.list
        this.startGame = this.pipe.transform(r.date_start, 'DD')
      })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchScheduleRoom()
    this.fetchCalendarStart()
    this.fetchCalendarEnd()
  }

  // search() {
  //   console.log(this.form.value)
  //   if (this.form.value.keyword || this.form.value.keyword?.length > 0) {
  //     this.queryTable.filters = [
  //       {
  //         field: this.form.value.prefix,
  //         keyword: this.form.value.keyword,
  //       },
  //     ]
  //   } else {
  //     this.queryTable.filters = []
  //   }
  //   console.log(this.queryTable.filters)
  //   this.fetchScheduleRoom()
  // }

  // changePrefix(event) {
  //   if (event == 'status') {
  //     this.form.patchValue({ keyword: true })
  //   } else if (event == 'function') {
  //     this.form.patchValue({ keyword: 'FORMAL' })
  //   } else {
  //     this.form.patchValue({ keyword: null })
  //   }
  // }

  // reset() {
  //   this.form.reset()
  //   this.form.patchValue({ prefix: 'vendor' })
  //   this.queryTable.sort = null
  //   this.queryTable.filters = []
  //   this.listOfColumns.forEach(r => {
  //     r.sortOrder = null
  //   })
  // this.fetchScheduleRoom()
  // }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  selectChange(select: Date): void {
    var valueBefore = new Date(this.valueBefore)
    this.selectedValue = select
    if (this.pipe.transform(valueBefore, 'MM') == this.pipe.transform(select, 'MM')) {
      if (this.pipe.transform(valueBefore, 'yyyy') == this.pipe.transform(select, 'yyyy')) {
        const modal = this.modal.create({
          nzTitle: 'Add Schedule Room',
          nzContent: AddScheduleComponent,
          nzViewContainerRef: this.viewContainerRef,
          nzComponentParams: {
            date_start: this.selectedValue,
          },
          nzMaskClosable: false,
          nzClosable: false,
          nzOkText: 'Create',
          nzOkDisabled: true,
          nzOnOk: () =>
            new Promise((resolve, reject) => {
              if (instance.submitForm()) {
                resolve
              } else {
                reject
              }
            }),
          nzWidth: '75%',
        })
        this.fetchScheduleRoom()
        this.fetchCalendarStart()
        this.fetchCalendarEnd()
        const instance = modal.getContentComponent()
        modal.afterClose.subscribe(() => {
          this.fetchScheduleRoom()
          this.fetchCalendarStart()
          this.fetchCalendarEnd()
        })
      }
    }
    this.valueBefore = this.selectedValue
    this.fetchScheduleRoom()
    this.fetchCalendarStart()
    this.fetchCalendarEnd()
  }

  async viewSchedule(id: string) {
    if (
      this.user.role == 'administrator' ||
      this.user.role == 'head_ga' ||
      this.user.role == 'staff_ga'
    ) {
      const modal = this.modal.create({
        nzTitle: 'Edit Scheduled Room',
        nzContent: EditScheduleComponent,
        nzViewContainerRef: this.viewContainerRef,
        nzComponentParams: {
          id: id,
        },
        nzMaskClosable: false,
        nzClosable: false,
        nzOkText: 'Update',
        nzOkDisabled: true,
        // nzOnCancel: () => {
        //   console.log('CANCEL')
        // },
        nzOnOk: () =>
          new Promise((resolve, reject) => {
            if (instance.submitForm()) {
              resolve
            } else {
              reject
            }
          }),
        nzWidth: '75%',
      })
      const instance = modal.getContentComponent()
      modal.afterClose.subscribe(() => {
        this.fetchScheduleRoom()
        this.fetchCalendarStart()
        this.fetchCalendarEnd()
      })
    } else {
      const modal = this.modal.create({
        nzTitle: 'View Scheduled Room',
        nzContent: EditScheduleComponent,
        nzViewContainerRef: this.viewContainerRef,
        nzComponentParams: {
          id: id,
        },
        nzMaskClosable: false,
        nzClosable: true,
        nzWidth: '75%',
        nzFooter: [
          {
            label: 'Close',
            onClick: () => modal.destroy(),
          },
        ],
      })
      const instance = modal.getContentComponent()
      modal.afterClose.subscribe(() => {
        this.fetchScheduleRoom()
        this.fetchCalendarStart()
        this.fetchCalendarEnd()
      })
    }
  }

  deleteSchedule(id: string) {
    this.modal.confirm({
      nzTitle: 'Are you sure you want to delete this schedule?',
      nzContent: '<b style="color: red;">Once deleted, there is no turning back.</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        console.log('OK')
        this.scheduleRoomService.deleteScheduleRoom(id).subscribe(
          r => {
            this.notification.success('Success', r.message)
            this.fetchScheduleRoom()
            this.fetchCalendarStart()
            this.fetchCalendarEnd()
          },
          err => {
            this.notification.error('Error', err.error.message)
            this.fetchScheduleRoom()
            this.fetchCalendarStart()
            this.fetchCalendarEnd()
          },
        )
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel'),
    })
  }
}
