import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzUploadFile } from 'ng-zorro-antd/upload'
import { updateProfile, userInfo, userList } from 'src/app/models/freelance-marketing.models'
import { FreelanceMarketingService } from 'src/app/services/freelance-management/freelance-marketing.service'

@Component({
  selector: 'app-reserved',
  templateUrl: './reserved.component.html',
  styleUrls: ['./reserved.component.scss'],
})
export class ReservedComponent implements OnInit {
  @Input() data: userList[]
  @ViewChild('acceptContent') acceptContent: TemplateRef<any>

  totalData: number = 0
  pageSize: number = 10
  pageIndex: number = 1

  isLoading: boolean
  isModalEditConfirm: boolean
  isModalEditVisible: boolean

  profile: FormGroup
  pictureList: NzUploadFile[] = []

  reason: string
  constructor(
    private freelanceMarketingService: FreelanceMarketingService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private modal: NzModalService,
  ) {
    this.profile = this.fb.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      gender: ['', Validators.compose([Validators.required])],
      dateOfBirth: ['', Validators.compose([Validators.required])],
      placeOfBirth: ['', Validators.compose([Validators.required])],
      phoneNumber: [null, Validators.compose([Validators.required])],
      address: ['', Validators.compose([Validators.required])],
      citizenNumber: [null, Validators.compose([Validators.minLength(16), Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      company: ['', Validators.compose([Validators.required])],
      bankName: ['', Validators.compose([Validators.required])],
      accountBankName: ['', Validators.compose([Validators.required])],
      accountNumber: ['', Validators.compose([Validators.required])],
      status: [0],
      id: [''],
      avatar: [''],
    })
  }

  listOfColumns = [
    {
      name: 'Actions',
      key: 'actions',
      left: false,
      right: false,
      width: '125px',
    },
    {
      name: 'Agent Id',
      key: 'agent_id',
      left: false,
      right: false,
      width: '230px',
      compare: (a: userList, b: userList) => a.agent_id.localeCompare(b.agent_id),
      priority: false,
    },
    {
      name: 'Status',
      key: 'status',
      left: false,
      right: false,
      width: '230px',
      compare: (a: userList, b: userList) => a.status.localeCompare(b.status),
      priority: false,
    },
    {
      name: 'Name',
      key: 'name',
      left: false,
      right: false,
      width: '230px',
      compare: (a: userList, b: userList) => a.name.localeCompare(b.name),
      priority: false,
    },
    {
      name: 'Phone Number',
      key: 'phone_number',
      left: false,
      right: false,
      width: '230px',
      compare: (a: userList, b: userList) => a.phone_number.localeCompare(b.phone_number),
      priority: false,
    },
    {
      name: 'Email',
      key: 'email',
      left: false,
      right: false,
      width: '230px',
      compare: (a: userList, b: userList) => a.email.localeCompare(b.email),
      priority: false,
    },
    {
      name: 'Reject Reason',
      key: 'reject_reason',
      left: false,
      right: false,
      width: '250px',
      compare: (a: userList, b: userList) => a.reject_reason.localeCompare(b.reject_reason),
      priority: false,
    },
  ]

  ngOnInit(): void {
    this.totalData = this.data.length
    // console.log(this.data)
  }

  refreshData(): void {
    this.fetchFreelanceList('reserved')
  }

  fetchFreelanceList(progress: string): void {
    this.isLoading = true
    this.freelanceMarketingService.fetchUser(progress).subscribe(
      (res: userList[]) => {
        this.isLoading = false
        this.data = res
        this.totalData = res.length
      },
      error => {
        console.error(error)
      },
    )
  }

  handleView(data: userList): void {
    // console.log(data.agent_id)
    this.isLoading = true
    this.profile.get('status')?.setValue(data.status === 'Review' ? false : true)
    this.profile.get('id')?.setValue(data.agent_id)
    this.freelanceMarketingService.fetchUserInfo(data.agent_id).subscribe(
      (res: userInfo) => {
        this.pictureList = [
          {
            uid: '-1',
            name: data.agent_id,
            status: 'done',
            url: res.avatar,
          },
        ]
        this.profile.get('avatar')?.setValue(res.avatar)
        this.profile.get('firstName')?.setValue(res.first_name)
        this.profile.get('lastName')?.setValue(res.last_name)
        this.profile.get('gender')?.setValue(res.gender)
        this.profile
          .get('dateOfBirth')
          ?.setValue(res.birth_date ? new Date(res.birth_date) : res.birth_date)
        this.profile.get('placeOfBirth')?.setValue(res.birth_place)
        this.profile.get('phoneNumber')?.setValue(res.phone_number.slice(2))
        this.profile.get('address')?.setValue(res.address)
        this.profile.get('citizenNumber')?.setValue(res.citizen_number)
        this.profile.get('email')?.setValue(res.email)
        this.profile.get('company')?.setValue(res.company)
        this.profile.get('bankName')?.setValue(res.bank_info.bank_name)
        this.profile.get('accountBankName')?.setValue(res.bank_info.account_name)
        this.profile.get('accountNumber')?.setValue(res.bank_info.account_number)

        this.isLoading = false
        this.isModalEditVisible = true
      },
      error => {
        console.log(error)
        this.isLoading = false
        this.notification.error('Error', error.error.message)
      },
    )
  }

  handleEdit(): void {
    this.isModalEditConfirm = true
    const payload: updateProfile = {
      firstName: this.profile.value.firstName,
      lastName: this.profile.value.lastName,
      gender: this.profile.value.gender,
      birthPlace: this.profile.value.placeOfBirth,
      birthDate: this.profile.value.dateOfBirth,
      phoneNumber: this.profile.value.phone_number,
      address: this.profile.value.address,
      citizenNumber: String(this.profile.value.citizenNumber),
      email: this.profile.value.email,
      company: this.profile.value.company,
      bankName: this.profile.value.bankName,
      accountName: this.profile.value.accountBankName,
      accountNumber: this.profile.value.accountNumber,
    }

    // console.log(payload)
    this.freelanceMarketingService.updateInfo(this.profile.value.id, payload).subscribe(
      res => {
        this.notification.success('Success', res.message)
        this.isModalEditConfirm = false
        this.isModalEditVisible = false
      },
      err => {
        console.error(err)
        this.notification.error('Error', err.error.message)
        this.isModalEditConfirm = false
      },
    )
  }

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?action=download', '_blank')
  }

  onCancelModal(): void {
    this.refreshData()
    this.isModalEditVisible = false
  }

  handleAccept(data: userList): void {
    this.modal.confirm({
      nzTitle: 'Accept Candidate',
      nzContent: 'Has the candidate submitted the signed employment agreement (PKS)?',
      nzOnOk: () => {
        this.acceptReasonModal(data.agent_id)
      },
      nzCancelText: 'No',
      nzOkText: 'Yes',
    })
  }

  acceptReasonModal(id: string): void {
    const modalRef = this.modal.create({
      nzTitle: 'Accept Candidate',
      nzContent: this.acceptContent,
      nzCancelText: 'Cancel',
      nzOkText: 'Confirm',
      nzOnOk: () => {
        modalRef.updateConfig({
          nzOkLoading: true,
        })
        this.freelanceMarketingService.accept(id, this.reason).subscribe(
          res => {
            // console.log(res)

            this.notification.success('Success', res.message)
            this.reason = ''
            this.refreshData()
            modalRef.destroy()

            this.modal.info({
              nzTitle: 'Accept Candidate',
              nzContent: 'The candidate has been moved to the "Existing" tab.',
            })
          },
          err => {
            // console.log(err)

            this.notification.error('Error', err.error.message)
            this.reason = ''
            modalRef.updateConfig({
              nzOkLoading: true,
            })
          },
        )
      },
      nzOnCancel: () => {
        this.reason = ''
      },
    })
  }

  handleSendPKS(data: userList): void {
    this.freelanceMarketingService.sendPKS(data.agent_id).subscribe(
      res => {
        this.notification.success('Success', res.message)
        this.refreshData()
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }
}
