import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { NzModalRef } from 'ng-zorro-antd/modal'

@Component({
  selector: 'app-Edit-location',
  templateUrl: './Edit-location.component.html',
  styleUrls: ['./Edit-location.component.scss'],
})
export class EditLocationComponent implements OnInit {
  form: FormGroup
  @Input() code: string
  isLoading: boolean = false
  isLoadingOptions: boolean = false

  constructor(private fb: FormBuilder, private store: Store<any>, private modal: NzModalRef) {
    this.form = this.fb.group({})
  }
  ngOnInit(): void {}

  submitForm(): boolean {
    return true
  }
}
