import { Component, OnInit, ViewContainerRef, TemplateRef, ViewChild } from '@angular/core'
import { AssetManagementService } from 'src/app/services/asset-management.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ViewConfigurationComponent } from './view-configuration/view-configuration.component'
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal'
import {
  Company,
  Config,
  NewConfig,
  UpdateCompany,
  UpdateConfig,
} from 'src/app/models/asset-config.model'
import { CapitalizeWord } from './../../../shared/pipes/capitalize-word.pipe'
import { NzNotificationService } from 'ng-zorro-antd/notification'
@Component({
  selector: 'app-asset-configuration',
  templateUrl: './asset-configuration.component.html',
  styleUrls: ['./asset-configuration.component.scss'],
})
export class AssetConfigurationComponent implements OnInit {
  @ViewChild('toasterConfig', { static: true }) toasterConfigTemplate: TemplateRef<{}>
  confirmModal?: NzModalRef

  getConfig: Config[] = []
  getCompany: Company[] = []
  companyData: string[] = []
  isLoading: boolean = true
  pageSize: number = 10
  pageIndex: number = 1

  getEmpty: Config[] = []

  filterForm: FormGroup
  addForm: FormGroup
  editForm: FormGroup

  isDownloading: boolean = false
  selectedCompany: string = 'Onindonesia'
  isOkLoading = false

  isAddConfigVisible: boolean = false
  isAddConfigConfirm: boolean = false

  selectedConfig: string = ''
  displayedConfig: string = ''
  isEditConfigVisible: boolean = false
  isEditConfigConfirm: boolean = false

  messageConfig: string
  iconConfig: string
  colorConfig: string

  constructor(
    private assetManagementService: AssetManagementService,
    private fb: FormBuilder,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private notification: NzNotificationService,
  ) {
    this.filterForm = this.fb.group({
      company: ['', Validators.compose([Validators.required])],
      search: ['', Validators.compose([Validators.required])],
    })

    this.addForm = this.fb.group({
      new_config: ['', Validators.compose([Validators.required])],
      code: ['', Validators.compose([Validators.required])],
    })

    this.editForm = this.fb.group({
      new_config: ['', Validators.compose([Validators.required])],
    })
  }

  listOfColumn = [
    {
      title: 'Actions',
      key: 'actions',
      left: false,
      right: false,
      width: '70px',
    },
    {
      title: 'Configuration',
      key: 'configuration',
      left: false,
      right: false,
      width: '130px',
      compare: (a: Config, b: Config) => a.configuration.localeCompare(b.configuration),
      priority: false,
    },
    {
      title: 'Created By',
      key: 'created_by',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Config, b: Config) => a.created_by.localeCompare(b.created_by),
      priority: 4,
    },
    {
      title: 'Updated By',
      key: 'updated_by',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Config, b: Config) => a.updated_by.localeCompare(b.updated_by),
      priority: 3,
    },
    {
      title: 'Created At',
      key: 'created_at',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Config, b: Config) => a.created_at.localeCompare(b.created_at),
      priority: 2,
    },
    {
      title: 'Updated At',
      key: 'updated_at',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Config, b: Config) => a.updated_at.localeCompare(b.updated_at),
      priority: 1,
    },
  ]

  ngOnInit(): void {
    this.companyData = []
    this.filterForm.get('company').setValue(this.selectedCompany)
    this.fetchCompany()
    this.fetchConfig(this.selectedCompany)
  }

  fetchCompany() {
    this.assetManagementService.getCompany().subscribe((r: Company[]) => {
      this.getCompany = r
      for (let item of r) {
        this.companyData.push(item.value)
      }
    })
  }

  fetchConfig(company: string) {
    this.isLoading = true
    this.assetManagementService.getConfig(company).subscribe((r: Config[]) => {
      this.getConfig = r
      if (this.filterForm.value.search.length > 0) {
        const filteredData = []
        for (let item of this.getConfig) {
          if (item.configuration.toLocaleLowerCase().includes(this.filterForm.value.search)) {
            filteredData.push(item)
          }
        }
        this.getConfig = filteredData
      }
      this.isLoading = false
    })
  }

  searchConfig() {
    this.selectedCompany = this.filterForm.value.company
    this.fetchConfig(this.selectedCompany)
  }

  addConfig() {
    this.isAddConfigVisible = true
  }

  viewConfig(config: string) {
    const modal = this.modal.create({
      nzContent: ViewConfigurationComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzMaskClosable: false,
      nzClosable: false,
      nzWidth: '80%',
      nzComponentParams: {
        config: config,
        company: this.selectedCompany,
      },
    })
    modal.afterClose.subscribe(() => {
      this.fetchConfig(this.selectedCompany)
    })
  }

  deleteConfig(config: string) {
    this.confirmModal = this.modal.confirm({
      nzWidth: '500px',
      nzTitle: `Do you want to delete "${config}" configuration?`,
      nzContent:
        'When clicked the OK button, everything associate with this configuration will be deleted also!',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 500)
        }).then(() => {
          setTimeout(() => {
            this.assetManagementService.deleteConfig(this.selectedCompany, config).subscribe(
              r => {
                this.messageConfig = r.message
                this.iconConfig = 'check-circle'
                this.colorConfig = '#52c41a'
                this.createBasicNotification(this.toasterConfigTemplate)
                this.fetchConfig(this.selectedCompany)
              },
              error => {
                this.messageConfig = error.error.message
                this.iconConfig = 'warning'
                this.colorConfig = '#eb2f96'
                this.createBasicNotification(this.toasterConfigTemplate)
              },
            )
          }, 500)
        }),
    })
  }

  updateConfig(config: string) {
    this.isEditConfigVisible = true
    this.selectedConfig = config
    this.displayedConfig = config
    // console.log(this.isEditConfigVisible, this.selectedConfig, this.selectedCompany, 'edit config')
  }

  handleAddOk(): void {
    this.isAddConfigConfirm = true
    setTimeout(() => {
      this.isAddConfigVisible = false
      this.isAddConfigConfirm = false

      const capitalizeWordPipe = new CapitalizeWord()

      const payload = {
        company: this.selectedCompany,
        config: capitalizeWordPipe.transform(this.addForm.value.new_config),
        code: this.addForm.value.code.toUpperCase(),
      }

      this.assetManagementService.addConfig(payload).subscribe(
        r => {
          this.messageConfig = r.message
          this.iconConfig = 'check-circle'
          this.colorConfig = '#52c41a'
          this.createBasicNotification(this.toasterConfigTemplate)
          this.fetchConfig(this.selectedCompany)
        },
        error => {
          this.messageConfig = error.error.message
          this.iconConfig = 'warning'
          this.colorConfig = '#eb2f96'
          this.createBasicNotification(this.toasterConfigTemplate)
        },
      )
    }, 500)
  }

  handleEditOk(): void {
    this.isEditConfigConfirm = true
    setTimeout(() => {
      this.isEditConfigVisible = false
      this.isEditConfigConfirm = false

      const capitalizeWordPipe = new CapitalizeWord()

      const payload = {
        company: this.selectedCompany,

        last_config: this.selectedConfig,
        new_config: capitalizeWordPipe.transform(this.editForm.value.new_config),
      }

      //console.log(payload, 'handle edit')

      this.assetManagementService.updateConfig(payload).subscribe(
        r => {
          this.messageConfig = r.message
          this.iconConfig = 'check-circle'
          this.colorConfig = '#52c41a'
          this.createBasicNotification(this.toasterConfigTemplate)
          this.fetchConfig(this.selectedCompany)
        },
        error => {
          this.messageConfig = error.error.message
          this.iconConfig = 'warning'
          this.colorConfig = '#eb2f96'
          this.createBasicNotification(this.toasterConfigTemplate)
        },
      )
    }, 500)
  }

  exportConfig(): void {
    this.isDownloading = true
    var blankPage = window.open('http://example.com/waiting.html', '_blank')
    blankPage!.document.write('Loading preview...')

    this.assetManagementService.exportConfig(this.selectedCompany).subscribe(
      r => {
        console.log(r.link)
        this.isDownloading = false
        blankPage!.location.href = r.link
        this.messageConfig = r.message
        this.iconConfig = 'check-circle'
        this.colorConfig = '#52c41a'
        this.createBasicNotification(this.toasterConfigTemplate)
      },
      err => {
        blankPage.close()
        this.isDownloading = false
        this.messageConfig = err.error.message
        this.iconConfig = 'warning'
        this.colorConfig = '#eb2f96'
        this.createBasicNotification(this.toasterConfigTemplate)
      },
    )
  }

  handleAddCancel(): void {
    this.isAddConfigVisible = false
  }

  handleEditCancel(): void {
    this.isEditConfigVisible = false
  }

  createBasicNotification(template: TemplateRef<{}>): void {
    this.notification.template(template, {
      nzPlacement: 'bottomRight',
    })
  }
}
