import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'
import store from 'store'
import { MitraForm, PendingForm } from './interface/on-mitra.model'
import { QueryTable } from './interface/general.model'

@Injectable({
  providedIn: 'root',
})
export class OnMitraService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  //--   PENDING SERVICES   --//

  getPending(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/mitra/pending-list',
      queryTable,
      params,
    )
  }

  detailPending(_id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            _id: _id,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/mitra/pending-detail', params)
  }

  verifyPending(form: PendingForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/mitra/verify-driver',
      form,
      params,
    )
  }

  //--   VERIFIED SERVICES   --//

  getVerified(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/mitra/driver-list',
      queryTable,
      params,
    )
  }

  detailVerified(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/mitra/driver-detail', params)
  }

  updateVerified(form: MitraForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/mitra/update-driver',
      form,
      params,
    )
  }

  resetPassword(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/mitra/reset-password',
      { id },
      params,
    )
  }

  resetUniqueKey(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/mitra/reset-unique-key',
      { id },
      params,
    )
  }

  // displayProtectedImage(folder:string, filename:string){
  //   const accessToken = store.get('accessToken')
  //   const params = accessToken
  //     ? {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }
  //     : {}
  //     console.log(params)
  //   return this.http.get(
  //     this.apiService.API_SUNSHINE_SERVER + '/api/on_mitra/image/' + folder + "/" + filename,
  //     params,
  //   )
  // }
  // displayProtectedImage(
  //   imageId, imageUrl, authToken
  // ): Observable<any> {
  //   // Fetch the image.
  //   const response = await fetchWithAuthentication(
  //     imageUrl, authToken
  //   );

  //   // Create an object URL from the data.
  //   const blob = await response.blob();
  //   const objectUrl = URL.createObjectURL(blob);

  //   // Update the source of the image.
  //   const imageElement = getElementById(imageId);
  //   imageElement.src = objectUrl;
  //   imageElement.onload = () => URL.revokeObjectUrl(objectUrl);
  // }

  // getImage(email: string, filename: string): Observable<any> {
  //   const accessToken = store.get('accessToken')
  //   const params = accessToken
  //     ? {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }
  //     : {}
  //   return this.http.post(
  //     this.apiService.API_SUNSHINE_SERVER + '/api/on_mitra/image' + '/' + email + '/' + filename,
  //     params,
  //   )
  // }
}
