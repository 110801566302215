<nz-spin [nzSpinning]="isLoading">
    <form nz-form [formGroup]="form">
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="full_name" class="text-left">Full Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input full name correctly!">
                        <input nz-input id="'full_name'" readonly formControlName="full_name"
                            placeholder="Input full name ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="agent_type" class="text-left">Agent Type</nz-form-label>
                    <nz-form-control nzErrorTip="Please input agent type correctly!">
                        <input nz-input id="'agent_type'" readonly formControlName="agent_type" placeholder="Input agent type ..."
                            readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="phone" class="text-left">Phone</nz-form-label>
                    <nz-form-control nzErrorTip="Please input phone correctly!">
                        <input nz-input id="'phone'" readonly formControlName="phone" placeholder="Input phone ..."
                            readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="email" class="text-left">Email</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid email">
                        <input nz-input id="'email'" formControlName="email" placeholder="Input Email ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="location_type" class="text-left">Location Type</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid location type">
                        <input nz-input id="'location_type'" formControlName="location_type"
                            placeholder="Input location type ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="building_status" class="text-left">Building Status</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid building status">
                        <input nz-input id="'building_status'" formControlName="building_status"
                            placeholder="Input building status ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="full_address" class="text-left">Full Address</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid full address">
                        <input nz-input id="'full_address'" formControlName="full_address"
                            placeholder="Input full address ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="google_map" class="text-left">Google Map</nz-form-label>
                    <button nz-button nzType="default">
                        <a [href]=googleMapLink target="_blank">Open Google Maps</a>
                    </button>
                    <!-- <nz-form-control nzErrorTip="Please input a valid google map">
                        <input nz-input id="'google_map'" formControlName="google_map"
                            placeholder="Input google map ..." readonly />
                    </nz-form-control> -->
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="province" class="text-left">Province</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid province">
                        <input nz-input id="'province'" formControlName="province"
                            placeholder="Input province ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="city" class="text-left">City</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid city">
                        <input nz-input id="'city'" formControlName="city"
                            placeholder="Input city ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="sub_district" class="text-left">Sub District</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid sub district">
                        <input nz-input id="'sub_district'" formControlName="sub_district"
                            placeholder="Input sub district ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="urban" class="text-left">Urban</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid urban">
                        <input nz-input id="'urban'" formControlName="urban"
                            placeholder="Input urban ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="updated_at" class="text-left">Inquiry Date</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid date">
                        <input nz-input id="'updated_at'" formControlName="updated_at" placeholder="Input date ..."
                            readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
</nz-spin>