<form nz-form [formGroup]="form">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <nz-form-item>
                <nz-form-label nzFor="name" class="text-left">Name</nz-form-label>
                <nz-form-control>
                    <input readonly class="content" nz-input id="'name'" formControlName="name" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div class="col-lg-6 col-md-12">
            <nz-form-item>
                <nz-form-label nzFor="code" class="text-left">Employee Code</nz-form-label>
                <nz-form-control>
                    <input readonly class="content" nz-input id="'code'" formControlName="code" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div class="col-lg-6 col-md-12">
            <nz-form-item>
                <nz-form-label nzFor="days" class="text-left">Leave Days</nz-form-label>
                <nz-form-control nzErrorTip="Please input amount of days!">
                    <input class="content" nz-input type="number" min="1" id="'days'" formControlName="days" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div class="col-6">
            <nz-form-item>
                <nz-form-label nzFor="substitute" class="text-left">Substitute</nz-form-label>
                <nz-form-control nzErrorTip="Please choose a substitute!">
                    <nz-select nzShowSearch nzServerSearch nzAllowClear id="substitute" formControlName="substitute"
                        nzPlaceHolder="Choose a Substitute" (nzOnSearch)="search($event, 'substitute')">
                        <ng-container *ngFor="let item of subsArr">
                            <nz-option *ngIf="!isLoadingOptions" [nzValue]="item.username" [nzLabel]="item.name">
                            </nz-option>
                        </ng-container>
                        <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                            <i nz-icon nzType="loading" class="loading-icon"></i>
                            Loading Data...
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div class="col-lg-6 col-md-12">
            <ng-container formArrayName="date">
                <div *ngFor="let _ of dateArray.controls; index as i">
                    <nz-form-item>
                        <nz-form-label nzFor="date" class="text-left">Date</nz-form-label>
                        <nz-form-control nzErrorTip="Please choose correct date!">
                            <nz-date-picker [nzDisabledDate]="disabledDatePassed" [formControlName]="i" nzFormat="dd-MM-yyyy"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </ng-container>
        </div>
        <div class="col-6">
            <nz-form-item>
                <nz-form-label nzFor="leave_notes" class="text-left">Leave Notes</nz-form-label>
                <nz-form-control nzErrorTip="Please input a valid leave notes">
                    <nz-textarea-count>
                        <textarea rows="4" formControlName="leave_notes" nz-input></textarea>
                    </nz-textarea-count>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
</form>
<div *nzModalFooter>
    <button nz-button nzType="default" (click)="cancel()" [nzLoading]="isLoading">Cancel</button>
    <button nz-button nzType="primary" (click)="submitForm()" [nzLoading]="isLoading">Apply</button>
</div>