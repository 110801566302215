import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CMSService } from 'src/app/services/cms.service'
import { VacancyForm } from 'src/app/services/interface/vacancy.model'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { Editor, toHTML, Toolbar } from 'ngx-editor'

interface options {
  id: string
  title: string
}

@Component({
  selector: 'app-add-vacancy',
  templateUrl: './add-vacancy.component.html',
  styleUrls: ['./add-vacancy.component.scss'],
})
export class AddVacancyComponent implements OnInit {
  editor: Editor
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ]
  isLoading: boolean = false
  deptArr: options[] = []
  sectionArr: options[] = [
    {
      id: 'Full Time',
      title: 'Full Time',
    },
    {
      id: 'Part Time',
      title: 'Part Time',
    },
    {
      id: 'Internship',
      title: 'Internship',
    },
  ]
  constructor(
    private fb: FormBuilder,
    private cmsService: CMSService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
  ) {
    this.form = this.fb.group({
      title: [null, Validators.compose([Validators.required])],
      category: [null, Validators.compose([Validators.required])],
      content: [null, Validators.compose([Validators.required])],
      departmentId: [null, Validators.compose([Validators.required])],
      status: [null, Validators.compose([Validators.required])],
    })
    this.cmsService.deptOption().subscribe((r: any) => {
      this.deptArr = r
    })
  }

  ngOnDestroy(): void {
    this.editor = new Editor()
  }

  @Input() id: string
  form: FormGroup

  ngOnInit(): void {
    this.editor = new Editor()
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  submitForm() {
    this.isLoading = true
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    if (this.form.valid) {
      var formSubs: VacancyForm = this.form.value
      formSubs.content = toHTML(this.form.value.content)
      this.cmsService.createVacancy(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.isLoading = false
          this.modal.destroy()
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      return false
    }
  }
}
