// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-add-fleet',
//   templateUrl: './add-fleet.component.html',
//   styleUrls: ['./add-fleet.component.scss']
// })
// export class AddFleetComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { TicketingService } from 'src/app/services/ticketing.service'
import { select, Store } from '@ngrx/store'
import storeeeee from 'store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import { TicketForm } from 'src/app/services/interface/ticketing.model'
import { UploadList } from 'src/app/services/interface/general.model'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { ApiService } from 'src/app/services/api.service'
import { BehaviorSubject, Observable, Observer, of } from 'rxjs'
import { FleetService } from 'src/app/services/fleet.service'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import { DriverForm } from 'src/app/services/interface/driver.model'
import { WhForm } from 'src/app/services/interface/warehouse.model'

@Component({
  selector: 'app-add-warehouse',
  templateUrl: './add-warehouse.component.html',
})
export class AddWarehouseComponent implements OnInit {
  date = null
  isLoading: boolean = false
  isLoadingOptions: boolean = false
  signer: string
  fileList: NzUploadFile[] = []
  constructor(
    private fb: FormBuilder,
    private fleetService: FleetService,
    private apiService: ApiService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
  ) {
    this.form = this.fb.group({
      whId: [null, Validators.compose([Validators.required])],
      whName: [null, Validators.compose([Validators.required])],
      whType: [null, Validators.compose([Validators.required])],
      whAddress: [null, Validators.compose([Validators.required])],
      whPic: [null, Validators.compose([Validators.required])],
      phoneNumber: [null, Validators.compose([Validators.required])],
    })
  }

  @Input() ticketId: string
  form: FormGroup
  searchChange$ = new BehaviorSubject({ type: '', value: '' })

  ngOnInit(): void {
    // setInterval(() => console.log(this.fileList), 3000)
    this.form.statusChanges.subscribe(state => {
      // console.log(state)
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }

    if (this.form.valid) {
      var formSubs: WhForm = this.form.value
      this.fleetService.createWh(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.modal.destroy()
          this.isLoading = false
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }

  search(value: string, type: string) {
    this.isLoadingOptions = true
    this.searchChange$.next({ type, value })
  }

  handleRemove = (file: NzUploadFile) =>
    new Observable((observer: Observer<boolean>) => {
      // console.log(file)
      if (file.hasOwnProperty('originFileObj')) {
        if (file.response == undefined) {
          observer.next(true)
          observer.complete()
        } else {
          this.apiService.deleteFileTemp(file.response?.name).subscribe(
            r => {
              observer.next(true)
              observer.complete()
            },
            e => {
              observer.next(false)
              file.status = 'done'
              this.msg.error('Failed to Delete')
              observer.complete()
            },
          )
        }
      } else {
        observer.next(true)
        observer.complete()
      }
    })

  onChange(result: Date[]): void {
    this.form.patchValue({
      startContract: result[0],
      endContract: result[1],
    })
  }
}
