import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { TicketingService } from 'src/app/services/ticketing.service'
import { select, Store } from '@ngrx/store'
import storeeeee from 'store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import { TicketForm } from 'src/app/services/interface/ticketing.model'
import { UploadList } from 'src/app/services/interface/general.model'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { ApiService } from 'src/app/services/api.service'
import { Observable, Observer } from 'rxjs'
// import { extname } from 'path'
interface options {
  codeName: string
  code: string
}

@Component({
  selector: 'app-add-ticket',
  templateUrl: './add-ticket.component.html',
  styleUrls: ['./add-ticket.component.scss'],
})
export class AddTicketComponent implements OnInit {
  isLoading: boolean = false
  signer: string
  fileList: NzUploadFile[] = []
  constructor(
    private fb: FormBuilder,
    private ticketingService: TicketingService,
    private apiService: ApiService,
    private store: Store<any>,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private http: HttpClient,
  ) {
    this.form = this.fb.group({
      tag: [null],
      vendor: [null, Validators.compose([Validators.required])],
      waybill: [null, Validators.compose([Validators.required])],
      agent: [null, Validators.compose([Validators.required])],
      person: [null, Validators.compose([Validators.required])],
      problemType: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.signer = state.username
    })
  }

  @Input() ticketId: string
  form: FormGroup

  problemTypeOptions: options[] = []
  vendorOptions: options[] = []
  agentOptions: options[] = []

  ngOnInit(): void {
    // setInterval(() => console.log(this.fileList), 3000)
    // this.modal.updateConfig({ nzMaskClosable: true, nzClosable: true })
    // console.log(this.modal.updateConfig({ nzMaskClosable: true, nzClosable: true }))
  }

  submitForm(): void {
    this.isLoading = true
    var isUploading = false
    for (let i = 0; i < this.fileList.length; i++) {
      if (this.fileList[i].status == 'uploading') {
        isUploading = true
        break
      }
    }
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    if (isUploading) {
      this.msg.error('Please wait until all files uploaded')
      this.isLoading = false
      return
    }

    if (this.form.valid) {
      var attachmentList: UploadList[] = []
      for (let i = 0; i < this.fileList.length; i++) {
        if ((this.fileList[i].status = 'done')) {
          var tempMetaAttachment: UploadList = {
            name: this.fileList[i].response?.name || this.fileList[i].name,
            status: this.fileList[i].status,
            url: this.fileList[i].response?.url || this.fileList[i].url,
            uid: this.fileList[i].uid,
          }
          attachmentList.push(tempMetaAttachment)
        }
      }
      var formSubs: TicketForm = this.form.value
      formSubs.signer = this.signer
      formSubs.attachments = attachmentList
      formSubs.tag = this.ticketId
      // console.log(formSubs)
      this.ticketingService.createTicket(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.modal.destroy()
          this.isLoading = false
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
    }
  }

  search(event: string, type: string) {
    this.ticketingService.serverOptionLoad(event, type).subscribe((r: options[]) => {
      switch (type) {
        case 'vendor':
          this.vendorOptions = r
          break
        case 'agent':
          this.agentOptions = r
          break
        case 'problemType':
          this.problemTypeOptions = r
          break
      }
    })
  }

  handleBeforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    if (file.size <= 11534336) {
      return true
    } else {
      this.msg.error('File too Large')
      return false
    }
  }

  customUploadReq = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    // console.log(item)
    var ext = item.file.name.split('.').pop()
    formData.append('file', item.file as any, this.ticketId + '.' + ext)

    const req = new HttpRequest(
      'POST',
      this.apiService.API_SUNSHINE_SERVER + '/api/ticketing/tickets/upload',
      formData,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + storeeeee.get('accessToken'),
        }),
        reportProgress: true,
        withCredentials: false,
      },
    )

    return this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            ;(event as any).percent = (event.loaded / event.total) * 100 // tslint:disable-next-line:no-any
          }
          item.onProgress!(event, item.file)
        } else if (event instanceof HttpResponse) {
          // console.log(event)
          item.onSuccess!(event.body, item.file, event)
        }
      },
      err => {
        // console.log(err)
        /* error */
        item.onError!(err, item.file)
      },
    )
  }

  handleRemove = (file: NzUploadFile) =>
    new Observable((observer: Observer<boolean>) => {
      // console.log(file)
      if (file.hasOwnProperty('originFileObj')) {
        if (file.response == undefined) {
          observer.next(true)
          observer.complete()
        } else {
          this.apiService.deleteFileTemp(file.response?.name).subscribe(
            r => {
              observer.next(true)
              observer.complete()
            },
            e => {
              observer.next(false)
              file.status = 'done'
              this.msg.error('Failed to Delete')
              observer.complete()
            },
          )
        }
      } else {
        observer.next(true)
        observer.complete()
      }
    })

  handleDownload = (file: NzUploadFile): void => {
    // console.log(file)
    window.open((file.response?.url || file.url) + '?statement=download', '_blank')
  }

  handlePreview = async (file: NzUploadFile) => {
    window.open((file.response?.url || file.url) + '?statement=preview', '_blank')
  }
}
