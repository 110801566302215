<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form">
    <div class="row">
      <div class="marginBot col-lg-6 col-md-12">
        <h3 class="title-font">Title</h3>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input title correctly!">
            <input class="content" nz-input id="'title'" formControlName="title" placeholder="Input Vacancy title.." />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="marginBot col-lg-6 col-md-12">
        <h3 class="title-font">Department</h3>
        <nz-form-item>
          <nz-form-control nzErrorTip="Select available department!">
            <nz-select formControlName="departmentId" nzPlaceHolder="Choose Department">
              <nz-option *ngFor="let item of deptArr" [nzLabel]="item.title" [nzValue]="item.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="marginBot col-lg-6 col-md-12">
        <h3 class="title-font">Category</h3>
        <nz-form-item>
          <nz-form-control nzErrorTip="Select available category!">
            <nz-select formControlName="category" nzPlaceHolder="Choose Category">
              <nz-option *ngFor="let item of sectionArr" [nzLabel]="item.title" [nzValue]="item.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div style="margin-bottom: 15px; margin-left: 15px;">
      <h3 class="title-font">Content</h3>
      <form [formGroup]="form">
        <div class="editor">
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
          </ngx-editor-menu>
          <ngx-editor [editor]="editor" formControlName="content" style="height: 500px;">
          </ngx-editor>
        </div>
      </form>
    </div>

    <div class="widthContainer" style="margin-left: 15px;">
      <h3 class="title-font">Publishing Status</h3>
      <nz-form-item>
        <nz-form-control nzErrorTip="Select available mode!">
          <nz-select formControlName="status" nzPlaceHolder="Choose Publishing Status">
            <nz-option nzLabel="Publish" [nzValue]="true"></nz-option>
            <nz-option nzLabel="Draft" [nzValue]="false"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
</nz-spin>