<form nz-form [formGroup]="form">
  <div class="row" style="margin-bottom: 15px;">
    <div class="col-12">
      <div class="col-6"></div>
      <nz-collapse>
        <nz-collapse-panel nzHeader="Employee Account Information">
          <div class="row">
            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="username" class="text-left">Username</nz-form-label>
                <nz-form-control nzErrorTip="Please input username correctly!">
                  <input
                    nz-input
                    id="'username'"
                    formControlName="username"
                    placeholder="Input Username ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="role" class="text-left">Role</nz-form-label>
                <nz-form-control nzErrorTip="Please select role!">
                  <nz-select
                    nzShowSearch
                    nzServerSearch
                    nzAllowClear
                    id="role"
                    formControlName="role"
                    nzPlaceHolder="Select Role ..."
                    (nzOnSearch)="search($event, 'role')"
                  >
                    <ng-container *ngFor="let ro of roleOptions">
                      <nz-option
                        *ngIf="!isLoadingOptions"
                        [nzValue]="ro.code"
                        [nzLabel]="ro.codeName"
                      ></nz-option>
                    </ng-container>
                    <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Loading Data...
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="email" class="text-left">Email</nz-form-label>
                <nz-form-control nzErrorTip="Please input a valid email">
                  <input
                    nz-input
                    id="email"
                    formControlName="email"
                    placeholder="Input Email ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="code" class="text-left">Employee Code</nz-form-label>
                <nz-form-control nzErrorTip="Please input a valid employee code">
                  <input
                    nz-input
                    id="code"
                    formControlName="code"
                    placeholder="Input employee code ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="shift" class="text-left">Shift</nz-form-label>
                <nz-form-control nzErrorTip="Please select shift!">
                  <nz-select
                    nzShowSearch
                    nzServerSearch
                    nzAllowClear
                    id="'shift'"
                    formControlName="shift"
                    nzPlaceHolder="Select Shift ..."
                    (nzOnSearch)="search($event, 'shifts')"
                  >
                    <ng-container *ngFor="let so of shiftOptions">
                      <nz-option
                        *ngIf="!isLoadingOptions"
                        [nzValue]="so.code"
                        [nzLabel]="so.codeName"
                      ></nz-option>
                    </ng-container>
                    <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Loading Data...
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="company" class="text-left">Company</nz-form-label>
                <nz-form-control nzErrorTip="Please select company!">
                  <nz-select
                    nzShowSearch
                    nzServerSearch
                    nzAllowClear
                    id="'company'"
                    formControlName="company"
                    nzPlaceHolder="Select company ..."
                    (nzOnSearch)="search($event, 'company')"
                  >
                    <ng-container *ngFor="let co of companyOptions">
                      <nz-option
                        *ngIf="!isLoadingOptions"
                        [nzValue]="co.code"
                        [nzLabel]="co.codeName"
                      ></nz-option>
                    </ng-container>
                    <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Loading Data...
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-6">
              <nz-form-label nzFor="profile_img" class="text-left">Profile Picture (optional)</nz-form-label>
              <nz-upload
                nzListType="picture-card"
                [(nzFileList)]="fileList"
                [nzPreview]="handlePreview"
                [nzDownload]="handleDownload"
                [nzRemove]="handleRemove"
                [nzCustomRequest]="customUploadReq"
                [nzBeforeUpload]="handleBeforeUpload"
                [nzAccept]="'image/jpeg, image/jpg, image/png'"
                [nzDisabled]="fileList.length > 0"
                [nzShowButton]="!(fileList.length > 0)"
                (nzChange)="handleChange($event)"
              >
                <div>
                  <i nz-icon nzType="plus"></i>
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </nz-upload>
              <nz-modal
                [nzVisible]="previewVisible"
                [nzContent]="modalContent"
                [nzFooter]="null"
                (nzOnCancel)="previewVisible = false"
              >
                <ng-template #modalContent>
                  <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                </ng-template>
              </nz-modal>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
  <div class="row" style="margin-bottom: 15px;">
    <div class="col-12">
      <nz-collapse>
        <nz-collapse-panel nzHeader="Employee Personal Information">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="name" class="text-left">Full Name</nz-form-label>
                <nz-form-control nzErrorTip="Please input full name correctly!">
                  <input
                    nz-input
                    id="'name'"
                    formControlName="name"
                    placeholder="Input Full Name ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="citizenNumber" class="text-left"
                  >Citizen Number</nz-form-label
                >
                <nz-form-control nzErrorTip="Please input citizen number correctly!">
                  <input
                    nz-input
                    id="'citizenNumber'"
                    formControlName="citizenNumber"
                    placeholder="Input Citizen Number ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-3 col-md-6">
              <nz-form-item>
                <nz-form-label nzFor="gender">Gender</nz-form-label>
                <nz-form-control nzErrorTip="Please select gender!">
                  <nz-select formControlName="gender" nzPlaceHolder="Select Gender ...">
                    <nz-option nzLabel="Male" nzValue="Male"></nz-option>
                    <nz-option nzLabel="Female" nzValue="Female"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-3 col-md-6">
              <nz-form-item>
                <nz-form-label nzFor="dateOfBirth" class="text-left">Date of Birth</nz-form-label>
                <nz-form-control nzErrorTip="Please enter a valid date of birth">
                  <nz-date-picker id="'dateOfBirth'" formControlName="dateOfBirth"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-3 col-md-6">
              <nz-form-item>
                <nz-form-label nzFor="placeOfBirth" class="text-left">Place Of Birth</nz-form-label>
                <nz-form-control nzErrorTip="Please input place of birth correctly!">
                  <input
                    nz-input
                    id="'placeOfBirth'"
                    formControlName="placeOfBirth"
                    placeholder="Input Place Of Birth ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-3 col-md-6">
              <nz-form-item>
                <nz-form-label nzFor="typeOfSIM" class="text-left"
                  >Driver License's Type</nz-form-label
                >
                <nz-form-control nzErrorTip="Please select driver license!">
                  <nz-select
                    formControlName="typeOfSIM"
                    nzPlaceHolder="Select Type of Driver License ..."
                  >
                    <nz-option nzLabel="SIM A" nzValue="SIM A"></nz-option>
                    <nz-option nzLabel="SIM B1" nzValue="SIM B 1"></nz-option>
                    <nz-option nzLabel="SIM B2" nzValue="SIM B 2"></nz-option>
                    <nz-option nzLabel="SIM C" nzValue="SIM C"></nz-option>
                    <nz-option nzLabel="None" nzValue="None"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="phoneNumber">Phone Number</nz-form-label>
                <nz-form-control nzErrorTip="Please input your phone number!">
                  <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <ng-template #addOnBeforeTemplate>
                      <nz-select formControlName="phoneNumberPrefix" class="phone-select">
                        <nz-option nzLabel="+62" nzValue="+62"></nz-option>
                      </nz-select>
                    </ng-template>
                    <input formControlName="phoneNumber" id="'phoneNumber'" nz-input />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="waNumber">Whatsapp Number</nz-form-label>
                <nz-form-control nzErrorTip="Please input your whatsapp number!">
                  <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <ng-template #addOnBeforeTemplate>
                      <nz-select formControlName="waNumberPrefix" class="wa-select">
                        <nz-option nzLabel="+62" nzValue="+62"></nz-option>
                      </nz-select>
                    </ng-template>
                    <input formControlName="waNumber" id="'waNumber'" nz-input />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="registeredAddress" class="text-left"
                  >Registered Address</nz-form-label
                >
                <nz-form-control nzErrorTip="Please input registered address correctly!">
                  <textarea
                    rows="2"
                    id="'registeredAddress'"
                    nz-input
                    formControlName="registeredAddress"
                    placeholder="Input Registered Address ..."
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="currentAddress" class="text-left"
                  >Current Address</nz-form-label
                >
                <nz-form-control nzErrorTip="Please input current address correctly!">
                  <textarea
                    rows="2"
                    id="'currentAddress'"
                    nz-input
                    formControlName="currentAddress"
                    placeholder="Input Current Address ..."
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-lg-3 col-md-6">
              <nz-form-item>
                <nz-form-label nzFor="maritalStatus" class="text-left"
                  >Marital Status</nz-form-label
                >
                <nz-form-control nzErrorTip="Please select marital status!">
                  <nz-select
                    formControlName="maritalStatus"
                    nzPlaceHolder="Select Marital Status ..."
                  >
                    <nz-option nzLabel="Single" nzValue="Single"></nz-option>
                    <nz-option nzLabel="Maried" nzValue="Maried"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-3 col-md-6">
              <nz-form-item>
                <nz-form-label nzFor="numberOfChild" class="text-left"
                  >Number Of Child</nz-form-label
                >
                <nz-form-control nzErrorTip="Please select marital status!">
                  <nz-input-number
                    formControlName="numberOfChild"
                    [nzMin]="1"
                    [nzMax]="10"
                    [nzStep]="1"
                  >
                  </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <nz-collapse>
        <nz-collapse-panel nzHeader="Employee Work Information">
          <div class="row">
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="position" class="text-left">Position</nz-form-label>
                <nz-form-control nzErrorTip="Please select position!">
                  <nz-select
                    nzShowSearch
                    nzServerSearch
                    nzAllowClear
                    id="'position'"
                    formControlName="position"
                    nzPlaceHolder="Select Position ..."
                    (nzOnSearch)="search($event, 'positions')"
                  >
                    <ng-container *ngFor="let po of positionOptions">
                      <nz-option
                        *ngIf="!isLoadingOptions"
                        [nzValue]="po.code"
                        [nzLabel]="po.codeName"
                      ></nz-option>
                    </ng-container>
                    <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Loading Data...
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="placement" class="text-left">Placement</nz-form-label>
                <nz-form-control nzErrorTip="Please input placement correctly!">
                  <!-- <input
                    nz-input
                    id="'placement'"
                    formControlName="placement"
                    placeholder="Input Placement Employee ..."
                  /> -->
                  <nz-select
                    nzShowSearch
                    nzServerSearch
                    nzAllowClear
                    id="'placement'"
                    formControlName="placement"
                    nzPlaceHolder="Select Placement Employee ..."
                    (nzOnSearch)="search($event, 'placement')"
                  >
                    <ng-container *ngFor="let po of placementOptions">
                      <nz-option
                        *ngIf="!isLoadingOptions"
                        [nzValue]="po.code"
                        [nzLabel]="po.codeName"
                      ></nz-option>
                    </ng-container>
                    <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Loading Data...
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="contractNumber" class="text-left"
                  >Contract Number</nz-form-label
                >
                <nz-form-control nzErrorTip="Please input contract number correctly!">
                  <input
                    nz-input
                    id="'contractNumber'"
                    formControlName="contractNumber"
                    placeholder="Input Contract Number ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="contractRange" class="text-left"
                  >Contract Range</nz-form-label
                >
                <nz-form-control nzErrorTip="Please select contract range!">
                  <nz-range-picker
                    formControlName="rangeContract"
                    (ngModelChange)="onChange($event)"
                  ></nz-range-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="joinDate" class="text-left">Join Date</nz-form-label>
                <nz-form-control nzErrorTip="Please select join date!">
                  <nz-date-picker formControlName="joinDate"></nz-date-picker>
                  <!-- (ngModelChange)="whenChange($event)" -->
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="resignDate" class="text-left">Resign Date</nz-form-label>
                <nz-form-control nzErrorTip="Please select resign date!">
                  <nz-date-picker formControlName="resignDate"></nz-date-picker>
                  <!-- (ngModelChange)="whenChange($event)" -->
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="reason" class="text-left">Resign Reason</nz-form-label>
                <nz-form-control nzErrorTip="Please input reason correctly!">
                  <input
                    nz-input
                    id="'reason'"
                    formControlName="reason"
                    placeholder="Input Resign Reason ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="employementStatus" class="text-left"
                  >Employement Status</nz-form-label
                >
                <nz-form-control nzErrorTip="Please select employement status!">
                  <nz-select
                    nzShowSearch
                    nzServerSearch
                    nzAllowClear
                    id="'employementStatus'"
                    formControlName="employementStatus"
                    nzPlaceHolder="Select Employement Status ..."
                    (nzOnSearch)="search($event, 'employementStatus')"
                  >
                    <ng-container *ngFor="let eso of employementStatusOptions">
                      <nz-option
                        *ngIf="!isLoadingOptions"
                        [nzValue]="eso.code"
                        [nzLabel]="eso.codeName"
                      ></nz-option>
                    </ng-container>
                    <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Loading Data...
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div class="col-lg-6 col-md-12">
      <nz-collapse>
        <nz-collapse-panel nzHeader="Employee Payment Information">
          <div class="row">
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="bankCode" class="text-left">Bank Name</nz-form-label>
                <nz-form-control nzErrorTip="Please select bank name!">
                  <nz-select
                    nzShowSearch
                    nzServerSearch
                    nzAllowClear
                    id="'bankCode'"
                    formControlName="bankCode"
                    nzPlaceHolder="Select Bank ..."
                    (nzOnSearch)="search($event, 'bankInfo')"
                  >
                    <ng-container *ngFor="let bio of bankInfoOptions">
                      <nz-option
                        *ngIf="!isLoadingOptions"
                        [nzValue]="bio.code"
                        [nzLabel]="bio.codeName"
                      ></nz-option>
                    </ng-container>
                    <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Loading Data...
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="bankAccountName" class="text-left"
                  >Account Name</nz-form-label
                >
                <nz-form-control nzErrorTip="Please input account name correctly!">
                  <input
                    nz-input
                    id="'bankAccountName'"
                    formControlName="bankAccountName"
                    placeholder="Input Account Name ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="bankAccountNumber" class="text-left"
                  >Account Number</nz-form-label
                >
                <nz-form-control nzErrorTip="Please input account number correctly!">
                  <input
                    nz-input
                    id="'bankAccountNumber'"
                    formControlName="bankAccountNumber"
                    placeholder="Input Account Number ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>

  <div class="col-6 pt-3">
    <nz-checkbox-wrapper style="width: 100%;">
      <label nz-checkbox (nzCheckedChange)="checked($event)">Sync to OSAS Account</label>
    </nz-checkbox-wrapper>
  </div>

  <div class="row pt-4" *ngIf="val === true">
    <div class="col-lg-6 col-md-12">
      <nz-collapse>
        <nz-collapse-panel nzHeader="Employee Information Sync to OSAS">
          <div class="row">
            <div class="col-6">
              <nz-form-item>
                <nz-form-label class="text-left">Roles</nz-form-label>
                <nz-form-control nzErrorTip="Please select roles OSAS!">
                  <nz-select formControlName="roles" nzPlaceHolder="Select Roles ...">
                    <ng-container *ngFor="let role of roleOsasOptions">
                      <nz-option
                        *ngIf="!isLoadingOptions"
                        [nzValue]="role.name"
                        [nzLabel]="role.name"
                      ></nz-option>
                    </ng-container>
                    <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Loading Data...
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-6">
              <nz-form-item>
                <nz-form-label class="text-left">Agent Location</nz-form-label>
                <nz-form-control nzErrorTip="Please select agent location!">
                  <nz-select
                    nzShowSearch
                    nzServerSearch
                    nzAllowClear
                    formControlName="location_agent"
                    nzPlaceHolder="Select Agent Location ..."
                    (nzOnSearch)="searchOSAS($event)"
                  >
                    <ng-container *ngFor="let bio of agentLocationsList">
                      <nz-option
                        *ngIf="!isLoadingOptions"
                        [nzValue]="bio.location_name"
                        [nzLabel]="bio.location_name"
                      ></nz-option>
                    </ng-container>
                    <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Loading Data...
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
</form>
