import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ApiService } from '../api.service'
import { Observable } from 'rxjs'
import store from 'store'
import { QueryTable } from '../interface/general.model'
import { VoucherForm } from '../interface/voucher.model'

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  //--   VOUCHER SERVICES   --//

  createVoucher(form: VoucherForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/onapps/voucher/create',
      form,
      params,
    )
  }

  updateVoucher(form: VoucherForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/onapps/voucher/update',
      form,
      params,
    )
  }

  getVoucher(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/onapps/voucher/list/private',
      queryTable,
      params,
    )
  }

  getVoucherNewUser(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/onapps/voucher/list/new-user',
      queryTable,
      params,
    )
  }

  detailVoucher(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/onapps/voucher/detail/private',
      params,
    )
  }

  deleteVoucher(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    const body = {
      id: id,
    }
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/onapps/voucher/delete',
      body,
      params,
    )
  }

  destinationSearchNew(destination: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.get(
      this.apiService.API_OSAS_SERVER +
        '/check-fee/destination/searchlanding?search_dest=' +
        destination,
      { headers: customHeaders },
    )
  }

  serviceList(): Observable<any> {
    let customHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.get(this.apiService.API_OSAS_SERVER + '/utility/service-list', {
      headers: customHeaders,
    })
  }

  onappsUserSearch(content: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_USERSTATE_SERVER + '/onapps/user-search',
      { search: content },
      params,
    )
  }

  detailConfigsVoucher(id: number): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/onapps/voucher/config/details',
      params,
    )
  }

  updateVoucherConfig(form: VoucherForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/onapps/voucher/config/update',
      form,
      params,
    )
  }
}
