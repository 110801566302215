import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/services/api.service'

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  form: FormGroup
  image: any
  constructor(
    private fb: FormBuilder,
    private apiServer: ApiService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.form = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
      code: [null, Validators.compose([Validators.required])],
      name: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])],
      role: [null, Validators.compose([Validators.required])],
      position: [null, Validators.compose([Validators.required])],
      // profile_img: [null, Validators.compose([Validators.required])],
    })
    this.apiServer.userInfo().subscribe(r => {
      this.form.patchValue({
        username: r.username,
        code: r.code,
        name: r.name,
        email: r.email,
        role: r.roleName,
        position: r.position,
      })
      this.image = r.profile_img
    })
  }

  ngOnInit(): void {}

  changePassword() {
    if (!this.router.url.includes('change-password')) {
      this.router.navigate(['change-password'], { relativeTo: this.route })
    } else {
      this.router.navigate(['/user-profile'])
    }
  }
}
