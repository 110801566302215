<form nz-form [formGroup]="form">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <nz-form-item>
        <nz-form-label nzFor="vendor" class="text-left">Vendor</nz-form-label>
        <nz-form-control nzErrorTip="Please select vendor!">
          <nz-select
            nzShowSearch
            nzServerSearch
            nzAllowClear
            id="vendor"
            formControlName="vendor"
            nzPlaceHolder="Select Vendor ..."
            (nzOnSearch)="search($event, 'vendor')"
          >
            <nz-option
              *ngFor="let vo of vendorOptions"
              [nzValue]="vo.code"
              [nzLabel]="vo.codeName"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-6 col-md-12">
      <nz-form-item>
        <nz-form-label nzFor="waybill" class="text-left">Waybill</nz-form-label>
        <nz-form-control nzErrorTip="Please input waybill correctly!">
          <input nz-input id="waybill" formControlName="waybill" placeholder="Input Waybill ..." />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-6 col-md-12">
      <nz-form-item>
        <nz-form-label nzFor="agent" class="text-left">Agent</nz-form-label>
        <nz-form-control nzErrorTip="Please select agent!">
          <nz-select
            nzShowSearch
            nzServerSearch
            nzAllowClear
            id="agent"
            formControlName="agent"
            nzPlaceHolder="Select Agent ..."
            (nzOnSearch)="search($event, 'agent')"
          >
            <nz-option
              *ngFor="let ao of agentOptions"
              [nzValue]="ao.code"
              [nzLabel]="ao.codeName"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-6 col-md-12">
      <nz-form-item>
        <nz-form-label nzFor="person" class="text-left">Person</nz-form-label>
        <nz-form-control nzErrorTip="Please input person correctly!">
          <input nz-input id="person" formControlName="person" placeholder="Input Person ..." />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-12 col-md-12">
      <nz-form-item>
        <nz-form-label nzFor="problemType" class="text-left">Problem Type</nz-form-label>
        <nz-form-control nzErrorTip="Please select problem type">
          <nz-select
            nzShowSearch
            nzServerSearch
            nzAllowClear
            id="problemType"
            formControlName="problemType"
            nzPlaceHolder="Select Problem Type ..."
            (nzOnSearch)="search($event, 'problemType')"
          >
            <nz-option
              *ngFor="let pto of problemTypeOptions"
              [nzValue]="pto.code"
              [nzLabel]="pto.codeName"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-12 col-md-12">
      <nz-form-item>
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="description" class="text-left"
          >Description</nz-form-label
        >
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please input description!">
          <textarea
            rows="4"
            id="description"
            nz-input
            formControlName="description"
            placeholder="Input Description ..."
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-12 col-md-12">
      <nz-upload
        nzListType="picture-card"
        [(nzFileList)]="fileList" [nzMultiple]="true"
        [nzPreview]="handlePreview" [nzDownload]="handleDownload" [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq" [nzBeforeUpload]="handleBeforeUpload"
      >
        <div>
          <i nz-icon nzType="plus"></i>
          <div style="margin-top: 8px">Upload</div>
        </div>
      </nz-upload>
    </div>
  </div>
</form>
