<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table
          #fixedTable
          [nzShowSizeChanger]="false"
          class="table-driver"
          [nzData]="listOfData"
          [nzFrontPagination]="false"
          [nzLoading]="isLoading"
          [nzTotal]="totalData"
          [nzPageSize]="pageSize"
          [nzPageIndex]="pageIndex"
          (nzQueryParams)="onQueryParamsChange($event)"
          [nzScroll]="{ y: '42vh' }"
          [nzTitle]="headerTable"
          nzSize="small"
        >
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <th
                  *ngIf="column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzSortFn]="column.sortFn"
                  [(nzSortOrder)]="column.sortOrder"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
                <th
                  *ngIf="!column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of fixedTable.data">
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <td *ngIf="column.key == 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Driver">
                    <a class="btn btn-sm btn-light mr-2" (click)="viewDriver(data.driverId)">
                      <i class="fe fe-eye"></i>
                    </a>
                  </span>
                </td>
                <td *ngIf="column.key != 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  {{ data[column.key] }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<ng-template #tplFooter let-ref="modalRef">
  <button
    nz-button
    (click)="ref.destroy()"
    [disabled]="ref.getContentComponent().isLoading"
    nzDanger
  >
    Cancel
  </button>
  <button
    nz-button
    nzType="primary"
    (click)="ref.getContentComponent().submitForm()"
    [nzLoading]="ref.getContentComponent().isLoading"
  >
    Create
  </button>
</ng-template>

<ng-template #headerTable>
  <nz-spin [nzSpinning]="isDownloading">
    <div class="row justify-content-between">
      <div class="col">
        <!-- <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Add New Drop Point">
          <a class="btn btn-lg btn-light mr-2" (click)="addDropPoint()">
            <i class="fe fe-plus-circle"></i>
          </a>
        </span> -->
        <!-- <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Restart Filter & Sort">
          <a class="btn btn-lg btn-light mr-2" (click)="reset()">
            <i class="fe fe-refresh-cw"></i>
          </a>
        </span> -->
        <!-- <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Export Tickets">
          <a class="btn btn-lg btn-light mr-2" (click)="exportTickets()">
            <i class="fe fe-download"></i>
          </a>
        </span> -->
      </div>
      <div class="col">
        <form nz-form [formGroup]="form" (ngSubmit)="search()" class="float-right">
          <div
            formArrayName="filters"
            *ngFor="let filter of advancedFilters.controls; let i = index"
          >
            <div nz-form [formGroupName]="i" [nzLayout]="'inline'">
              <nz-form-item>
                <nz-form-control>
                  <nz-select
                    id="prefix"
                    formControlName="prefix"
                    nzPlaceHolder="Select Prefix ..."
                    style="width: 150px;"
                  >
                    <nz-option nzValue="name" nzLabel="Name"></nz-option>
                    <nz-option nzValue="email" nzLabel="Email"></nz-option>
                    <nz-option nzValue="phoneNumber" nzLabel="Phone Number"></nz-option>
                    <!-- <nz-option nzValue="status" nzLabel="Status"></nz-option> -->
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control>
                  <ng-container
                    *ngIf="
                      [
                        'name',
                        'email',
                        'phoneNumber',
                      ].includes(advancedFilters.at(0).get('prefix').value)
                    "
                  >
                    <input formControlName="keyword" nz-input placeholder="Keyword ..." />
                  </ng-container>
                  <ng-container
                    *ngIf="['updatedAt',].includes(advancedFilters.at(0).get('prefix').value)"
                  >
                    <nz-range-picker formControlName="keyword"></nz-range-picker>
                  </ng-container>
                  <!-- <ng-container *ngIf="advancedFilters.at(0).get('prefix').value === 'status'">
                  <nz-select formControlName="keyword" nzPlaceHolder="Select Status ...">
                    <nz-option nzValue="Open" nzLabel="Open"></nz-option>
                    <nz-option nzValue="In Progress" nzLabel="In Progress"></nz-option>
                    <nz-option nzValue="Remainders" nzLabel="Remainders"></nz-option>
                    <nz-option nzValue="Request Delete" nzLabel="Request Delete"></nz-option>
                    <nz-option nzValue="Solved" nzLabel="Solved"></nz-option>
                  </nz-select>
                </ng-container> -->
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-control>
                  <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
                    Search
                  </button>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </div>
  </nz-spin>
</ng-template>
<router-outlet></router-outlet>
