<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading-3
            [data]="{
              title: 'Charts / Chartjs',
              button: 'Go to Docs',
              url: 'https://www.chartjs.org/'
            }"
          ></vb-headers-heading-3>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{ title: 'Line Chart' }"></vb-headers-card-header>
        </div>
        <div class="card-body">
          <div class="row">
            <vb-charts-chartjs-1></vb-charts-chartjs-1>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{ title: 'Radar Chart' }"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartjs-3></vb-charts-chartjs-3></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{ title: 'Pie Chart' }"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartjs-5></vb-charts-chartjs-5></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{ title: 'Bar Chart' }"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartjs-2></vb-charts-chartjs-2></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{ title: 'Polar Area Chart' }"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartjs-4></vb-charts-chartjs-4></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{ title: 'Doughnut Chart' }"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartjs-6></vb-charts-chartjs-6></div>
      </div>
    </div>
  </div>
</div>
