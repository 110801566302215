import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { HttpClient } from '@angular/common/http'
import { ApiService } from 'src/app/services/api.service'
import { TicketingService } from 'src/app/services/ticketing.service'
import { AgentAccountForm } from 'src/app/services/interface/ticketing.model'

interface options {
  id: string
  title: string
}

@Component({
  selector: 'app-edit-agent',
  templateUrl: './edit-agent.component.html',
})
export class EditAgentComponent implements OnInit {
  @Input() username: string
  @Input() type: string
  isLoading: boolean = false
  isLoadingOptions: boolean = false
  signer: string
  roleArr: options[] = [
    {
      id: 'admin',
      title: 'Admin',
    },
    {
      id: 'dc',
      title: 'DC',
    },
    {
      id: 'finance',
      title: 'Finance',
    },
    {
      id: 'indopaket',
      title: 'Indopaket',
    },
    {
      id: 'internal_test',
      title: 'Internal Test',
    },
    {
      id: 'kurir delivery',
      title: 'Kurir Delivery',
    },
    {
      id: 'kurir pickup',
      title: 'Kurir Pickup',
    },
    {
      id: 'moderator',
      title: 'Moderator',
    },
    {
      id: 'pos_uat',
      title: 'POS UAT',
    },
    {
      id: 'sa',
      title: 'SA',
    },
    {
      id: 'tester',
      title: 'Tester',
    },
    {
      id: 'vip',
      title: 'VIP',
    },
    {
      id: 'driver',
      title: 'Driver',
    },
    {
      id: 'qc',
      title: 'QC',
    },
  ]
  constructor(
    private fb: FormBuilder,
    private ticketService: TicketingService,
    private apiService: ApiService,
    private store: Store<any>,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private http: HttpClient,
  ) {
    this.form = this.fb.group({
      first_name: [null, Validators.compose([Validators.required])],
      last_name: [null],
      phone: [null, Validators.compose([Validators.required])],
      location_agent: [null, Validators.compose([Validators.required])],
      role: [null, Validators.compose([Validators.required])],
      email: [null],
      username: [null, Validators.compose([Validators.required])],
      password: [null],
      disabled: [null],
    })

    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.signer = state.username
    })
  }

  form: FormGroup
  switchValue = false
  loading = false

  ngOnInit(): void {
    this.fetchAgentDetails()
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  fetchAgentDetails() {
    this.isLoading = true
    this.ticketService.detailAgentAccount(this.username, this.type).subscribe(
      r => {
        if (r.disabled !== null) {
          this.switchValue = r.disabled
        }
        this.form.patchValue(r)
        for (const i in this.form.controls) {
          if (this.form.controls.hasOwnProperty(i)) {
            this.form.controls[i].markAsDirty()
            this.form.controls[i].updateValueAndValidity()
          }
        }
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  clickSwitch(): void {
    if (!this.loading) {
      this.loading = true
      setTimeout(() => {
        this.switchValue = !this.switchValue
        this.loading = false
        this.form.value.disabled = this.switchValue
      }, 10)
    }
  }

  submitForm = (): void => {
    this.isLoading = true
    var isUploading = false
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    if (!isUploading && this.form.valid) {
      var formSubs: AgentAccountForm = this.form.value
      formSubs.updated_by = this.signer
      formSubs.type = this.type
      this.ticketService.updateAgentAccount(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.isLoading = false
          this.modal.destroy()
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error(
        isUploading ? 'Please wait until all files uploaded' : 'Please Fill Blank Form',
      )
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return
    }
  }
}
