<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">

        <nz-table #sortTable [nzData]="assetList" [nzLoading]="isLoading" [nzTotal]="totalData"
          [nzPageIndex]="pageIndex" [nzPageSize]="pageSize" [nzPageSizeOptions]="[25, 50, 100, 250]"
          [nzScroll]="{ y: '50vh'}" nzShowSizeChanger [nzTitle]="headerTable" nzSize="small"
          (nzQueryParams)="onQueryParamsChange($event)">

          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns">
                <th [nzWidth]="column.width" [nzSortFn]="column.compare" [nzSortPriority]="column.priority">
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let data of sortTable.data">
              <td>
                <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Add History">
                  <button class="btn btn-sm btn-light mr-2" (click)="toggleAddHistory(data)">
                    <i class="fe fe-plus"></i>
                  </button>
                </span>
                <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View History">
                  <button class="btn btn-sm btn-light mr-2" (click)="toggleViewHistory(data)">
                    <i class="fe fe-eye"></i>
                  </button>
                </span>
                <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Export History"
                  (click)="exportHistory(data.asset_number)">
                  <button class="btn btn-sm btn-light mr-2">
                    <i class="fe fe-download"></i>
                  </button>
                </span>
              </td>
              <td>{{data.asset_number}}</td>
              <td>{{data.asset_name}}</td>
              <td>{{data.location}}</td>
              <td>{{data.serial_number}}</td>
              <td>{{data.created_by}}</td>
              <td>{{data.updated_by}}</td>
              <td>{{data.created_at | date:'yyyy-MM-dd HH:mm:ss' }}</td>
              <td>{{data.updated_at | date:'yyyy-MM-dd HH:mm:ss' }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<ng-template #headerTable>
  <nz-spin [nzSpinning]="isDownloading">
    <div class="row">
      <form nz-form [nzLayout]="'inline'" [formGroup]="filterForm">
        <nz-form-item>
          <nz-form-control>
            <nz-input-group [nzSuffix]="qrIcon">
              <input nz-input placeholder="Asset Number" style="border-radius: 8px;margin-left:5px"
                formControlName="assetNumber" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

      </form>
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Refresh">
        <a class="btn btn-lg btn-light mr-2" (click)="ngOnInit()">
          <i class="fe fe-refresh-cw"></i>
        </a>
      </span>
    </div>
  </nz-spin>
</ng-template>

<ng-template #qrIcon><span nz-icon nzType="qrcode" nzTheme="outline"></span></ng-template>


<nz-modal [(nzVisible)]="addModalVisible" [nzWidth]="'80%'" [nzTitle]="addModalTitle" [nzContent]="addModalContent"
  [nzFooter]="addModalFooter" (nzOnCancel)="toggleCancelAddHistory()">
  <ng-template #addModalTitle>Add History</ng-template>

  <ng-template #addModalContent>
    <nz-spin [nzSpinning]="addModalLoading">
      <form nz-form [formGroup]="assetsForm">
        <nz-collapse>
          <nz-collapse-panel [nzHeader]="'Employee Data'" [nzActive]="true">
            <div class="row">
              <div class="col">
                <nz-form-item>
                  <nz-form-label nzFor="employee_name" class="text-left col-4" nzRequired>Employee Name</nz-form-label>
                  <nz-form-control>
                    <input id="employee_name" type="text" nz-input style=" border-radius: 8px;"
                      formControlName="selectedEmployee" [nzAutocomplete]="employee" />
                    <nz-autocomplete class="autocomplete" nzBackfill #employee>
                      <nz-auto-option *ngFor="let item of employeeList" [nzValue]="item"
                        [nzLabel]="item.name">{{item.name}}</nz-auto-option>
                    </nz-autocomplete>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                  <nz-form-label nzFor="department" class="text-left col-4" nzRequired>Department</nz-form-label>
                  <nz-form-control>
                    <nz-select id="department" formControlName="department"
                      [nzDisabled]="assetsForm.get('company').value.length<1">
                      <ng-container *ngFor="let item of departmentList">
                        <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                      </ng-container>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label class="text-left col-4">Employee Picture</nz-form-label>
                  <nz-form-control>
                    <nz-upload nzName="picture" nzListType="picture-card" [nzLimit]="1"
                      [(nzFileList)]="employeePictList" [nzCustomRequest]="customUpload" [nzPreview]="handlePreview"
                      [nzBeforeUpload]="beforeUpload" (nzChange)="handleChangeEmployee($event) "
                      [nzDownload]="handleDownload" [nzRemove]="handleRemove"
                      [nzAccept]="'image/jpeg, image/jpg, image/png'" [nzDisabled]="employeePictList.length>0"
                      [nzShowButton]="!(employeePictList.length>0)">
                      <div>
                        <span nz-icon nzType="plus"></span>
                        <div style="margin-top: 8px">Upload</div>
                      </div>
                    </nz-upload>
                  </nz-form-control>
                </nz-form-item>

              </div>
              <div class="col">
                <nz-form-item>
                  <nz-form-label nzFor="company" class="text-left col-4" nzRequired>Company</nz-form-label>
                  <nz-form-control>
                    <nz-select id="company" formControlName="company" (ngModelChange)="handleSelectCompany()">
                      <ng-container *ngFor="let item of companyList">
                        <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                      </ng-container>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="phone_number" class="text-left col-4" nzRequired>Phone Number</nz-form-label>
                  <nz-form-control>
                    <input id="phone_number" formControlName="phoneNumber" type="text" nz-input
                      style=" border-radius: 8px;" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="email" class="text-left col-4">Email</nz-form-label>
                  <nz-form-control>
                    <input id="email" type="text" nz-input style=" border-radius: 8px;" formControlName="email" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-collapse-panel>
          <nz-collapse-panel [nzHeader]="'Asset Data'" [nzActive]="false">
            <div class="row">
              <div class="col">
                <nz-form-item>
                  <nz-form-label nzFor="asset_name" class="text-left col-4" nzRequired>Asset Name</nz-form-label>
                  <nz-form-control>
                    <input id="asset_name" type="text" nz-input style=" border-radius: 8px;"
                      formControlName="assetName" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="serial_number" class="text-left col-4">Serial Number</nz-form-label>
                  <nz-form-control>
                    <input id="serial_number" type="text" nz-input style=" border-radius: 8px;"
                      formControlName="serialNumber" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="purchased_date" class="text-left col-4" nzRequired>Purchased
                    Date</nz-form-label>
                  <nz-form-control>
                    <nz-date-picker formControlName="purchasedDate"></nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="status" class="text-left col-4" nzRequired>Status</nz-form-label>
                  <nz-form-control>
                    <nz-select id="status" formControlName="status">
                      <ng-container *ngFor="let item of statusList">
                        <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                      </ng-container>
                    </nz-select>
                    <!-- <input id="status" type="text" nz-input style=" border-radius: 8px;" /> -->
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="service" class="text-left col-4">Service</nz-form-label>
                  <nz-form-control>
                    <input id="service" type="text" nz-input style=" border-radius: 8px;" formControlName="service"
                      (input)="formatNumber($event,'service')" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="serviceAt" class="text-left col-4">Service At</nz-form-label>
                  <nz-form-control>
                    <input id="serviceAt" type="text" nz-input style=" border-radius: 8px;color:black"
                      formControlName="serviceAt" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="location" class="text-left col-4" nzRequired>Location</nz-form-label>
                  <nz-form-control>
                    <nz-select id="location" formControlName="location">
                      <ng-container *ngFor="let item of locationList">
                        <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                      </ng-container>
                    </nz-select>
                    <!-- <input id="location" type="text" nz-input style=" border-radius: 8px;" /> -->
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label class="text-left col-4" nzRequired>Asset Picture</nz-form-label>
                  <nz-form-control>
                    <nz-upload nzName="picture" nzListType="picture-card" [nzShowUploadList]="true" [nzMultiple]="false"
                      [(nzFileList)]="assetPictList" [nzLimit]="1" [nzPreview]="handlePreview"
                      [nzCustomRequest]="customUpload" [nzDownload]="handleDownload" [nzRemove]="handleRemove"
                      [nzBeforeUpload]="beforeUpload" (nzChange)="handleChangeAsset($event)"
                      [nzDisabled]="assetPictList.length>20" [nzShowButton]="!(assetPictList.length>20)">
                      <div>
                        <span nz-icon nzType="plus"></span>
                        <div style="margin-top: 8px">Upload</div>
                      </div>
                    </nz-upload>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col">
                <nz-form-item>
                  <nz-form-label nzFor="type" class="text-left col-4" nzRequired>Type</nz-form-label>
                  <nz-form-control>
                    <nz-select id="type" formControlName="type" [nzDisabled]="true">
                      <nz-option [nzValue]="assetsForm.value.type" [nzLabel]="assetsForm.value.type"></nz-option>
                    </nz-select>
                    <!-- <input id="type" type="text" nz-input style=" border-radius: 8px;" /> -->
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="brand" class="text-left col-4" nzRequired>Brand</nz-form-label>
                  <nz-form-control>
                    <nz-select id="brand" formControlName="brand" [nzDisabled]="true">
                      <nz-option [nzValue]="assetsForm.value.brand" [nzLabel]="assetsForm.value.brand"></nz-option>
                    </nz-select>
                    <!-- <input id="brand" type="text" nz-input style=" border-radius: 8px;" /> -->
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="condition" class="text-left col-4" nzRequired>Condition</nz-form-label>
                  <nz-form-control>
                    <nz-select id="condition" formControlName="condition">
                      <ng-container *ngFor="let item of conditionList">
                        <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                      </ng-container>
                    </nz-select>
                    <!-- <input id="condition" type="text" nz-input style=" border-radius: 8px;" /> -->
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="description" class="text-left col-4">Description</nz-form-label>
                  <nz-form-control>
                    <textarea id="description" nz-input style=" border-radius: 8px;height: 100px;"
                      formControlName="description"></textarea>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="initial_value" class="text-left col-4" nzRequired>Initial Value</nz-form-label>
                  <nz-form-control>
                    <nz-input-group nzPrefix="Rp.">
                      <input id="initial_value" type="text" nz-input formControlName="initialValue"
                        style="border-radius: 8px;" numbersOnly />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="deprecation_rate" class="text-left col-4" nzRequired>Deprecation
                    Rate</nz-form-label>
                  <nz-form-control>
                    <nz-input-group nzSuffix="%" style="width:49%;">
                      <input id="deprecation_rate" type="text" nz-input formControlName="depreciationRate"
                        placeholder="Rate" style="border-radius: 8px" />
                    </nz-input-group>
                    <nz-input-group nzSuffix="month" style="width:49%; margin-left: 2%;">
                      <input numbersOnly id="period" type="text" nz-input formControlName="period" placeholder="Period"
                        style="border-radius: 8px;" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="note" class="text-left col-4">Note</nz-form-label>
                  <nz-form-control>
                    <textarea id="note" nz-input style=" border-radius: 8px; height: 100px;"
                      formControlName="note"></textarea>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-collapse-panel>
        </nz-collapse>
      </form>
    </nz-spin>
  </ng-template>

  <ng-template #addModalFooter>
    <button nz-button nzType="default" (click)="toggleCancelAddHistory()">Cancel</button>
    <button nz-button nzType="primary" [disabled]="!this.assetsForm.valid" (click)="addHistoryOk()">Confirm</button>
  </ng-template>

</nz-modal>

<nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
  (nzOnCancel)="previewVisible = false">
  <ng-template #modalContent>
    <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
  </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="viewModalVisible" [nzWidth]="'80%'" [nzTitle]="viewModalTitle" [nzContent]="viewModalContent"
  [nzFooter]="viewModalFooter" (nzOnCancel)="toggleCancelViewHistory()">

  <ng-template #viewModalTitle>Asset History</ng-template>
  <ng-template #viewModalContent>
    <nz-card style="line-height: 10px;background-color: #e4e9f0;">
      <p style=" font-size: 21px;">{{selectedAsset.asset_name}}</p>
      <p>{{selectedAsset.serial_number}}</p>
    </nz-card>
    <nz-card [nzTitle]="'Detail Asset'" [nzBodyStyle]="{backgroundColor:'#fff'}"
      style="margin-top: 10px;background-color: #e4e9f0;">
      <div nz-row [nzGutter]="5">
        <div nz-col [nzSpan]="6">
          <nz-card [nzBordered]="false">
            <div class="d-flex flex-column align-items-center" (click)="handleImagePreview(viewHistoryImageSrc)">
              <img *ngIf="viewHistoryImageSrc.length>0" nz-image width="120px" height="120px"
                [nzSrc]="viewHistoryImageSrc[0].src" [nzFallback]="imageFallback" alt="asset-image" />
              <img *ngIf="viewHistoryImageSrc.length===0" nz-image width="120px" height="120px" [nzSrc]="error"
                [nzFallback]="imageFallback" alt="asset-image" />
              <!-- <nz-image-group>
                <img nz-image *ngFor="let src of viewHistoryImageSrc" [nzSrc]="src">
              </nz-image-group> -->
              <h6 style="font-size: 12px; margin-top: 10px;">{{selectedAsset.asset_number}}</h6>
            </div>
          </nz-card>
        </div>
        <div nz-col [nzSpan]="9">
          <nz-card>
            <div nz-row>
              <div nz-col nzSpan="10">Updated At</div>
              <div nz-col>: {{selectedAsset.updated_at |date:'yyyy-MM-dd HH:mm:ss'}}</div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="10">Employee Name</div>
              <div nz-col>: {{selectedAsset.employee_name}}</div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="10">Status</div>
              <div nz-col>: {{selectedAsset.asset_trackings[0].status}}</div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="10">Condition</div>
              <div nz-col>: {{selectedAsset.asset_trackings[0].condition}}</div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="10">Note</div>
              <div nz-col nzSpan="14" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">:
                {{selectedAsset.asset_trackings[0].note}}</div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="10">Registered Date</div>
              <div nz-col>: {{selectedAsset.registered_date |date:'yyyy-MM-dd HH:mm:ss'}}</div>
            </div>
          </nz-card>
        </div>
        <div nz-col [nzSpan]="9">
          <nz-card>
            <div nz-row>
              <div nz-col nzSpan="10">Service</div>
              <div nz-col>: {{selectedAsset.asset_trackings[0].service }}</div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="10">Service at</div>
              <div nz-col>: {{selectedAsset.asset_trackings[0].service_at }}</div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="10">Location</div>
              <div nz-col>: {{selectedAsset.location}}</div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="10">Purchased Date</div>
              <div nz-col>: {{selectedAsset.purchased_date |date:'yyyy-MM-dd'}}</div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="10">Value</div>
              <div nz-col>: {{selectedAsset.asset_trackings[0].value | currency:"Rp.":"symbol":"1.2-2"}}</div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="10">Updated By</div>
              <div nz-col>: {{selectedAsset.updated_by}}</div>
            </div>

          </nz-card>
        </div>
      </div>
    </nz-card>
    <nz-table #historyTable [nzData]="selectedAsset.asset_trackings" nzTableLayout="fixed" [nzScroll]="{ y: '20vh' }"
      nzSize="small" style="margin-top: 10px;">
      <thead>
        <tr>
          <ng-container *ngFor=" let column of listHistoryColumns">
            <th [nzWidth]="column.width" [nzSortFn]="column.compare" [nzSortPriority]="column.priority">
              {{ column.name }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of historyTable.data">
          <td>
            <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View History">
              <button class="btn btn-sm btn-light mr-2" (click)="showHistory(data)">
                <i class="fe fe-eye"></i>
              </button>
            </span>
          </td>
          <td>{{data.updated_at |date:'yyyy-MM-dd HH:mm:ss'}}</td>
          <td>{{data.employee_name}}</td>
          <td>{{data.department}}</td>
          <td>{{data.status}}</td>
        </tr>
      </tbody>
    </nz-table>

  </ng-template>

  <ng-template #viewModalFooter><button nz-button nzType="primary"
      (click)="toggleCancelViewHistory()">Close</button></ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="showHistoryDetail" [nzWidth]="'50%'" [nzTitle]="showHistoryTitle"
  [nzContent]="showHistoryContent" [nzFooter]="showHistoryFooter" (nzOnCancel)="cancelShowHistory()">

  <ng-template #showHistoryTitle>History Detail</ng-template>
  <ng-template #showHistoryContent>
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="'Employee Detail'" [nzActive]="true">
        <div nz-row [nzGutter]="2">
          <div nz-col [nzSpan]="6">
            <nz-card [nzBordered]="false">
              <div class="d-flex flex-column align-items-center">
                <img nz-image width="100px" height="100px" [nzFallback]="imageFallback"
                  [nzSrc]="showHistoryEmployeePicture" alt="employee-image" />
              </div>
            </nz-card>
          </div>

          <div nz-col [nzSpan]="18">
            <nz-card style="width:100%;">
              <div nz-row>
                <div nz-col nzSpan="7">Employee Name</div>
                <div nz-col nzSpan="15">: {{selectedHistory.employee_name}}</div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="7">Company</div>
                <div nz-col nzSpan="15">: {{selectedHistory.company}}</div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="7">Department</div>
                <div nz-col nzSpan="15">: {{selectedHistory.department}}</div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="7">Phone Number</div>
                <div nz-col nzSpan="15">: {{selectedHistory.phone_number}}</div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="7">Email</div>
                <div nz-col nzSpan="15">: {{selectedHistory.email}}</div>
              </div>

            </nz-card>
          </div>
        </div>
      </nz-collapse-panel>
      <nz-collapse-panel [nzHeader]="'Asset Detail'">
        <div nz-row [nzGutter]="2">
          <div nz-col [nzSpan]="6">
            <nz-card [nzBordered]="false">
              <div class="d-flex flex-column align-items-center" (click)="handleImagePreview(showHistoryAssetPicture)">
                <img *ngIf="showHistoryAssetPicture.length>0" nz-image width="100px" height="100px"
                  [nzSrc]="showHistoryAssetPicture[0].src" alt="asset-image" />
                <img *ngIf="showHistoryAssetPicture.length===0" nz-image width="100px" height="100px" [nzSrc]="error"
                  alt="asset-image" [nzFallback]="imageFallback" />
              </div>
            </nz-card>
          </div>

          <div nz-col [nzSpan]="18">
            <nz-card style="width:100%;">
              <div nz-row>
                <div nz-col nzSpan="7">Asset Number</div>
                <div nz-col nzSpan="15">: {{selectedHistory.asset_number}}</div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="7">Asset Name</div>
                <div nz-col nzSpan="15">: {{selectedAsset.asset_name}}</div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="7">Condition</div>
                <div nz-col nzSpan="15">: {{selectedHistory.condition}}</div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="7">Status</div>
                <div nz-col nzSpan="15">: {{selectedHistory.status}}</div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="7">Location</div>
                <div nz-col nzSpan="15">: {{selectedHistory.location}}</div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="7">Service</div>
                <div nz-col nzSpan="15">: {{selectedHistory.service}}</div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="7">Service At</div>
                <div nz-col nzSpan="15">: {{selectedHistory.service_at}}</div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="7">Value</div>
                <div nz-col nzSpan="15">: {{selectedHistory.value |currency:"Rp.":"symbol":"1.2-2"}}</div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="7">Updated At</div>
                <div nz-col nzSpan="15">: {{selectedHistory.updated_at |date:'yyyy-MM-dd HH:mm:ss'}}</div>
              </div>
            </nz-card>
          </div>
        </div>

      </nz-collapse-panel>
    </nz-collapse>
  </ng-template>
  <ng-template #showHistoryFooter><button nz-button nzType="primary"
      (click)="cancelShowHistory()">Close</button></ng-template>
</nz-modal>

<ng-template #toaster>
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon">
        <span nz-icon [nzType]="toasterIcon" [ngStyle]="{ color: toasterColor }"></span>
      </span>
      <div class="ant-notification-notice-message">Message :</div>
      <div class="ant-notification-notice-description">
        {{toasterMessage}}
      </div>
    </div>
  </div>
</ng-template>
<router-outlet></router-outlet>