import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'
import { WebSocketSubject } from 'rxjs/webSocket'
import store from 'store'

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  //DEMO
  // API_SUNSHINE_SERVER = 'https://demosunshineapi.onindonesia.id'
  // // API_SUNSHINE_SERVER = 'http://192.168.30.197:5002'
  // // API_OSAS_SERVER = 'https://demoapi.ondelivery.id'
  // API_OSAS_SERVER = 'https://testapi.ondelivery.id'

  // API_OSAS_LOCAL = 'http://192.168.30.190:4220'
  // API_USERSTATE_SERVER = 'https://demousapi.onindonesia.id'

  // WS_API_SERVER = 'ws://localhost:3001'
  // WS_API_SERVER = 'ws://localhost:5001'

  //PROD
  API_SUNSHINE_SERVER = 'https://sunshineapi.onindonesia.id'
  API_OSAS_SERVER = 'https://api.ondelivery.id'
  API_USERSTATE_SERVER = 'https://usapi.onindonesia.id'
  WS_API_SERVER = 'ws://localhost:3001'

  //STAGING
  // API_SUNSHINE_SERVER = 'https://stagingsunshineapi.onindonesia.id'
  // API_OSAS_SERVER = 'https://stagingapi.ondelivery.id'
  // API_USERSTATE_SERVER = 'https://stagingusapi.onindonesia.id'
  // WS_API_SERVER = 'ws://localhost:3011'

  alfanumerikCustom = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  alphabethCustom = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  numberCustom = '0123456789'
  optionHeader = new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('jwt'),
  })

  public MenuData: any = []

  public notificationTrigger$ = new BehaviorSubject(true)
  wsNotif = new WebSocketSubject({
    url: this.WS_API_SERVER + '/branding-approval/notif',
    protocol: [localStorage.getItem('jwt')!],
  })

  appMobile(): Observable<any> {
    return this.http.get(this.API_SUNSHINE_SERVER + '/api/mobile/info')
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post(this.API_SUNSHINE_SERVER + '/api/auth/login', {
      username,
      password,
      isNative: false,
    })
  }

  register(username: string, password: string, name: string): Observable<any> {
    return this.http.post(this.API_SUNSHINE_SERVER + '/api/auth/register', {
      username,
      password,
      name,
    })
  }

  currentAccount(): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.get(this.API_SUNSHINE_SERVER + '/api/auth/account', params)
  }

  logout(): Observable<any> {
    return this.http.get(this.API_SUNSHINE_SERVER + '/api/auth/logout')
  }

  accessMenu(): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.get(this.API_SUNSHINE_SERVER + '/api/user/accessmenu', params)
  }

  setAccessMenu() {
    this.accessMenu().subscribe(r => (this.MenuData = r))
  }

  deleteFileTemp(filename: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.get(
      this.API_SUNSHINE_SERVER + '/api/temp/attachment/delete/' + filename,
      params,
    )
  }

  userInfo(): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.get(this.API_SUNSHINE_SERVER + '/api/user/info', params)
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.API_SUNSHINE_SERVER + '/api/user/change-password',
      { oldPassword: oldPassword, newPassword: newPassword },
      params,
    )
  }

  resetPassword(username: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.API_SUNSHINE_SERVER + '/api/user/reset-password',
      { username },
      params,
    )
  }

  resetUniqueKey(username: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.API_SUNSHINE_SERVER + '/api/user/reset-unique-identifier',
      { username },
      params,
    )
  }

  get customHeader() {
    const accessToken = store.get('accessToken')
    return new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    })
  }

  /*------------------ Start Template ---------------------*/
  getTemplateJSON(content: any) {
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/template-json/get', content, {
      headers: this.customHeader,
    })
  }

  saveTemplateJSON(content: any) {
    console.log(content, 'content')
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/template-json/save', content, {
      headers: this.customHeader,
    })
  }

  /*------------------ End Template ------------------*/

  ccDone(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })

    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/send/cctome', content, {
      headers: customHeaders,
    })
  }

  async printDoc(content: any) {
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/print', content).toPromise()
  }

  rejectedApproval(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })

    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/send/todotask/reject', content, {
      headers: customHeaders,
    })
  }

  withdrawApproval(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })

    return this.http.post<any>(
      this.API_SUNSHINE_SERVER + '/api/po/send/todotask/withdraw',
      content,
      {
        headers: customHeaders,
      },
    )
  }

  /*------------------ START Super User Branding Approval ------------------*/

  activeApproval(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/get/approval/inprogress', content, {
      headers: customHeaders,
    })
  }
  completedApproval(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/get/approval/completed', content, {
      headers: customHeaders,
    })
  }

  getEmployeeOnRoutes(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/approval/get-on-routes', content, {
      headers: customHeaders,
    })
  }

  executeOnRoutes(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(
      this.API_SUNSHINE_SERVER + '/api/approval/execute/on-routes',
      content,
      {
        headers: customHeaders,
      },
    )
  }
  executeOtherRoutes(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(
      this.API_SUNSHINE_SERVER + '/api/approval/execute/other-routes',
      content,
      { headers: customHeaders },
    )
  }
  getEmployeeeeSuperUser() {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.get<any>(this.API_SUNSHINE_SERVER + '/api/approval/get-other-routes', {
      headers: customHeaders,
    })
  }
  deleteApprovalSU(contet: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/approval/delete/', contet, {
      headers: customHeaders,
    })
  }
  /*------------------ END Super User Branding Approval ------------------*/

  /*------------------ Start Branding Approval ------------------*/

  bulkApprovedTodotask(content: any) {
    return this.http.post<any>(
      this.API_SUNSHINE_SERVER + '/api/branding-approval/approved/bulk',
      content,
      { headers: this.customHeader },
    )
  }

  bulkReadCC(content: any) {
    return this.http.post<any>(
      this.API_SUNSHINE_SERVER + '/api/branding-approval/read/bulk',
      content,
      {
        headers: this.customHeader,
      },
    )
  }

  /*------------------ End Branding Approval ------------------*/

  addMemberReference(type: any, value: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(
      this.API_SUNSHINE_SERVER + '/api/flow/add-member',
      { type, value },
      { headers: customHeaders },
    )
  }

  async gettingRefPO() {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http
      .get<any>(this.API_SUNSHINE_SERVER + '/api/po/reference/main', {
        headers: customHeaders,
      })
      .toPromise()
  }

  async gettingRefPOCustom(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http
      .post<any>(this.API_SUNSHINE_SERVER + '/api/po/reference/custom', content, {
        headers: customHeaders,
      })
      .toPromise()
  }

  gettingRefPOEditConf() {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.get<any>(this.API_SUNSHINE_SERVER + '/api/po/reference/main/editable', {
      headers: customHeaders,
    })
  }

  // attachments
  savingAttachmenntsBA(content: any) {
    console.log(content)
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/ba/attachments', content, {
      reportProgress: true,
      observe: 'events',
    })
  }

  // Branding Approval
  savingBA(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/ba/saving-form', content, {
      headers: customHeaders,
    })
  }
  getToDoTaskBasic(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/get/todotask/basic', content, {
      headers: customHeaders,
    })
  }

  // async getApprovalDetail(content: any) {
  //   let customHeaders = new HttpHeaders({
  //     Authorization: "Bearer " + localStorage.getItem("jwt"),
  //   });
  //   return this.http
  //     .post<any>(this.API_SUNSHINE_SERVER + "/po/get/todotask/details", content, {
  //       headers: customHeaders,
  //     })
  //     .toPromise();
  // }

  async formDetails(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http
      .post<any>(this.API_SUNSHINE_SERVER + '/api/po/get/todotask/details', content, {
        headers: customHeaders,
      })
      .toPromise()
  }

  getCompletedTaskBasic(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/get/completed/basic', content, {
      headers: customHeaders,
    })
  }

  async getCompletedTaskDetails(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http
      .post<any>(this.API_SUNSHINE_SERVER + '/api/po/get/completed/details', content, {
        headers: customHeaders,
      })
      .toPromise()
  }

  getCCToMeBasic(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/get/cc/basic', content, {
      headers: customHeaders,
    })
  }

  async getCCToMeDetails(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http
      .post<any>(this.API_SUNSHINE_SERVER + '/api/po/get/cc/details', content, {
        headers: customHeaders,
      })
      .toPromise()
  }

  saveUserRestrictions(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(
      this.API_SUNSHINE_SERVER + '/api/user/user-restrictions/save',
      content,
      {
        headers: customHeaders,
      },
    )
  }

  nextOnBA(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/send/todotask', content, {
      headers: customHeaders,
    })
  }

  returnOnBA(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/return/todotask', content, {
      headers: customHeaders,
    })
  }

  getNotifOnBA(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })

    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/count/notif', content, {
      headers: customHeaders,
    })
  }

  flowHistory(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })

    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/getflow/history', content, {
      headers: customHeaders,
    })
  }

  getFlowDescriptions(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })

    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/getflow', content, {
      headers: customHeaders,
    })
  }

  freeCommentApproval(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })

    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/free-comment', content, {
      headers: customHeaders,
    })
  }

  freeCommentAttachments(content: any) {
    return this.http.post<any>(
      this.API_SUNSHINE_SERVER + '/api/po/free-comment/attachments',
      content,
      {
        reportProgress: true,
        observe: 'events',
      },
    )
  }

  async getBase64(content: any) {
    return this.http
      .post<any>(this.API_SUNSHINE_SERVER + '/api/files/branding-approval/blob/', content)
      .toPromise()
      .then(data => {
        return data
      })
  }
  async getDataType(content: any) {
    return this.http
      .post<any>(this.API_SUNSHINE_SERVER + '/api/files/branding-approval/blob/type', content)
      .toPromise()
      .then(data => {
        return data
      })
  }

  trackingApproval(content: any) {
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/ba/tracking', content)
  }

  approvalConds(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/', content, {
      headers: customHeaders,
    })
  }

  referencePOMainChange(content: any) {
    const customHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    })
    return this.http.post<any>(this.API_SUNSHINE_SERVER + '/api/po/reference/main/add', content, {
      headers: customHeaders,
    })
  }
}
