import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzTableQueryParams, NzTableFilterList, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Driver } from 'src/app/services/interface/driver.model'
import { FilterObj, QueryTable } from 'src/app/services/interface/general.model'
import { FleetService } from 'src/app/services/fleet.service'
import { AddWarehouseComponent } from './add-warehouse/add-warehouse.component'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-warehouse-management',
  templateUrl: './warehouse-management.component.html',
})
export class WarehouseManagementComponent implements OnInit {
  listOfData: Driver[] = []
  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1

  form: FormGroup
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private fleetService: FleetService,
    private fb: FormBuilder,
    private store: Store<any>,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      filters: this.fb.array([
        this.fb.group({
          prefix: ['driverName', Validators.compose([Validators.required])],
          keyword: [null],
        }),
      ]),
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.role = state.roleCode
    })
  }

  role: string

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '100px',
    },
    {
      name: 'Warehouse ID',
      key: 'whId',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '100px',
    },
    {
      name: 'Warehouse Name',
      key: 'whName',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '120px',
    },
    {
      name: 'Warehouse Type',
      key: 'whType',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '120px',
    },
    {
      name: 'Warehouse Address',
      key: 'whAddress',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'PIC',
      key: 'whPic',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: true,
      width: '200px',
    },
    {
      name: 'Phone No.',
      key: 'phoneNumber',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: true,
      width: '200px',
    },
  ]

  ngOnInit(): void {
    this.fetchWh()
  }

  fetchWh() {
    this.isLoading = true
    this.fleetService.getWh(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count
    })
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  get advancedFilters(): FormArray {
    return this.form.get('filters') as FormArray
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchWh()
  }

  search() {
    if (this.advancedFilters.controls[0].value.keyword?.length > 0) {
      var index = this.queryTable.filters.findIndex(e => e.field)
      if (index !== -1) {
        this.queryTable.filters.splice(index, 1)
      }
      var filter: FilterObj = {
        field: this.advancedFilters.controls[0].value.prefix,
        keyword: this.advancedFilters.controls[0].value.keyword,
      }
      this.queryTable.filters.push(filter)
    } else {
      var index = this.queryTable.filters.findIndex(e => e.field)
      if (index !== -1) {
        this.queryTable.filters.splice(index, 1)
      }
    }
    this.fetchWh()
  }

  addWh() {
    const modal = this.modal.create({
      nzTitle: 'Add New Warehouse',
      nzContent: AddWarehouseComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Create',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve
          } else {
            reject
          }
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchWh()
    })
  }

  deleteWh(whId: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Are you sure to delete this Warehouse?',
      nzContent: '<b style="color: red;">This action cannot be undo</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.fleetService.deleteWh(whId).subscribe(
          r => {
            this.notification.success('Success', r.message)
            this.fetchWh()
          },
          err => {
            this.notification.error('Error', err.error.message)
            this.fetchWh()
          },
        )
      },
      nzCancelText: 'No',
      // nzOnCancel: () => console.log('Cancel'),
    })
  }
}
