import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { CTAService } from 'src/app/services/cta.service'

@Component({
  selector: 'app-view-agent-inquiry',
  templateUrl: './view-agent-inquiry.component.html',
})
export class ViewAgentInquiryComponent implements OnInit {
  isLoading: boolean = true
  googleMapLink: string
  constructor(
    private fb: FormBuilder,
    private ctaService: CTAService,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      full_name: [null, Validators.compose([Validators.required])],
      agent_type: [null, Validators.compose([Validators.required])],
      phone: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])],
      location_type: [null, Validators.compose([Validators.required])],
      building_status: [null, Validators.compose([Validators.required])],
      full_address: [null, Validators.compose([Validators.required])],
      google_map: [null, Validators.compose([Validators.required])],
      province: [null, Validators.compose([Validators.required])],
      city: [null, Validators.compose([Validators.required])],
      sub_district: [null, Validators.compose([Validators.required])],
      urban: [null, Validators.compose([Validators.required])],
      updated_at: [null],
    })
  }

  @Input() id: string
  form: FormGroup
  loading = false
  ngOnInit(): void {
    this.fetchAgentInquiryDetails()
  }

  fetchAgentInquiryDetails() {
    this.isLoading = true
    this.ctaService.agentInquiryDetail(this.id).subscribe(
      r => {
        console.log(r)
        if (
          r.location_coordinate.google_link !== null &&
          r.location_coordinate.google_link !== ''
        ) {
          this.googleMapLink = r.location_coordinate.google_link
        } else {
          this.googleMapLink =
            'https://www.google.com.sa/maps/search/' +
            r.location_coordinate.latitude +
            ',' +
            r.location_coordinate.longitude
        }
        this.form.get('province').setValue(r.osas_location.province_name)
        this.form.get('city').setValue(r.osas_location.city_name)
        this.form.get('sub_district').setValue(r.osas_location.sub_district_name)
        this.form.get('urban').setValue(r.osas_location.urban_name)
        this.form.patchValue(r)
        for (const i in this.form.controls) {
          if (this.form.controls.hasOwnProperty(i)) {
            this.form.controls[i].markAsDirty()
            this.form.controls[i].updateValueAndValidity()
          }
        }
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  // goToLink(url: string) {
  //   window.open(url, "_blank");
  // }
}
