<ng-container *ngFor="let item of objectKeys(renderedFlyoutItems)">
  <ng-container *ngTemplateOutlet="flyoutTpl; context: {$implicit: renderedFlyoutItems[item]}"></ng-container>
</ng-container>
<div [ngClass]="{
  air__menuLeft: true,
  air__menuLeft__mobileToggled: isMobileMenuOpen,
  air__menuLeft__toggled: isMenuCollapsed,
  air__menuLeft__unfixed: isMenuUnfixed,
  air__menuLeft__shadow: isMenuShadow,
  air__menuLeft__flyout: flyoutMenuType === 'flyout',
  air__menuLeft__compact: flyoutMenuType === 'compact',
  air__menuLeft__blue: menuColor === 'blue',
  air__menuLeft__white: menuColor === 'white',
  air__menuLeft__gray: menuColor === 'gray',
  air__menuFlyout__black: flyoutMenuColor === 'dark' && flyoutMenuType !== 'default',
  air__menuFlyout__white: flyoutMenuColor === 'white' && flyoutMenuType !== 'default',
  air__menuFlyout__gray: flyoutMenuColor === 'gray' && flyoutMenuType !== 'default'
}">
  <div class="air__menuLeft__outer">
    <a href="javascript: void(0);" class="air__menuLeft__mobileToggleButton" (click)="toggleMobileMenu()">
      <span></span>
    </a>
    <a href="javascript: void(0);" class="air__menuLeft__toggleButton" (click)="toggleMenu()">
      <span></span>
      <span></span>
    </a>
    <a href="javascript: void(0);" class="air__menuLeft__logo">
      <div class="air__menuLeft__logo__letter">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1" height="24px" width="24px">
          <g>
            <path fill="#4b7cf3" strokeWidth="1" stroke="#4b7cf3"
              d="M12,10.9c-0.1,0-0.2,0-0.2-0.1L3.5,6.1C3.4,6,3.3,5.8,3.3,5.6c0-0.2,0.1-0.3,0.2-0.4l8.2-4.7c0.2-0.1,0.3-0.1,0.5,0      l8.2,4.7c0.2,0.1,0.2,0.3,0.2,0.4S20.6,6,20.5,6.1l-8.2,4.7C12.2,10.8,12.1,10.9,12,10.9z M4.8,5.6L12,9.8l7.2-4.2L12,1.5      L4.8,5.6z" />
            <path fill="#4b7cf3" strokeWidth="1" stroke="#4b7cf3"
              d="M13.6,23.6c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.2-0.3-0.2-0.4v-9.5c0-0.2,0.1-0.3,0.2-0.4l8.2-4.7c0.2-0.1,0.3-0.1,0.5,0      c0.2,0.1,0.2,0.3,0.2,0.4v9.5c0,0.2-0.1,0.3-0.3,0.4l-8.2,4.7C13.8,23.6,13.7,23.6,13.6,23.6z M14.1,13.9v8.3l7.2-4.2V9.8      L14.1,13.9z" />
            <path fill="#4b7cf3" strokeWidth="1" stroke="#4b7cf3"
              d="M10.4,23.6c-0.1,0-0.2,0-0.2-0.1l-8.2-4.7c-0.2-0.1-0.3-0.3-0.3-0.4V8.9c0-0.2,0.1-0.3,0.2-0.4c0.2-0.1,0.3-0.1,0.5,0      l8.2,4.7c0.2,0.1,0.2,0.3,0.2,0.4v9.5c0,0.2-0.1,0.3-0.2,0.4C10.5,23.6,10.5,23.6,10.4,23.6z M2.7,18.1l7.2,4.2v-8.3L2.7,9.8      V18.1z" />
          </g>
        </svg>
      </div>
      <div class="air__menuLeft__logo__name">{{ logo }}</div>
      <div class="air__menuLeft__logo__descr">{{ version }}</div>
    </a>
    <a href="javascript: void(0);" class="air__menuLeft__user">
      <nz-avatar nzIcon="user" [nzShape]="'square'" [nzSize]="'large'" class="air__menuLeft__user__avatar"></nz-avatar>
      <div class="air__menuLeft__user__name">David Beckham</div>
      <div class="air__menuLeft__user__role">Administrator</div>
    </a>
    <perfect-scrollbar>
      <div class="air__menuLeft__container">
        <ul class="air__menuLeft__list">
          <ng-container *ngFor="let item of menuData">
            <ng-container *ngTemplateOutlet="itemTpl; context: {$implicit: item}"></ng-container>
            <ng-container *ngTemplateOutlet="submenuTpl; context: {$implicit: item}"></ng-container>
            <ng-container *ngTemplateOutlet="categoryTpl; context: {$implicit: item}"></ng-container>
          </ng-container>
        </ul>

      </div>
    </perfect-scrollbar>
  </div>
</div>
<a href="javascript: void(0);" class="air__menuLeft__backdrop" (click)="toggleMobileMenu()"></a>

<ng-template #itemTpl let-item>
  <ng-container *ngIf="!item.children && !item.category">
    <li class="air__menuLeft__item" [ngClass]="{air__menuLeft__item__active: item.key === activeItem}">
      <a [routerLink]="item.url ? item.url : 'javascript: void(0)'" class="air__menuLeft__link">
        <i *ngIf="item.icon" class="air__menuLeft__icon" [ngClass]="item.icon"></i>
        <span>{{ item.title }}</span>
        <span *ngIf="item.count" class="badge text-white bg-blue-light float-right mt-1 px-2">{{ item.count }}</span>
      </a>
    </li>
  </ng-container>
</ng-template>
<ng-template #submenuTpl let-item>
  <ng-container *ngIf="item.children">
    <li class="air__menuLeft__item air__menuLeft__submenu"
      [ngClass]="{air__menuLeft__submenu__active: item.key === activeSubmenu}">
      <a href="javascript: void(0);" class="air__menuLeft__link" (click)="handleSubmenuClick(item.key)"
        (mouseenter)="handleFlyoutOver($event, item.key, item.children)" (mouseleave)="handleFlyoutOut(item.key)">
        <i *ngIf="item.icon" class="air__menuLeft__icon" [ngClass]="item.icon"></i>
        <span>{{ item.title }}</span>
        <span *ngIf="item.count" class="badge text-white bg-blue-light float-right mt-1 px-2">{{ item.count }}</span>
      </a>
      <ul class="air__menuLeft__list">
        <ng-container *ngFor="let item of item.children">
          <ng-container *ngTemplateOutlet="itemTpl; context: {$implicit: item}"></ng-container>
        </ng-container>
      </ul>
    </li>
  </ng-container>
</ng-template>
<ng-template #categoryTpl let-item>
  <ng-container *ngIf="item.category">
    <li class="air__menuLeft__category">
      <span>{{ item.title }}</span>
      <span *ngIf="item.count" class="badge text-white bg-blue-light float-right mt-1 px-2">{{ item.count }}</span>
    </li>
  </ng-container>
</ng-template>
<ng-template #flyoutTpl let-item>
  <div @flyoutAnimation [ngStyle]="{
      left: item.itemDimensions.left + item.itemDimensions.width - 10 + 'px',
      top: item.itemDimensions.top + 'px'
    }" class="air__menuFlyout" [ngClass]="{
    air__menuFlyoutLeft: menuLayoutType === 'left',
    air__menuFlyout__black: flyoutMenuColor === 'dark',
    air__menuFlyout__white: flyoutMenuColor === 'white',
    air__menuFlyout__gray: flyoutMenuColor === 'gray'
  }">
    <ul class="air__menuLeft__list" (mouseenter)="handleFlyoutContainerOver(item.key)"
      (mouseleave)="handleFlyoutOut(item.key)">
      <ng-container *ngFor="let item of item.items">
        <ng-container *ngTemplateOutlet="itemTpl; context: {$implicit: item}"></ng-container>
      </ng-container>
    </ul>
  </div>
</ng-template>
