import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { ApiService } from '../api.service'
import { getMenuData } from './config'

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private apiService:ApiService) {}
  public MenuData:any[] = []
  getMenuData() {
    // return this.apiService.accessMenu().toPromise()
    return of(this.apiService.MenuData)
  }


}
