import { Component, Input, OnInit, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core'
import { AssetManagementService } from 'src/app/services/asset-management.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Config, ConfigValue, vendorDetail } from 'src/app/models/asset-config.model'
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal'
import { CapitalizeWord } from './../../../../shared/pipes/capitalize-word.pipe'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
@Component({
  selector: 'app-view-configuration',
  templateUrl: './view-configuration.component.html',
  styleUrls: ['./view-configuration.component.scss'],
})
export class ViewConfigurationComponent implements OnInit {
  @Input() company: string
  @Input() config: string

  @ViewChild('toasterConfig', { static: true }) toasterConfigTemplate: TemplateRef<{}>
  confirmModal?: NzModalRef

  selectedConfig: string
  selectedCompany: string

  getConfig: Config[] = []
  getConfigValue: ConfigValue[] = []
  configData: string[] = []
  isLoading: boolean = true
  pageSize: number = 10
  pageIndex: number = 1

  filterForm: FormGroup
  addForm: FormGroup
  editForm: FormGroup
  vendorForm: FormGroup

  isDownloading: boolean = false
  isOkLoading = false

  isAddConfigValueVisible: boolean = false
  isAddConfigValueConfirm: boolean = false

  isEditConfigValueVisible: boolean = false
  isEditConfigValueConfirm: boolean = false

  selectedConfigValue: string = ''
  displayedConfigValue: string = ''
  displayedCode: string = ''

  vendorDetail: vendorDetail

  messageConfigValue: string
  iconConfigValue: string
  colorConfigValue: string

  importExcelFile: NzUploadFile[] = []

  emptyData: ConfigValue[] = []

  constructor(
    private assetManagementService: AssetManagementService,
    private fb: FormBuilder,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private notification: NzNotificationService,
  ) {
    this.filterForm = this.fb.group({
      config: [this.selectedConfig, Validators.compose([Validators.required])],
      search: ['', Validators.compose([Validators.required])],
    })

    this.addForm = this.fb.group({
      new_config_value: ['', Validators.compose([Validators.required])],
      code: ['-', Validators.compose([Validators.required])],
    })

    this.editForm = this.fb.group({
      new_config_value: ['', Validators.compose([Validators.required])],
      code: ['-', Validators.compose([Validators.required])],
    })

    this.vendorForm = this.fb.group({
      lastCompanyName: [''],
      companyName: ['', Validators.compose([Validators.required])],
      PICName: ['', Validators.compose([Validators.required])],
      address: ['', Validators.compose([Validators.required])],
      phoneNumber: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      industry: ['', Validators.compose([Validators.required])],
    })
  }

  listOfColumn = [
    {
      title: 'Actions',
      key: 'actions',
      left: false,
      right: false,
      width: '100px',
    },
    {
      title: 'Value',
      key: 'value',
      left: false,
      right: false,
      width: '130px',
      compare: (a: ConfigValue, b: ConfigValue) => a.configuration.localeCompare(b.value),
      priority: false,
    },
    {
      title: 'Created By',
      key: 'created_by',
      left: false,
      right: false,
      width: '200px',
      compare: (a: ConfigValue, b: ConfigValue) => a.created_by.localeCompare(b.created_by),
      priority: 4,
    },
    {
      title: 'Updated By',
      key: 'updated_by',
      left: false,
      right: false,
      width: '200px',
      compare: (a: ConfigValue, b: ConfigValue) => a.updated_by.localeCompare(b.updated_by),
      priority: 3,
    },
    {
      title: 'Created At',
      key: 'created_at',
      left: false,
      right: false,
      width: '200px',
      compare: (a: ConfigValue, b: ConfigValue) => a.created_at.localeCompare(b.created_at),
      priority: 2,
    },
    {
      title: 'Updated At',
      key: 'updated_at',
      left: false,
      right: false,
      width: '200px',
      compare: (a: ConfigValue, b: ConfigValue) => a.updated_at.localeCompare(b.updated_at),
      priority: 1,
    },
  ]

  ngOnInit(): void {
    //console.log(this.company, 'company data')
    //console.log(this.config, "config data")
    this.configData = []
    this.selectedCompany = this.company
    this.selectedConfig = this.config
    this.filterForm.get('config').setValue(this.selectedConfig)
    this.fetchConfig(this.company)
    this.fetchConfigValue(this.selectedCompany, this.selectedConfig)
  }

  add() {
    this.isAddConfigValueVisible = true
  }

  addCompany() {
    this.isAddConfigValueConfirm = true
    setTimeout(() => {
      this.isAddConfigValueVisible = false
      this.isAddConfigValueConfirm = false

      const capitalizeWordPipe = new CapitalizeWord()

      const payload = {
        value: this.addForm.value.new_config_value,
        code: this.addForm.value.code.toUpperCase(),
      }

      this.assetManagementService.addCompany(payload).subscribe(
        r => {
          this.messageConfigValue = r.message
          this.iconConfigValue = 'check-circle'
          this.colorConfigValue = '#52c41a'
          this.createBasicNotification(this.toasterConfigTemplate)
          this.fetchConfigValue(this.selectedCompany, this.selectedConfig)
        },
        error => {
          this.messageConfigValue = error.error.message
          this.iconConfigValue = 'warning'
          this.colorConfigValue = '#eb2f96'
          this.createBasicNotification(this.toasterConfigTemplate)
        },
      )
    }, 500)
  }

  addConfigValue() {
    this.isAddConfigValueConfirm = true
    setTimeout(() => {
      this.isAddConfigValueVisible = false
      this.isAddConfigValueConfirm = false

      const capitalizeWordPipe = new CapitalizeWord()

      if (this.selectedConfig === 'Vendor') {
        const payload = {
          company: this.selectedCompany,
          companyName: this.vendorForm.value.companyName,
          address: this.vendorForm.value.address,
          industry: capitalizeWordPipe.transform(this.vendorForm.value.industry),
          phoneNumber: this.vendorForm.value.phoneNumber,
          PICName: capitalizeWordPipe.transform(this.vendorForm.value.PICName),
          email: this.vendorForm.value.email,
        }
        // console.log(payload)

        this.assetManagementService.addVendorDetail(payload).subscribe(
          r => {
            this.messageConfigValue = r.message
            this.iconConfigValue = 'check-circle'
            this.colorConfigValue = '#52c41a'
            this.createBasicNotification(this.toasterConfigTemplate)
            this.fetchConfigValue(this.selectedCompany, this.selectedConfig)
          },
          error => {
            this.messageConfigValue = error.error
            this.iconConfigValue = 'warning'
            this.colorConfigValue = '#eb2f96'
            this.createBasicNotification(this.toasterConfigTemplate)
          },
        )
      } else {
        const payload = {
          company: this.selectedCompany,
          config: this.selectedConfig,
          value: this.addForm.value.new_config_value,
          code: this.addForm.value.code.toUpperCase() || '-',
        }

        this.assetManagementService.addConfigValue(payload).subscribe(
          r => {
            this.messageConfigValue = r.message
            this.iconConfigValue = 'check-circle'
            this.colorConfigValue = '#52c41a'
            this.createBasicNotification(this.toasterConfigTemplate)
            this.fetchConfigValue(this.selectedCompany, this.selectedConfig)
          },
          error => {
            this.messageConfigValue = error.error.message
            this.iconConfigValue = 'warning'
            this.colorConfigValue = '#eb2f96'
            this.createBasicNotification(this.toasterConfigTemplate)
          },
        )
      }
    }, 500)
  }

  delete(value: string) {
    if (this.selectedConfig === 'Company') {
      this.deleteCompany(value)
    } else {
      this.deleteConfigValue(value)
    }
  }

  deleteCompany(company: string) {
    this.confirmModal = this.modal.confirm({
      nzWidth: '500px',
      nzTitle: `Do you want to delete "${company}" from company?`,
      nzContent:
        'When clicked the OK button, everything associate with this value will be deleted also!',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 500)
        }).then(() => {
          setTimeout(() => {
            this.assetManagementService.deleteCompany(company).subscribe(
              r => {
                this.messageConfigValue = r.message
                this.iconConfigValue = 'check-circle'
                this.colorConfigValue = '#52c41a'
                this.createBasicNotification(this.toasterConfigTemplate)
                this.fetchConfigValue(this.selectedCompany, this.selectedConfig)
              },
              error => {
                this.messageConfigValue = error.error.message
                this.iconConfigValue = 'warning'
                this.colorConfigValue = '#eb2f96'
                this.createBasicNotification(this.toasterConfigTemplate)
              },
            )
          }, 500)
        }),
    })
  }

  deleteConfigValue(configValue: string) {
    this.confirmModal = this.modal.confirm({
      nzWidth: '500px',
      nzTitle: `Do you want to delete "${configValue}" value?`,
      nzContent: 'When clicked the OK button, this value will be deleted in the database!',
      nzOnOk: () =>
        setTimeout(() => {
          if (this.selectedConfig === 'Vendor') {
            this.assetManagementService.deleteVendor(this.selectedCompany, configValue).subscribe(
              r => {
                this.messageConfigValue = r.message
                this.iconConfigValue = 'check-circle'
                this.colorConfigValue = '#52c41a'
                this.createBasicNotification(this.toasterConfigTemplate)
                this.fetchConfigValue(this.selectedCompany, this.selectedConfig)
              },
              error => {
                this.messageConfigValue = error.error
                this.iconConfigValue = 'warning'
                this.colorConfigValue = '#eb2f96'
                this.createBasicNotification(this.toasterConfigTemplate)
              },
            )
          } else {
            this.assetManagementService
              .deleteConfigValue(this.selectedCompany, this.selectedConfig, configValue)
              .subscribe(
                r => {
                  this.messageConfigValue = r.message
                  this.iconConfigValue = 'check-circle'
                  this.colorConfigValue = '#52c41a'
                  this.createBasicNotification(this.toasterConfigTemplate)
                  this.fetchConfigValue(this.selectedCompany, this.selectedConfig)
                },
                error => {
                  this.messageConfigValue = error.error.message
                  this.iconConfigValue = 'warning'
                  this.colorConfigValue = '#eb2f96'
                  this.createBasicNotification(this.toasterConfigTemplate)
                },
              )
          }
        }, 500),
    })
  }

  edit(configValue: string, code: string) {
    // console.log(configValue)
    if (this.selectedConfig === 'Vendor') {
      this.assetManagementService.getVendorDetail(this.selectedCompany, configValue).subscribe(
        (r: vendorDetail) => {
          this.isEditConfigValueVisible = true
          this.vendorForm.get('lastCompanyName').setValue(r.company_name)
          this.vendorForm.get('companyName').setValue(r.company_name)
          this.vendorForm.get('PICName').setValue(r.pic_name)
          this.vendorForm.get('address').setValue(r.address)
          this.vendorForm.get('phoneNumber').setValue(r.phone_number)
          this.vendorForm.get('email').setValue(r.email)
          this.vendorForm.get('industry').setValue(r.industry)
        },
        error => console.error(error.error),
      )
    } else {
      this.isEditConfigValueVisible = true
      this.displayedConfigValue = configValue
      this.selectedConfigValue = configValue
      this.displayedCode = code
    }
  }

  fetchConfig(company: string) {
    this.isLoading = true
    this.assetManagementService.getConfig(company).subscribe((r: any) => {
      this.getConfig = r
      for (let item of r) {
        this.configData.push(item.value)
      }
      this.isLoading = false
      // console.log(this.getConfig)
    })
  }

  fetchConfigValue(company: string, config: string) {
    this.isLoading = true
    this.assetManagementService.getConfigValue(company, config).subscribe((r: any) => {
      this.getConfigValue = r
      // console.log(r)
      if (this.filterForm.value.search.length > 0) {
        const filteredData = []
        for (let item of this.getConfigValue) {
          if (item.value.toLocaleLowerCase().includes(this.filterForm.value.search)) {
            filteredData.push(item)
          }
        }
        this.getConfigValue = filteredData
      }
      this.isLoading = false
    })
  }

  apply() {
    this.selectedConfig = this.filterForm.value.config
    this.fetchConfigValue(this.selectedCompany, this.selectedConfig)
  }

  handleAddOk(): void {
    if (this.selectedConfig === 'Company') {
      this.addCompany()
    } else {
      this.addConfigValue()
    }
  }

  handleEditOk(): void {
    if (this.selectedConfig === 'Company') {
      this.editCompany()
    } else {
      this.editConfigValue()
    }
  }

  editCompany(): void {
    this.isEditConfigValueConfirm = true

    setTimeout(() => {
      this.isEditConfigValueVisible = false
      this.isEditConfigValueConfirm = false

      const capitalizeWordPipe = new CapitalizeWord()

      const payload = {
        company: this.selectedCompany,
        config: this.selectedConfig,
        last_value: this.selectedConfigValue,
        new_value: this.editForm.value.new_config_value,
        code: this.editForm.value.code.toUpperCase(),
      }

      this.assetManagementService.updateCompany(payload).subscribe(
        r => {
          this.messageConfigValue = r.message
          this.iconConfigValue = 'check-circle'
          this.colorConfigValue = '#52c41a'
          this.createBasicNotification(this.toasterConfigTemplate)
          this.fetchConfig(this.selectedCompany)
          this.fetchConfigValue(this.selectedCompany, this.selectedConfig)
        },
        error => {
          this.messageConfigValue = error.error.message
          this.iconConfigValue = 'warning'
          this.colorConfigValue = '#eb2f96'
          this.createBasicNotification(this.toasterConfigTemplate)
        },
      )
    }, 500)
  }

  editConfigValue(): void {
    this.isEditConfigValueConfirm = true

    setTimeout(() => {
      this.isEditConfigValueConfirm = false

      const capitalizeWordPipe = new CapitalizeWord()
      if (this.selectedConfig === 'Vendor') {
        if (!this.vendorForm.valid) {
          this.messageConfigValue = 'Please fill all entry'
          this.iconConfigValue = 'warning'
          this.colorConfigValue = '#eb2f96'
          this.createBasicNotification(this.toasterConfigTemplate)
          return
        }
        this.isEditConfigValueVisible = false
        const payload = {
          lastCompanyName: this.vendorForm.value.lastCompanyName,
          company: this.selectedCompany,
          companyName: this.vendorForm.value.companyName,
          address: this.vendorForm.value.address,
          industry: this.vendorForm.value.industry,
          phoneNumber: this.vendorForm.value.phoneNumber,
          PICName: capitalizeWordPipe.transform(this.vendorForm.value.PICName),
          email: this.vendorForm.value.email,
        }

        this.assetManagementService.updateVendorDetail(payload).subscribe(
          r => {
            this.messageConfigValue = r.message
            this.iconConfigValue = 'check-circle'
            this.colorConfigValue = '#52c41a'
            this.createBasicNotification(this.toasterConfigTemplate)
            this.fetchConfig(this.selectedCompany)
            this.fetchConfigValue(this.selectedCompany, this.selectedConfig)
          },
          error => {
            this.messageConfigValue = error.error.message
            this.iconConfigValue = 'warning'
            this.colorConfigValue = '#eb2f96'
            this.createBasicNotification(this.toasterConfigTemplate)
          },
        )
      } else {
        const payload = {
          company: this.selectedCompany,
          config: this.selectedConfig,
          last_value: this.selectedConfigValue,
          new_value: this.editForm.value.new_config_value,
          code: this.editForm.value.code.toUpperCase(),
        }

        this.assetManagementService.updateConfigValue(payload).subscribe(
          r => {
            this.messageConfigValue = r.message
            this.iconConfigValue = 'check-circle'
            this.colorConfigValue = '#52c41a'
            this.isEditConfigValueVisible = false
            this.createBasicNotification(this.toasterConfigTemplate)
            this.fetchConfig(this.selectedCompany)
            this.fetchConfigValue(this.selectedCompany, this.selectedConfig)
          },
          error => {
            this.messageConfigValue = error.error.message
            this.iconConfigValue = 'warning'
            this.colorConfigValue = '#eb2f96'
            this.createBasicNotification(this.toasterConfigTemplate)
          },
        )
      }
    }, 500)
  }

  handleAddCancel(): void {
    this.isAddConfigValueVisible = false
  }

  handleEditCancel(): void {
    this.isEditConfigValueVisible = false
  }

  handleChangeImport(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.isLoading = true
        break
      case 'done':
        console.log(info.file.response, 'import')
        this.messageConfigValue = info.file.response.message
        this.iconConfigValue = 'check-circle'
        this.colorConfigValue = '#52c41a'
        this.createBasicNotification(this.toasterConfigTemplate)
        this.fetchConfigValue(this.selectedCompany, this.selectedConfig)
        break
      case 'error':
        this.isLoading = false
        break
    }
  }

  customImport = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    formData.append('file', item.file as any)
    formData.append('company', this.selectedCompany)
    this.assetManagementService.importVendor(formData).subscribe(
      r => {
        item.onSuccess(r, item.file, r)
        this.importExcelFile = []
      },
      error => {
        item.onError(error, item.file)
        console.log(error.error.message)
        this.messageConfigValue = error.error.message
        this.iconConfigValue = 'warning'
        this.colorConfigValue = '#eb2f96'
        this.createBasicNotification(this.toasterConfigTemplate)
        this.importExcelFile = []
      },
    )
  }

  exportConfig(): void {
    this.isDownloading = true
    var blankPage = window.open('http://example.com/waiting.html', '_blank')
    blankPage!.document.write('Loading preview...')

    this.assetManagementService.exportVendor(this.selectedCompany).subscribe(
      r => {
        console.log(r.link)
        this.isDownloading = false
        blankPage!.location.href = r.link
        this.messageConfigValue = r.message
        this.iconConfigValue = 'check-circle'
        this.colorConfigValue = '#52c41a'
        this.createBasicNotification(this.toasterConfigTemplate)
      },
      err => {
        blankPage.close()
        this.isDownloading = false
        this.messageConfigValue = err.error.message
        this.iconConfigValue = 'warning'
        this.colorConfigValue = '#eb2f96'
        this.createBasicNotification(this.toasterConfigTemplate)
      },
    )
  }

  formatNumber(event: any, formControl) {
    const inputValue = event.target.value
    const number = inputValue.replace(/[^\d.+]/g, '')
    this.vendorForm.get(formControl).setValue(number)
  }

  createBasicNotification(template: TemplateRef<{}>): void {
    this.notification.template(template, {
      nzPlacement: 'bottomRight',
    })
  }
}
