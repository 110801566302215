import { Injectable } from '@angular/core'
import { ApiService } from '../api.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import store from 'store'
import { updateProfile } from 'src/app/models/freelance-marketing.models'
@Injectable({
  providedIn: 'root',
})
export class FreelanceMarketingService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  private accessToken = store.get('accessToken')

  fetchUser(progress: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.accessToken,
    })
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/freelance/list?progress=' + progress,
      { headers: headers },
    )
  }

  fetchUserInfo(id: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.accessToken,
    })

    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/freelance/detail?id=' + id, {
      headers: headers,
    })
  }

  updateInfo(agent_id: string, payload: updateProfile): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.accessToken,
    })

    return this.http.put(
      this.apiService.API_SUNSHINE_SERVER + '/api/freelance/detail/' + agent_id,
      payload,
      { headers: headers },
    )
  }

  changeStatus(id: string, status: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.accessToken,
    })

    return this.http.put(
      this.apiService.API_SUNSHINE_SERVER + '/api/freelance/status',
      { id: id, status: status },
      { headers },
    )
  }

  accept(id: string, reason: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.accessToken,
    })

    return this.http.put(
      this.apiService.API_SUNSHINE_SERVER + '/api/freelance/verify/' + id,
      { reason: reason },
      { headers: headers },
    )
  }

  reject(id: string, reason: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.accessToken,
    })

    return this.http.put(
      this.apiService.API_SUNSHINE_SERVER + '/api/freelance/reject/' + id,
      { reason: reason },
      { headers: headers },
    )
  }

  sendPKS(id: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.accessToken,
    })
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/freelance/send-pks/' + id, {
      headers: headers,
    })
  }

  getFile(filename: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.accessToken,
    })

    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/freelance/document/' + filename,
      {
        headers: headers,
        responseType: 'blob' as 'json',
      },
    )
  }
}
