<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table #fixedTable 
        nzShowSizeChanger 
        class="table-MeetingRooming" 
        [nzData]="listOfData"
        [nzFrontPagination]="false" 
        [nzLoading]="isLoading" 
        [nzTotal]="totalData" 
        [nzPageSize]="pageSize"
        [nzPageIndex]="pageIndex" 
        [nzPageSizeOptions]="[5, 10, 20, 50]" 
        (nzQueryParams)="onQueryParamsChange($event)"
        [nzScroll]="{ y: '42vh' }"
        [nzTitle]="headerTable" nzSize="small">

          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <th *ngIf="column.sortFn" [nzLeft]="column.left" [nzRight]="column.right" [nzSortFn]="column.sortFn"
                  [(nzSortOrder)]="column.sortOrder" [nzColumnKey]="column.key" [nzWidth]="column.width">
                  {{ column.name }}
                </th>
                <th *ngIf="!column.sortFn" [nzLeft]="column.left" [nzRight]="column.right" [nzColumnKey]="column.key"
                  [nzWidth]="column.width">
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of fixedTable.data">
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <td *ngIf="column.key == 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Room">
                    <a class="btn btn-sm btn-light mr-2" (click)="viewMeetingRoom(data.id)">
                      <i class="fe fe-eye"></i>
                    </a>
                  </span>
                  <span style="margin: 0 2px;"></span>
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Delete Room">
                    <a class="btn btn-sm btn-light mr-2" (click)="deleteRoom(data.id)">
                      <i class="fe fe-trash-2"></i>
                    </a>
                  </span>
                </td>
                <td *ngIf="column.key == 'status'" [nzLeft]="column.left" [nzRight]="column.right">
                  {{ (data['status'])? 'ENABLED': 'DISABLED' }}
                </td>
                <td *ngIf="(column.key != 'actions') && (column.key != 'status')" [nzLeft]="column.left"
                  [nzRight]="column.right">
                  {{ data[column.key] }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<ng-template #headerTable>
  <div class="row justify-content-between">
    <div class="col">
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Add Room">
        <a class="btn btn-lg btn-light mr-2" (click)="addRoom()">
          <i class="fe fe-plus-circle"></i>
        </a>
      </span>
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Refresh">
        <a class="btn btn-lg btn-light mr-2" (click)="reset()">
          <i class="fe fe-refresh-cw"></i>
        </a>
      </span>
    </div>

    <div class="col">
      <form nz-form [nzLayout]="'inline'" [formGroup]="form" (ngSubmit)="search()" class="float-right">
        <nz-form-item>
          <nz-form-label nzFor="prefix" class="text-left">Prefix</nz-form-label>
          <nz-form-control>
            <nz-select id="prefix" [formControl]="form.controls['prefix']" nzPlaceHolder="Select Prefix ..."
              style="width: 150px;" (ngModelChange)="changePrefix($event)">
              <nz-option nzValue="name" nzLabel="Name"></nz-option>
              <nz-option nzValue="capacity" nzLabel="Capacity"></nz-option>
              <nz-option nzValue="status" nzLabel="Status"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <div *ngIf="(form.controls['prefix'].value == 'status') || (form.controls['prefix'].value == 'function')">
            <nz-form-control>
              <nz-select nzPlaceHolder="Keyword ..." formControlName="keyword">
                <ng-container *ngIf="form.controls['prefix'].value == 'status'">
                  <nz-option [nzValue]="true" nzLabel="ENABLED"></nz-option>
                  <nz-option nzValue="false" nzLabel="DISABLED"></nz-option>
                </ng-container>
              </nz-select>
            </nz-form-control>
          </div>
          <div *ngIf="(form.controls['prefix'].value != 'status') && (form.controls['prefix'].value != 'function')">
            <nz-form-control>
              <input [formControl]="form.controls['keyword']" nz-input placeholder="Keyword ..."
                style="width: 100px;" />
            </nz-form-control>
          </div>
        </nz-form-item>
        <nz-form-item>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
              Search
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</ng-template>