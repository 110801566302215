<form nz-form [formGroup]="form">
  <div class="row" style="margin-bottom: 15px;">
    <div class="col-lg-3 col-md-6"></div>
          <div class="row">
            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="whId" class="text-left">Warehouse ID</nz-form-label>
                <nz-form-control nzErrorTip="Please input Warehouse ID correctly!">
                  <input
                    nz-input
                    id="'whId'"
                    formControlName="whId"
                    placeholder="Input Warehouse ID ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="whName" class="text-left">Warehouse Name</nz-form-label>
                <nz-form-control nzErrorTip="Please input a valid Warehouse Name">
                  <input
                    nz-input
                    id="whName"
                    formControlName="whName"
                    placeholder="Input Warehouse Name ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="whType" class="text-left">Warehouse Type</nz-form-label>
                <nz-form-control nzErrorTip="Please input a valid Warehouse Type">
                  <input
                    nz-input
                    id="whType"
                    formControlName="whType"
                    placeholder="Input Warehouse Type ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="whAddress" class="text-left">Warehouse Address</nz-form-label>
                <nz-form-control nzErrorTip="Please input a valid Warehouse Address">
                  <input
                    nz-input
                    id="whAddress"
                    formControlName="whAddress"
                    placeholder="Input Warehouse Address ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="whPic" class="text-left">Warehouse PIC</nz-form-label>
                <nz-form-control nzErrorTip="Please input a valid Warehouse PIC">
                  <input
                    nz-input
                    id="whPic"
                    formControlName="whPic"
                    placeholder="Input Warehouse PIC ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="phoneNumber" class="text-left">Phone Number</nz-form-label>
                <nz-form-control nzErrorTip="Please input a valid Phone Number">
                  <input
                    nz-input
                    type="number"
                    id="phoneNumber"
                    formControlName="phoneNumber"
                    placeholder="Input Phone Number ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
  </div>
</form>
