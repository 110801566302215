import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { CTAService } from 'src/app/services/cta.service'

@Component({
  selector: 'app-view-inquiry',
  templateUrl: './view-inquiry.component.html',
})
export class ViewInquiryComponent implements OnInit {
  isLoading: boolean = true
  businessLink: string
  constructor(
    private fb: FormBuilder,
    private ctaService: CTAService,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      full_name: [null, Validators.compose([Validators.required])],
      phone: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])],
      business_name: [null, Validators.compose([Validators.required])],
      product_sold: [null, Validators.compose([Validators.required])],
      shipment_quantity: [null, Validators.compose([Validators.required])],
      business_link: [null, Validators.compose([Validators.required])],
      updated_at: [null],
    })
  }

  @Input() id: string
  form: FormGroup
  loading = false
  ngOnInit(): void {
    this.fetchBusinessInquiryDetails()
  }

  fetchBusinessInquiryDetails() {
    this.isLoading = true
    this.ctaService.businessInquiryDetail(this.id).subscribe(
      r => {
        if (!r.business_link.startsWith('https://')) {
          this.businessLink = 'https://' + r.business_link
        } else {
          this.businessLink = r.business_link
        }
        this.form.patchValue(r)
        for (const i in this.form.controls) {
          if (this.form.controls.hasOwnProperty(i)) {
            this.form.controls[i].markAsDirty()
            this.form.controls[i].updateValueAndValidity()
          }
        }
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }
}
