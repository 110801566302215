<nz-tabset>
  <nz-tab [nzTitle]="titleTemplate">
    <ng-template #titleTemplate>
      <span nz-icon nzType="plus-circle" nzTheme="twotone" class="m-0"></span>
      <span> {{ 'Create' }}</span>
    </ng-template>
    <app-create-shift-scheduler></app-create-shift-scheduler>
  </nz-tab>
  <nz-tab [nzTitle]="titleTemplate2">
    <ng-template #titleTemplate2>
      <span nz-icon nzType="ordered-list" nzTheme="outline" class="m-0"></span>
      <span> {{ 'List' }}</span>
    </ng-template>
    <app-list-shift-scheduler></app-list-shift-scheduler>
  </nz-tab>
</nz-tabset>
<router-outlet></router-outlet>
