import { Component, OnInit } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'

@Component({
  selector: 'vb-widgets-general-25',
  templateUrl: './25.component.html',
  styleUrls: ['./25.component.scss'],
})
export class VbGeneral25Component implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    // Fix for guard encoding URL with query params. Decoding the URL and redirecting to it if user lands on error page.
    const url = decodeURIComponent(this.router.url)
    // console.log(url, 'url')
    if (url !== this.router.url) {
      this.router.navigateByUrl(url)
    }
  }
}
