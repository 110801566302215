<nz-spin [nzSpinning]="isLoading">
    <form nz-form [formGroup]="form">
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-12">
                <div class="row">
                    <ng-template [ngIf]="type === 'userstate'">
                        <div class="col-6">
                            <nz-form-item>
                                <nz-form-label nzFor="first_name" class="text-left">First Name</nz-form-label>
                                <nz-form-control nzErrorTip="Please input name correctly!">
                                    <input nz-input id="'first_name'" formControlName="first_name" readonly
                                        placeholder="Input agent's first name.." />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
    
                        <div class="col-6">
                            <nz-form-item>
                                <nz-form-label nzFor="last_name" class="text-left">Last Name</nz-form-label>
                                <nz-form-control nzErrorTip="Please input name correctly!">
                                    <input nz-input id="'last_name'" formControlName="last_name" readonly
                                        placeholder="Input agent's last name.." />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </ng-template>

                    <ng-template [ngIf]="type === 'osas'">
                        <div class="col-6">
                            <nz-form-item>
                                <nz-form-label nzFor="first_name" class="text-left">Full Name</nz-form-label>
                                <nz-form-control nzErrorTip="Please input name correctly!">
                                    <input nz-input id="'first_name'" formControlName="first_name" readonly
                                        placeholder="Input agent's full name.." />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </ng-template>

                    <div class="col-6">
                        <nz-form-item>
                            <nz-form-label nzFor="username" class="text-left">Username</nz-form-label>
                            <nz-form-control nzErrorTip="Please input username correctly!">
                                <input nz-input id="'username'" formControlName="username" readonly
                                    placeholder="Input agent's username.." />
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div class="col-6">
                        <nz-form-item>
                            <nz-form-label nzFor="password" class="text-left">Password</nz-form-label>
                            <nz-form-control nzErrorTip="Please input password correctly! Password should be atleast 8 characters!">
                                <input nz-input id="'password'" formControlName="password"
                                placeholder="***********" readonly />
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div class="col-6">
                        <nz-form-item>
                            <nz-form-label nzFor="role">Agent Role</nz-form-label>
                            <nz-form-control nzErrorTip="Please select role!">
                                <nz-select formControlName="role" nzPlaceHolder="Choose Role" [nzDisabled]>
                                    <nz-option *ngFor="let item of roleArr" [nzLabel]="item.title" [nzValue]="item.id">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div class="col-6">
                        <nz-form-item>
                            <nz-form-label nzFor="email" class="text-left">Email</nz-form-label>
                            <nz-form-control nzErrorTip="Please input email correctly!">
                                <input nz-input id="'email'" formControlName="email" readonly
                                    placeholder="Input email.." />
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div class="col-6">
                        <nz-form-item>
                            <nz-form-label nzFor="phone" class="text-left">Phone No.</nz-form-label>
                            <nz-form-control nzErrorTip="Please input number correctly!">
                                <input nz-input id="'phone'" formControlName="phone" readonly
                                    placeholder="Input agent's phone.." />
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div class="col-6">
                        <nz-form-item>
                            <nz-form-label nzFor="location_agent" class="text-left">Location Agent</nz-form-label>
                            <nz-form-control nzErrorTip="Please input location agent correctly!">
                                <input nz-input id="'location_agent'" formControlName="location_agent" readonly
                                    placeholder="Input location agent.." />
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div class="col-6">
                        <nz-form-label nzFor="disabled" class="text-left">Disable Account</nz-form-label>
                        <nz-switch id="'disabled'" formControlName="disabled" [(ngModel)]="switchValue"
                            [nzControl]="true" (click)="clickSwitch()"></nz-switch>
                    </div>
                </div>
            </div>
        </div>
    </form>
</nz-spin>