import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import storeeeee from 'store'
import { select, Store } from '@ngrx/store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import { TicketingService } from 'src/app/services/ticketing.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AgentForm, VendorForm } from 'src/app/services/interface/ticketing.model';

@Component({
  selector: 'app-view-vendor',
  templateUrl: './view-vendor.component.html',
})
export class ViewVendorComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private ticketingService: TicketingService,
    private apiService: ApiService,
    private store: Store<any>,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
  ) {
    this.form = this.fb.group({
      code: [null, Validators.compose([Validators.required])],
      name: [null, Validators.compose([Validators.required])],
      status: [null, Validators.compose([Validators.required])],
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.signer = state.username
    })
  }

  form:FormGroup
  signer:string
  isLoading: boolean = false

  @Input() data : any

  ngOnInit(): void {
    this.form.patchValue(this.data)
  }

  submitForm(): void {
    this.isLoading = true
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    if (this.form.valid) {
      var formSubs: VendorForm = this.form.value
      formSubs.signer = this.signer
      this.ticketingService.updateVendor(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.modal.destroy()
          this.isLoading = false
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
    }
  }

}
