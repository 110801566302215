import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'
import store from 'store'
import { ScheduleRoomForm } from './interface/schedule-room.model'
import { QueryTable } from './interface/general.model'

@Injectable({
  providedIn: 'root',
})
export class ScheduleRoomService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  createScheduleRoom(form: any): Observable<any> {
    // form.meeting_room = form.meeting_room.toUpperCase().trim()
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/schedule/add', form, params)
  }

  updateScheduleRoom(form: ScheduleRoomForm): Observable<any> {
    // form.meeting_room = form.meeting_room.toUpperCase().trim()
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/schedule/update',
      form,
      params,
    )
  }

  viewlistScheduleRoom(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    const body = {
      pageIndex: queryTable.pageIndex,
      pageSize: queryTable.pageSize,
    }
    // console.log(params)
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/schedule/viewlist',
      body,
      params,
    )
  }

  calendarStart(year: any, month: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          params: {
            year: year,
            month: month,
          },
        }
      : {}
    // const body = {
    //   year: year,
    //   month: month,
    // }
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/schedule/getCalendarStart',
      params,
    )
  }

  calendarEnd(year, month): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          params: {
            year: year,
            month: month,
          },
        }
      : {}
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/schedule/getCalendarEnd',
      params,
    )
  }

  viewdetailScheduleRoom(id: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/schedule/viewdetail', params)
  }

  deleteScheduleRoom(id: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/schedule/delete', params)
  }

  username(): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/schedule/username', params)
  }
}
