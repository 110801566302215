<form nz-form [formGroup]="form">
  <div class="row" style="margin-bottom: 15px;">
    <div class="col-lg-6 col-md-12">
      <nz-collapse>
        <nz-collapse-panel nzHeader="Main Information">
          <div class="row">
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="city" class="text-left">City</nz-form-label>
                <nz-form-control nzErrorTip="Please an existing city!">
                  <input nz-input id="'city'" formControlName="city" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="locationName" class="text-left">Location Name</nz-form-label>
                <nz-form-control nzErrorTip="Please input location name correctly!">
                  <textarea
                    rows="2"
                    id="'locationName'"
                    nz-input
                    formControlName="locationName"
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="locationSide">Location Side</nz-form-label>
                <nz-form-control nzErrorTip="Please select location Side!">
                  <nz-select
                    formControlName="locationSide"
                    nzPlaceHolder="Select location side ..."
                  >
                    <nz-option nzLabel="HO" nzValue="HO"></nz-option>
                    <nz-option nzLabel="DC" nzValue="DC"></nz-option>
                    <nz-option nzLabel="OPA" nzValue="OPA"></nz-option>
                    <nz-option nzLabel="OMA" nzValue="OMA"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="address" class="text-left">Address</nz-form-label>
                <nz-form-control nzErrorTip="Please input address correctly!">
                  <textarea rows="2" id="'address'" nz-input formControlName="address"></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div class="col-lg-6 col-md-12">
      <nz-collapse>
        <nz-collapse-panel nzHeader="Additional Information">
          <div class="row">
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="contractNumber" class="text-left">Phone Number</nz-form-label>
                <nz-form-control nzErrorTip="Please input valid phone number!">
                  <input nz-input id="'phoneNumber'" formControlName="phoneNumber" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="contractNumber" class="text-left">Map Links</nz-form-label>
                <nz-form-control nzErrorTip="Please input valid link correctly!">
                  <input nz-input id="'mapLinks'" formControlName="mapLinks" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="radiusSite" class="text-left">Distance</nz-form-label>
                <nz-form-control nzErrorTip="Please input valid distance correctly!">
                  <nz-input-group nzAddOnAfter="meter">
                    <input
                      nz-input
                      id="'radiusSite'"
                      type="number"
                      formControlName="radiusSite"
                      placeholder="Input distance ..."
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="contractNumber" class="text-left">Latitude</nz-form-label>
                <nz-form-control nzErrorTip="Please input valid Latitude">
                  <input nz-input type="number" id="'latitude'" formControlName="latitude" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="contractNumber" class="text-left">Longitude</nz-form-label>
                <nz-form-control nzErrorTip="Please input valid Longitude!">
                  <input nz-input type="number" id="'longitude'" formControlName="longitude" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
</form>
