<form nz-form [formGroup]="form">
  <div class="row" style="margin-bottom: 15px;">
    <div class="col-12">
      <nz-collapse>
        <nz-collapse-panel nzHeader="Location Information">
          <div class="row">
            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="city" class="text-left">City</nz-form-label>
                <nz-form-control nzErrorTip="Please input your city!">
                  <input nz-input id="'city'" formControlName="city" placeholder="Input City ..." />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="locationName" class="text-left">Location Name</nz-form-label>
                <nz-form-control nzErrorTip="Please input your Location Name!">
                  <input
                    nz-input
                    id="'locationName'"
                    formControlName="locationName"
                    placeholder="Input Location Name ..."
                  />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="address" class="text-left">Address</nz-form-label>
                <nz-form-control nzErrorTip="Please input your Address!">
                  <input
                    nz-input
                    id="'address'"
                    formControlName="address"
                    placeholder="Input Address ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="phone" class="text-left">
                  Phone Number
                </nz-form-label>
                <nz-form-control nzErrorTip="Please Input Phone Number!">
                  <input
                    nz-input
                    id="'phone'"
                    formControlName="phone"
                    placeholder="Input Phone Number ..."
                  />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="mapLink" class="text-left">
                  Map Links
                </nz-form-label>
                <nz-form-control nzErrorTip="Please Input Map Links!">
                  <input
                    nz-input
                    id="'mapLink'"
                    formControlName="mapLinks"
                    placeholder="Input Map Links ..."
                  />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="latitude" class="text-left">
                  Latitude
                </nz-form-label>
                <nz-form-control nzErrorTip="Please Input Latitude!">
                  <input
                    nz-input
                    id="'latitude'"
                    formControlName="lat"
                    type="number"
                    placeholder="Input Latitude ..."
                  />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="longitude" class="text-left">
                  Longitude
                </nz-form-label>
                <nz-form-control nzErrorTip="Please Input Longitude!">
                  <input
                    nz-input
                    id="'longitude'"
                    formControlName="long"
                    type="number"
                    placeholder="Input Longitude ..."
                  />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <iframe
                  width="600"
                  height="450"
                  style="border:0"
                  loading="lazy"
                  allowfullscreen
                  referrerpolicy="no-referrer-when-downgrade"
                  src="https://www.google.com/maps/embed/v1/place?key=API_KEY
                  &q=Space+Needle,Seattle+WA"
                >
                </iframe>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
</form>
