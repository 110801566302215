import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import store from 'store'
import { ApiService } from './api.service'
import {
  AgentForm,
  ProblemTypeForm,
  TicketCommentForm,
  TicketForm,
  VendorForm,
  AgentAccountForm,
} from './interface/ticketing.model'
import { QueryTable } from './interface/general.model'

@Injectable()
export class TicketingService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  serverOptionLoad(value: string, type: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/ticketing/ticket/autocomplete',
      { value, type },
      params,
    )
  }

  createTicket(obj: TicketForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/ticketing/ticket/create',
      obj,
      params,
    )
  }

  deleteTicket(tag: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/ticketing/ticket/delete',
      { tag: tag },
      params,
    )
  }

  getTicket(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/ticketing/tickets',
      queryTable,
      params,
    )
  }

  getTicketDetails(ticketId: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/ticketing/ticket/details',
      { tag: ticketId },
      params,
    )
  }

  createCommentTicket(obj: TicketCommentForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/ticketing/ticket/create/comment',
      obj,
      params,
    )
  }

  totalTicket(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/ticketing/ticket/total',
      queryTable,
      params,
    )
  }

  exportTicket(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/ticketing/ticket/export',
      queryTable,
      params,
    )
  }

  // -------------------------- Start Agent --------------------------- //

  getAgent(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/admistration/agents',
      queryTable,
      params,
    )
  }

  createAgent(obj: AgentForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/admistration/agent/create',
      obj,
      params,
    )
  }

  updateAgent(obj: AgentForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/admistration/agent/update',
      obj,
      params,
    )
  }

  deleteAgent(code: String): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/admistration/agent/delete',
      { code: code },
      params,
    )
  }

  // --------------------------  End  Agent --------------------------- //

  // -------------------------- Start Vendor --------------------------- //

  getVendor(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/admistration/vendors',
      queryTable,
      params,
    )
  }

  createVendor(obj: VendorForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/admistration/vendor/create',
      obj,
      params,
    )
  }

  updateVendor(obj: VendorForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/admistration/vendor/update',
      obj,
      params,
    )
  }

  deleteVendor(code: String): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/admistration/vendor/delete',
      { code: code },
      params,
    )
  }

  // --------------------------  End  Vendor --------------------------- //

  // -------------------------- Start Problem Type --------------------------- //

  getProblemType(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/admistration/problem-types',
      queryTable,
      params,
    )
  }

  createProblemType(obj: ProblemTypeForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/admistration/problem-type/create',
      obj,
      params,
    )
  }

  updateProblemType(obj: ProblemTypeForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/admistration/problem-type/update',
      obj,
      params,
    )
  }

  deleteProblemType(code: String): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/admistration/problem-type/delete',
      { code: code },
      params,
    )
  }

  // --------------------------  End  Problem Type --------------------------- //

  // -------------------------- Start Agent Management --------------------------- //

  createAgentAccount(form: AgentAccountForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_OSAS_SERVER + '/auth/signup', form, params)
  }

  updateAgentAccount(form: AgentAccountForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_OSAS_SERVER + '/deactivate', form, params)
  }

  getAgentAccount(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_USERSTATE_SERVER + '/get-agent', queryTable, params)
  }

  getOsasAgentAccount(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_OSAS_SERVER + '/get-agent', queryTable, params)
  }

  detailAgentAccount(username: string, type: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            username: username,
            type: type,
          },
        }
      : {}
    return this.http.get(this.apiService.API_USERSTATE_SERVER + '/agent-detail', params)
  }

  // --------------------------  End  Agent Management --------------------------- //
}
