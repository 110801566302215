<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form">
    <div class="row">
      <div class="marginBot col-lg-6 col-md-12">
        <h3 class="title-font">Title</h3>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input title!">
            <input class="content" nz-input id="'title'" formControlName="title" placeholder="Insert Ad Title ..." />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="marginBot col-lg-6 col-md-12">
        <h3 class="title-font">URL Destination</h3>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input URL!">
            <input class="content" nz-input id="'url'" formControlName="url" placeholder="Insert Destination URL ..." />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="marginBot col-lg-6 col-md-12">
        <h3 class="title-font">Department</h3>
        <nz-form-item>
          <nz-form-control nzErrorTip="Select from available department!">
            <nz-select formControlName="dept" nzPlaceHolder="Choose Department">
              <nz-option *ngFor="let item of deptArr" [nzLabel]="item.title" [nzValue]="item.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="marginBot col-lg-6 col-md-12">
        <h3 class="title-font">Section</h3>
        <nz-form-item>
          <nz-form-control nzErrorTip="Select from available section!">
            <nz-select formControlName="section" nzPlaceHolder="Choose Section">
              <nz-option *ngFor="let item of sectionArr" [nzLabel]="item.title" [nzValue]="item.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="marginBot col-lg-6 col-md-12">
        <h3 class="title-font">Upload Indonesian Banner</h3>
        <div class="col-lg-12 col-md-12">
          <nz-upload nzListType="picture-card" [(nzFileList)]="fileListIDN" [nzPreview]="handlePreview"
            [nzDownload]="handleDownload" [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq"
            [nzBeforeUpload]="handleBeforeUpload" [nzDisabled]="fileListIDN.length > 0"
            [nzShowButton]="!(fileListIDN.length > 0)" (nzChange)="handleChange($event)">
            <div>
              <i nz-icon nzType="plus"></i>
              <div style="margin-top: 8px">Upload</div>
            </div>
          </nz-upload>
          <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
            (nzOnCancel)="previewVisible = false">
            <ng-template #modalContent>
              <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
            </ng-template>
          </nz-modal>
        </div>
      </div>

      <div class="marginBot col-lg-6 col-md-12">
        <h3 class="title-font">Upload English Banner</h3>

        <div class="col-lg-12 col-md-12">
          <nz-upload nzListType="picture-card" [(nzFileList)]="fileListENG" [nzPreview]="handlePreview"
            [nzDownload]="handleDownload" [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq"
            [nzBeforeUpload]="handleBeforeUpload" [nzDisabled]="fileListENG.length > 0"
            [nzShowButton]="!(fileListENG.length > 0)" (nzChange)="handleChange($event)">
            <div>
              <i nz-icon nzType="plus"></i>
              <div style="margin-top: 8px">Upload</div>
            </div>
          </nz-upload>
        </div>
      </div>

      <div class="marginBot col-lg-6 col-md-12">
        <h3 class="title-font">Upload Chinese Banner</h3>

        <div class="col-lg-12 col-md-12">
          <nz-upload nzListType="picture-card" [(nzFileList)]="fileListCN" [nzPreview]="handlePreview"
            [nzDownload]="handleDownload" [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq"
            [nzBeforeUpload]="handleBeforeUpload" [nzDisabled]="fileListCN.length > 0"
            [nzShowButton]="!(fileListCN.length > 0)" (nzChange)="handleChange($event)">
            <div>
              <i nz-icon nzType="plus"></i>
              <div style="margin-top: 8px">Upload</div>
            </div>
          </nz-upload>
        </div>
      </div>
    </div>

    <div class="widthContainer" style="margin-left: 15px;">
      <h3 class="title-font">Publishing Status</h3>
      <nz-form-item>
        <nz-form-control nzErrorTip="Select available mode!">
          <nz-select formControlName="status" nzPlaceHolder="Choose Publishing Status">
            <nz-option nzLabel="Publish" [nzValue]="true"></nz-option>
            <nz-option nzLabel="Draft" [nzValue]="false"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
</nz-spin>