import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'
import store from 'store'
import { QueryTable } from './interface/general.model'
import { AdForm } from './interface/ad.model'
import { BlogForm } from './interface/blog.model'
import { DeptForm } from './interface/department.model'
import { VacancyForm } from './interface/vacancy.model'
import { DropPointForm } from './interface/drop-point.model'
import { TrainingForm } from './interface/training.model'

@Injectable({
  providedIn: 'root',
})
export class CMSService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  //--   BANNER SERVICES   --//

  createAd(form: AdForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/cms/ad/create', form, params)
  }

  updateAd(form: AdForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/cms/ad/update', form, params)
  }

  getAd(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/ads/private',
      queryTable,
      params,
    )
  }

  detailAd(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/cms/ad/detail', params)
  }

  deleteAd(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    const body = {
      id: id,
    }
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/cms/ad/delete', body, params)
  }

  //--   BLOG SERVICES   --//

  createBlog(form: BlogForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/blog/create',
      form,
      params,
    )
  }

  updateBlog(form: BlogForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/blog/update',
      form,
      params,
    )
  }

  getBlog(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/blog/list/private',
      queryTable,
      params,
    )
  }

  detailBlog(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/blog/detail/private',
      params,
    )
  }

  deleteBlog(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    const body = {
      id: id,
    }
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/blog/delete',
      body,
      params,
    )
  }

  //--   DROP POINT SERVICES   --//

  addDropPoint(form: DropPointForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/droppoint/create',
      form,
      params,
    )
  }

  updateDropPoint(form: DropPointForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/droppoint/update',
      form,
      params,
    )
  }

  getDropPoint(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/droppoint/list/private',
      queryTable,
      params,
    )
  }

  dropPointDetail(locationId: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            locationId: locationId,
          },
        }
      : {}
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/droppoint/detail/private',
      params,
    )
  }

  deleteDropPoint(locationId: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    const body = {
      locationId: locationId,
    }
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/droppoint/delete',
      body,
      params,
    )
  }

  //--   VACANCY SERVICES   --//

  createVacancy(form: VacancyForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/vacancy/create',
      form,
      params,
    )
  }

  updateVacancy(form: VacancyForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/vacancy/update',
      form,
      params,
    )
  }

  getVacancy(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/vacancy/list/private',
      queryTable,
      params,
    )
  }

  deptOption(): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/cms/department/option', params)
  }

  detailVacancy(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/vacancy/details/private',
      params,
    )
  }

  deleteVacancy(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    const body = {
      id: id,
    }
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/vacancy/delete',
      body,
      params,
    )
  }

  // -- ONDELIVERY CMS -- //

  waybillLotteryList(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/waybill-list',
      queryTable,
      params,
    )
  }

  registeredAgentList(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/registered-agent-list',
      queryTable,
      params,
    )
  }

  //--   DEPARTMENT SERVICES   --//

  createDept(form: DeptForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/department/create',
      form,
      params,
    )
  }

  updateDept(form: DeptForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/department/update',
      form,
      params,
    )
  }

  getDept(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/department/list/private',
      queryTable,
      params,
    )
  }

  detailDept(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/department/detail/private',
      params,
    )
  }

  deleteDept(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    const body = {
      id: id,
    }
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/department/delete',
      body,
      params,
    )
  }

  //--   TRAINING SERVICES   --//

  createTraining(form: TrainingForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/training/create',
      form,
      params,
    )
  }

  updateTraining(form: TrainingForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/training/update',
      form,
      params,
    )
  }

  getTraining(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/training/list/private',
      queryTable,
      params,
    )
  }

  detailTraining(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/training/detail/private',
      params,
    )
  }

  deleteTraining(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    const body = {
      id: id,
    }
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/training/delete',
      body,
      params,
    )
  }

  getViews(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cms/training/views/list',
      queryTable,
      params,
    )
  }
}
