import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { ApiService } from 'src/app/services/api.service'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit {
  formPassword: FormGroup
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private notification: NzNotificationService,
    private router: Router,
  ) {
    this.formPassword = this.fb.group({
      oldPassword: [null, Validators.compose([Validators.required])],
      newPassword: [null, Validators.compose([Validators.required])],
      confirmNewPassword: [null, [this.confirmValidator]],
    })
  }

  ngOnInit(): void {}
  validateConfirmPassword(): void {
    setTimeout(() => this.formPassword.controls.confirmNewPassword.updateValueAndValidity())
  }
  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true }
    } else if (control.value !== this.formPassword.controls.newPassword.value) {
      return { confirm: true, error: true }
    }
    return {}
  }

  submitForm() {
    var valueForm = this.formPassword.value
    this.apiService.changePassword(valueForm.oldPassword, valueForm.newPassword).subscribe(
      r => {
        this.router.navigate(['/user-profile'])
        this.notification.success('Success', r.message)
      },
      err => {
        this.notification.error('Error', err.error.message)
        this.formPassword.reset()
      },
    )
  }
}
