import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzTabComponent } from 'ng-zorro-antd/tabs'
import { userList } from 'src/app/models/freelance-marketing.models'
import { FreelanceMarketingService } from 'src/app/services/freelance-management/freelance-marketing.service'

@Component({
  selector: 'app-freelance-marketing',
  templateUrl: './freelance-marketing.component.html',
  styleUrls: ['./freelance-marketing.component.scss'],
})
export class FreelanceMarketingComponent implements OnInit {
  activeTab: number = 0
  freelanceData: userList[]
  isLoading: boolean
  constructor(
    private freelanceMarketingService: FreelanceMarketingService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.activeTab = params['tab'] || 0
    })
    this.fetchFreelanceList('candidate')
  }

  onChangeTab(event: any) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: event.index },
      queryParamsHandling: 'merge', // Keep existing query parameters
    })
    this.fetchFreelanceList(event.tab.nzTitle)
  }

  fetchFreelanceList(progress: string): void {
    this.freelanceData = []
    this.isLoading = true
    this.freelanceMarketingService.fetchUser(progress).subscribe(
      (res: userList[]) => {
        this.isLoading = false
        this.freelanceData = res
      },
      error => {
        console.error(error)
      },
    )
  }
}
