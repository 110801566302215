import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { QueryTable } from 'src/app/services/interface/general.model'
import { NzTableQueryParams, NzTableFilterList, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { TicketingService } from 'src/app/services/ticketing.service'
import { AgentAccount } from 'src/app/services/interface/ticketing.model'
import { AddAgentAccountComponent } from './add-agent/add-agent.component'
import { EditAgentComponent } from './edit-agent/edit-agent.component'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}
@Component({
  selector: 'app-agent-management',
  templateUrl: './agent-management.component.html',
})
export class AgentManagementComponent implements OnInit {
  listOfData: AgentAccount[] = []

  isLoading: boolean = true
  isDownloading: boolean = false
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  type: string = 'userstate'

  form: FormGroup
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private ticketService: TicketingService,
    private fb: FormBuilder,
    private store: Store<any>,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
      prefix: ['name', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  dbType = [
    {
      title: 'Userstate',
      key: 'userstate',
      selected: true,
      disabled: false,
    },
    {
      title: 'OSAS',
      key: 'osas',
      selected: false,
      disabled: false,
    },
  ]

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '65px',
    },
    {
      name: 'Agent ID',
      key: 'agent_id',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },
    {
      name: 'Agent Name',
      key: 'name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '220px',
    },
    {
      name: 'Location Side',
      key: 'agent',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '190px',
    },
    {
      name: 'Status',
      key: 'disabled',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '120px',
    },
  ]

  ngOnInit(): void {
    this.fetchAgent()
  }

  fetchAgent() {
    this.isLoading = true
    if (this.type === 'osas') {
      this.ticketService.getOsasAgentAccount(this.queryTable).subscribe((r: any) => {
        this.isLoading = false
        // console.log(r)
        this.listOfData = r.rows
        this.totalData = r.count
      })
    } else {
      this.ticketService.getAgentAccount(this.queryTable).subscribe((r: any) => {
        this.isLoading = false
        // console.log(r)
        this.listOfData = r.rows
        this.totalData = r.count
      })
    }
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchAgent()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.fetchAgent()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'name' })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.queryTable.pageSize = this.pageSize
    this.queryTable.pageIndex = this.pageIndex - 1
    this.pageSize = 25
    this.pageIndex = 1
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchAgent()
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  changeType(event: any) {
    var filterObj = {
      field: '',
      keyword: '',
    }
    if (event.key == 'osas') {
      ;(filterObj.field = 'type'), (filterObj.keyword = 'osas')
      this.type = 'osas'
      this.queryTable.pageSize = this.pageSize
      this.queryTable.pageIndex = this.pageIndex - 1
      this.pageSize = 25
      this.pageIndex = 1
      // console.log(this.pageSize)
      // console.log(this.pageIndex)
    } else if (event.key == 'userstate') {
      ;(filterObj.field = 'type'), (filterObj.keyword = 'userstate')
      this.type = 'userstate'
      this.queryTable.pageSize = this.pageSize
      this.queryTable.pageIndex = this.pageIndex - 1
      this.pageSize = 25
      this.pageIndex = 1
      // console.log(this.pageSize)
      // console.log(this.pageIndex)
    }
    var isExist = false
    for (let i = 0; i < this.queryTable.filters.length; i++) {
      if (this.queryTable.filters[i].field == 'type') {
        isExist = true
        this.queryTable.filters[i].keyword = filterObj.keyword
      } else {
        this.queryTable.filters[i].field = 'type'
        isExist = true
        this.queryTable.filters[i].keyword = filterObj.keyword
      }
    }
    if (!isExist) this.queryTable.filters.push(filterObj)
    this.fetchAgent()
  }

  addAgent() {
    const modal = this.modal.create({
      nzTitle: 'Add New Agent',
      nzContent: AddAgentAccountComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Create',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          instance.submitForm()
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchAgent()
    })
  }

  async viewAgent(username: string, type: string) {
    const modal = this.modal.create({
      nzTitle: 'View Agent',
      nzContent: EditAgentComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        username,
        type,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Update',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          instance.submitForm()
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchAgent()
    })
  }
}
