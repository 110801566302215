import { Component, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import { LeaveForm } from 'src/app/services/interface/leave.model'
import { ScheduleRoomService } from 'src/app/services/schedule-room.service'
import { LeaveService } from 'src/app/services/leave.service'
import { differenceInCalendarDays } from 'date-fns'
import { NzMessageService } from 'ng-zorro-antd/message'

interface options {
  code: string
  name: string
}

interface optionArgs {
  type: string
  value: string
}

@Component({
  selector: 'app-add-leave',
  templateUrl: './add-leave.component.html',
})
export class AddLeaveComponent implements OnInit {
  form: FormGroup
  searchChange$ = new BehaviorSubject({ type: '', value: '' })
  isLoading: boolean = false
  isLoadingOptions: boolean = false
  subsArr: options[] = []
  user: any
  days: number
  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private scheduleRoomService: ScheduleRoomService,
    private leaveService: LeaveService,
    private modal: NzModalRef,
    private msg: NzMessageService,
  ) {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      days: [null, [Validators.required]],
      date: this.fb.array([]),
      substitute: [null, [Validators.required]],
      leave_notes: [null, [Validators.required]],
      code: [null, [Validators.required]],
    })
    this.scheduleRoomService.username().subscribe(data => {
      this.form.get('name').patchValue(data.name)
      this.form.get('code').patchValue(data.code)
      this.user = data
    })
  }

  disabledDatePassed = (current: Date): boolean => {
    // Can not select days before today and today
    var date = new Date()
    return differenceInCalendarDays(current, date) <= 0
  }

  ngOnInit(): void {
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
    const getRandomNameList = (args: optionArgs) =>
      this.leaveService.subsOption(args.value, args.type).pipe(
        catchError(() => of({ results: [] })),
        map((res: any) => {
          var r = {}
          r[args.type] = res
          return r
        }),
      )
    const optionList$: Observable<options[]> = this.searchChange$
      .asObservable()
      .pipe(debounceTime(300))
      .pipe(switchMap<optionArgs, Observable<any>>(getRandomNameList))

    optionList$.subscribe(r => {
      var keys = Object.keys(r)[0]
      switch (keys) {
        case 'substitute':
          this.subsArr = r[keys]
          break
      }
      this.isLoadingOptions = false
    })

    this.form.controls['days'].valueChanges.pipe(debounceTime(500)).subscribe(data => {
      this.days = data
      this.dateArray.clear()
      for (let i = 0; i < this.days; i++) {
        this.dateArray.push(new FormControl(null, [Validators.required]))
      }
    })
  }

  get dateArray(): FormArray {
    return this.form.controls['date'] as FormArray
  }

  cancel(): void {
    this.modal.destroy()
  }

  search(value: string, type: string) {
    this.isLoadingOptions = true
    this.searchChange$.next({ type, value })
  }

  submitForm() {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
    if (this.form.valid) {
      var formSubs: LeaveForm = this.form.value
      this.leaveService.applyLeave(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.modal.destroy()
          this.isLoading = false
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }
}
