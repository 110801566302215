import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzTableSortOrder, NzTableFilterList, NzTableQueryParams } from 'ng-zorro-antd/table'
import { tap } from 'rxjs/operators'
import { ViewShiftSchedulerFormResponse } from 'src/app/services/interface/shift-scheduler.model'
import { ShiftService } from 'src/app/services/shift.service'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-list-shift-scheduler',
  templateUrl: './list-shift-scheduler.component.html',
})
export class ListShiftSchedulerComponent implements OnInit {
  listOfData: any[] = []
  isLoading: boolean = false
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  form: FormGroup
  date = null
  currentYear: number
  currentMonth: number
  queryTable: any = {
    sort: null,
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'NIP',
      key: 'employee_code',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '200px',
    },
    {
      name: 'Employee Name',
      key: 'employee_name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '200px',
    },
    {
      name: 'Year',
      key: 'year',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '50px',
    },
    {
      name: 'Month',
      key: 'month',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '100px',
    },
    {
      name: 'Placement',
      key: 'employee_placement',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '100px',
    },
    ...Array.from({ length: 31 }).map((_, index) => ({
      name: `${index + 1}`,
      key: `schedule${index + 1}`,
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    })),
  ]

  constructor(private shiftService: ShiftService, private fb: FormBuilder) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
    const currentDate = new Date()
    this.currentYear = currentDate.getFullYear()
    this.currentMonth = currentDate.getMonth() + 1
    this.queryTable.month = this.currentMonth
    this.queryTable.year = this.currentYear
    this.fetchShiftScheduler()
  }

  fetchShiftScheduler() {
    this.isLoading = true
    this.shiftService
      .getShiftScheduler(this.queryTable)
      .pipe(
        tap({
          next: (res: ViewShiftSchedulerFormResponse) => {
            this.isLoading = false
            this.totalData = res.count
            this.listOfData = this.transformData(res.rows)
          },
          error: () => {
            this.isLoading = false
          },
        }),
      )
      .subscribe()
  }

  transformData(data: any[]): any[] {
    return data.map(employee => {
      const scheduleData = employee.schedule.reduce(
        (acc: { [x: string]: any }, schedule: any, index: number) => {
          acc[`schedule${index + 1}`] = schedule
          return acc
        },
        {},
      )

      return { ...employee, ...scheduleData }
    })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchShiftScheduler()
  }

  getMonthName(monthNumber: number): string {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return months[monthNumber - 1]
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: 'placement_name',
          keyword: this.form.value.keyword,
        },
      ]
    }
    this.fetchShiftScheduler()
  }

  onChange(result: any): void {
    if (result != null) {
      this.queryTable.month = result.getMonth() + 1
      this.queryTable.year = result.getFullYear()
    }
    this.fetchShiftScheduler()
  }

  reset() {
    this.form.reset()
    this.queryTable.sort = null
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    const currentDate = new Date()
    this.queryTable.month = currentDate.getMonth() + 1
    this.queryTable.year = currentDate.getFullYear()
    this.date = null
    this.fetchShiftScheduler()
  }

  trackByName(_: number, item: any): string {
    return item.name
  }
}
