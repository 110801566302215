import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-statistics-ticketing',
  templateUrl: './statistics-ticketing.component.html',
})
export class StatisticsTicketingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
