<div class="topbar">
  <div class="mr-4">
    <vb-topbar-fav-pages></vb-topbar-fav-pages>
  </div>
  <div class="mr-auto mr-md-1">
    <vb-topbar-search></vb-topbar-search>
  </div>
  <div class="mr-4 d-none d-md-block">
    <!-- <vb-topbar-issues-history></vb-topbar-issues-history> -->
  </div>
  <div class="mr-auto d-xl-block d-none">
    <!-- <vb-topbar-project-management></vb-topbar-project-management> -->
  </div>
  <div class="mr-3 d-none d-sm-block ml-auto">
    <!-- <vb-topbar-cart></vb-topbar-cart> -->
  </div>
  <div class="mr-3 d-none d-sm-block">
    <!-- <vb-topbar-actions></vb-topbar-actions> -->
    <div
      nz-popover
      (click)="checkLink()"
      nzTrigger="click"
      class="dropdown"
      nzPopoverTrigger="click"
      nzPopoverTitle="Mobile App"
      [nzPopoverContent]="contentTemplate"
      nzPopoverPlacement="bottomRight"
    >
      <nz-avatar
        nzIcon="download"
        [nzShape]="'square'"
        [nzSize]="'large'"
        class="avatar"
      ></nz-avatar>
    </div>
    <ng-template #contentTemplate>
      <div style="width: 200px;height: 270px;">
        <div class="col" style="padding: 0px; text-align: center;">
          <img nz-image width="180px" height="200px" [nzSrc]="linkQR" alt="QRCODE" />
        </div>
        <div class="col text-center p-2">
          <div class="col">
            <button nz-button nzType="primary" (click)="openNewTab()">Download</button>
          </div>
          <div class="col">
            <span class="p-2">Version {{ version }}</span>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="mr-3 d-none d-sm-block">
    <vb-topbar-language-switcher></vb-topbar-language-switcher>
  </div>
  <div>
    <vb-topbar-user-menu></vb-topbar-user-menu>
  </div>
</div>
