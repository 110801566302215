<nz-tabset [nzSelectedIndex]="activeTab" (nzSelectChange)="onChangeTab($event)">
  <nz-tab nzTitle="Candidate">
    <div *ngIf="isLoading" class="d-flex justify-content-center mt-5"><nz-spin nzSimple></nz-spin></div>
    <app-candidate *ngIf="!isLoading" [data]="freelanceData"></app-candidate>
  </nz-tab>
  <nz-tab nzTitle="Existing">
    <div *ngIf="isLoading" class="d-flex justify-content-center mt-5"><nz-spin nzSimple></nz-spin></div>
    <app-existing *ngIf="!isLoading" [data]="freelanceData"></app-existing>
  </nz-tab>
  <nz-tab nzTitle="Reserved">
    <div *ngIf="isLoading" class="d-flex justify-content-center mt-5"><nz-spin nzSimple></nz-spin></div>
    <app-reserved *ngIf="!isLoading" [data]="freelanceData"></app-reserved>
  </nz-tab>
</nz-tabset>

<router-outlet></router-outlet>