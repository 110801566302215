<form nz-form [formGroup]="formPassword" (ngSubmit)="submitForm()">
  <nz-form-item>
    <nz-form-label [nzSpan]="7" nzRequired>Old Password</nz-form-label>
    <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="Please input your password!">
      <input
        nz-input
        type="password"
        formControlName="oldPassword"
        placeholder="input your old password"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="7" nzRequired>New Password</nz-form-label>
    <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="Please input your password!">
      <input
        nz-input
        type="password"
        formControlName="newPassword"
        placeholder="input your new password"
        (ngModelChange)="validateConfirmPassword()"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="7" nzRequired>Confirm New Password</nz-form-label>
    <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="passwordErrorTpl">
      <input
        nz-input
        type="password"
        formControlName="confirmNewPassword"
        placeholder="confirm your password"
      />
      <ng-template #passwordErrorTpl let-control>
        <ng-container *ngIf="control.hasError('required')"
          >Please confirm your password!</ng-container
        >
        <ng-container *ngIf="control.hasError('confirm')">Password is inconsistent!</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzOffset]="7" [nzSpan]="12">
      <button nz-button nzType="primary" class="float-right" [disabled]="!formPassword.valid">
        Save New Password
      </button>
    </nz-form-control>
  </nz-form-item>
</form>
