import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ApiService } from 'src/app/services/api.service'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { differenceInCalendarDays } from 'date-fns'
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import { VoucherForm } from 'src/app/services/interface/voucher.model'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { BehaviorSubject, Observable, Observer, of } from 'rxjs'
import storeeeee from 'store'
import { VoucherService } from 'src/app/services/onapps/voucher.service'
import { NewVoucherService } from 'src/app/services/onapps/new-voucher.service'
const _ = require('lodash')

interface options {
  id: number
  name: string
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

@Component({
  selector: 'app-create-new-user-configs',
  templateUrl: './create-new-user-configs.component.html',
  styleUrls: ['./create-new-user-configs.component.css'],
})
export class CreateNewUserConfigsComponent implements OnInit {
  isLoading: boolean = false
  isLoadingOptions: boolean = false
  fileList: NzUploadFile[] = []
  listOfDeptOption: string[] = ['ONDELIVERY', 'ONMARKET', 'ONTICKET', 'ONAPPS']
  listOfServiceOption: Array<options>
  isOndelivery: boolean = false
  isIndividual: boolean = false
  is_inner_city_selected: boolean = false
  transaction_same_asform: boolean = false
  res_transaction_custom: boolean
  res_time_custom: boolean
  listOfOriginCityOption: Array<{ city_id: string; city_name: string }> = []
  listOfDestinationCityOption: Array<{ city_id: string; city_name: string }> = []
  selectedOriginCity: Array<{ city_id: string; city_name: string }> = []
  selectedDestinationCity: Array<{ city_id: string; city_name: string }> = []
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private onappsService: VoucherService,
    private NewVoucherService: NewVoucherService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private http: HttpClient,
  ) {
    this.form = this.fb.group({
      code: [null, Validators.compose([Validators.required])],
      name: [null, Validators.compose([Validators.required])],
      type: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      status: [null],
      value: [null, Validators.compose([Validators.required, Validators.min(1)])],
      quota: [null /*, Validators.compose([Validators.required, Validators.min(1)])*/],
      quota_per_user: [null /*, Validators.compose([Validators.required, Validators.min(1)])*/],
      service: [null],
      origin_city: [null],
      destination_city: [null],
      department: [null, Validators.compose([Validators.required])],
      startDate: [null, Validators.compose([Validators.required])],
      dueDate: [null, Validators.compose([Validators.required])],
      image: [null, Validators.compose([Validators.required])],
      minDiscount: [null],
      maxDiscount: [null],
      is_inner_city: false,
      min_weight: 0,
      max_weight: 0,
      // isFcm: [null],
      is_active: false,
      restriction_transaction_service: false, //boolean
      restriction_transaction_custom: [null],
      restriction_time_registration: false, //boolean
      restriction_time_start: null,
      restriction_time_end: null,
    })
  }

  disabledDate = (current: Date): boolean => {
    // console.log(differenceInCalendarDays(new Date(),current))
    // Can not select days before today and today
    return differenceInCalendarDays(current, new Date()) < 0
  }

  form: FormGroup

  ngOnInit(): void {
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    // this.form.controls['city'].valueChanges.pipe(debounceTime(500)).subscribe(r => {
    //   if (typeof r == 'string') {
    //     this.destinationSearch(r.toLowerCase())
    //   } else {
    //     this.destinationSearch(r.city_name.toLowerCase())
    //   }
    // })
    this.isLoadingOptions = true
    this.onappsService.serviceList().subscribe(r => {
      this.listOfServiceOption = r
      this.isLoadingOptions = false
    })
  }

  departmentSelect(event) {
    this.isOndelivery = false
    for (var i = 0; i < event.length; i++) {
      if (event[i] === 'ONDELIVERY') {
        this.isOndelivery = true
      }
    }
  }

  statusSelect(event: any) {
    this.isIndividual = false
    if (event === 'individual') {
      this.isIndividual = true
    }
  }

  inner_city_select(event: boolean) {
    this.is_inner_city_selected = false
    if (event === true) {
      this.is_inner_city_selected = true
    }
  }

  transactionCriteriaSelect(event: any) {
    if (event === true) {
      this.res_transaction_custom = true
    } else {
      this.res_transaction_custom = false
    }
  }

  // transactionDiffSelect(event: any) {
  //   if (event === true) {
  //     this.res_transaction_custom = false
  //   } else {
  //     this.res_transaction_custom = true
  //   }
  // }

  timeCriteriaSelect(event: any) {
    if (event === true) {
      this.res_time_custom = true
    } else {
      this.res_time_custom = false
    }
  }

  originSearch(keyword: string): void {
    this.isLoadingOptions = true
    this.onappsService.destinationSearchNew(keyword).subscribe(r => {
      var value = []
      for (let i = 0; i < r.length; i++) {
        if (r[i].city_name.toLowerCase().startsWith(keyword)) {
          value.push({ city_id: r[i].city_id, city_name: r[i].city_name })
        }
      }

      this.listOfOriginCityOption = _.uniqBy(value, 'city_id')

      if (this.selectedOriginCity.length > 0) {
        this.listOfOriginCityOption = this.listOfOriginCityOption.filter(val => {
          return !this.selectedOriginCity.find(val2 => {
            return val.city_id === val2.city_id
          })
        })
      }
      this.isLoadingOptions = false
    })
  }

  destinationSearch(keyword: string): void {
    this.isLoadingOptions = true
    this.onappsService.destinationSearchNew(keyword).subscribe(r => {
      var value = []
      for (let i = 0; i < r.length; i++) {
        if (r[i].city_name.toLowerCase().startsWith(keyword)) {
          value.push({ city_id: r[i].city_id, city_name: r[i].city_name })
        }
      }

      this.listOfDestinationCityOption = _.uniqBy(value, 'city_id')

      if (this.selectedDestinationCity.length > 0) {
        this.listOfDestinationCityOption = this.listOfDestinationCityOption.filter(val => {
          return !this.selectedDestinationCity.find(val2 => {
            return val.city_id === val2.city_id
          })
        })
      }
      this.isLoadingOptions = false
    })
  }

  originCitySelect(event: any) {
    this.selectedOriginCity = event
  }

  destinationCitySelect(event: any) {
    this.selectedDestinationCity = event
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
    var isUploading = false
    for (let i = 0; i < this.fileList.length; i++) {
      if (['uploading', 'error'].includes(this.fileList[i].status)) {
        isUploading = true
        break
      }
      if (isUploading) break
    }

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }

    if (this.is_inner_city_selected == true) {
      this.form.value.destination_city = this.form.value.origin_city
    }

    if (!isUploading && this.form.valid) {
      var formSubs: VoucherForm = this.form.value
      if (this.fileList[0] != null && this.fileList[0].status === 'done') {
        formSubs.image = {
          name: this.fileList[0].response?.name || this.fileList[0].name,
          status: this.fileList[0].status,
          url: this.fileList[0].response?.url || this.fileList[0].url,
          uid: this.fileList[0].uid,
        }
      }
      this.NewVoucherService.createVoucherConfig(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.isLoading = false
          this.modal.destroy()
          // this.fetchVoucherDetails()
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          // this.fetchVoucherDetails()
          return false
        },
      )
    } else {
      this.msg.error(
        isUploading ? 'Please wait until all files uploaded' : 'Please Fill Blank Form',
      )
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }

  handleBeforeUpload = (file: NzUploadFile): boolean => {
    if (file.size <= 11534336) {
      return true
    } else {
      this.msg.error('File too Large')
      return false
    }
  }

  customUploadReq = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    formData.append('file', item.file as any)

    const req = new HttpRequest(
      'POST',
      this.apiService.API_SUNSHINE_SERVER + '/api/upload/temp',
      formData,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + storeeeee.get('accessToken'),
        }),
        reportProgress: true,
        withCredentials: false,
      },
    )

    return this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            ;(event as any).percent = (event.loaded / event.total) * 100 // tslint:disable-next-line:no-any
          }
          item.onProgress!(event, item.file)
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file, event)
        }
      },
      err => {
        // console.log(err)
        /* error */
        item.onError!(err, item.file)
      },
    )
  }

  handleRemove = (file: NzUploadFile) =>
    new Observable((observer: Observer<boolean>) => {
      this.form.patchValue({ image: null })
      observer.next(true)
      observer.complete()
    })

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?s=download', '_blank')
  }

  previewImage: string | undefined = ''
  previewVisible = false

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!)
    }
    this.previewImage = file.response?.url || file.url || file.preview
    this.previewVisible = true
  }

  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`)
      this.form.patchValue({ image: true })
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`)
    }
  }
}
