import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'
import store from 'store'
import { DriverForm } from './interface/driver.model'
import { QueryTable } from './interface/general.model'
import { WhForm } from './interface/warehouse.model'

@Injectable({
  providedIn: 'root',
})
export class FleetService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  getDriver(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/driver/list',
      queryTable,
      params,
    )
  }

  getTripLog(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/triplog/list',
      queryTable,
      params,
    )
  }

  createDriver(form: DriverForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/driver/add', form, params)
  }

  detailDriver(driverId: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            driverId: driverId,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/driver/detail', params)
  }

  updateDriver(form: DriverForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/driver/update', form, params)
  }

  getWh(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/wh/list', queryTable, params)
  }

  createWh(form: WhForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/wh/add', form, params)
  }

  deleteWh(whId: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/wh/delete',
      { whId: whId },
      params,
    )
  }
}
