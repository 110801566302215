<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="card-header-flex  align-items-center">
            <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Add ProblemType">
              <a class="btn btn-lg btn-light mr-2" (click)="createProblemType(tplFooterCreate)">
                <i class="fe fe-plus-circle"></i>
              </a>
            </span>
            <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Restart Filter & Sort">
              <a class="btn btn-lg btn-light mr-2" (click)="reset()">
                <i class="fe fe-refresh-cw"></i>
              </a>
            </span>
          </div>
        </div>
        <div class="card-body">
          <form nz-form [nzLayout]="'inline'" [formGroup]="form" (ngSubmit)="search()">
            <nz-form-item>
              <nz-form-label nzFor="prefix" class="text-left">Prefix</nz-form-label>
              <nz-form-control>
                <nz-select id="prefix" [formControl]="form.controls['prefix']" nzPlaceHolder="Select Prefix ..."
                  style="width: 150px;">
                  <nz-option nzValue="code" nzLabel="Code"></nz-option>
                  <nz-option nzValue="name" nzLabel="Name"></nz-option>
                  <!-- <nz-option nzValue="createdBy" nzLabel="Created By"></nz-option>
                  <nz-option nzValue="updatedBy" nzLabel="Updated By"></nz-option>
                  <nz-option nzValue="status" nzLabel="Status"></nz-option> -->
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control>
                <input [formControl]="form.controls['keyword']" nz-input placeholder="Keyword ..." />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control>
                <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
                  Search
                </button>
              </nz-form-control>
            </nz-form-item>
          </form>
          <nz-table #fixedTable nzShowSizeChanger class="table-ticketing" [nzData]="listOfData"
            [nzFrontPagination]="false" [nzLoading]="isLoading" [nzTotal]="totalData" [nzPageSize]="pageSize"
            [nzPageIndex]="pageIndex" [nzPageSizeOptions]="[25, 50, 100, 250]"
            (nzQueryParams)="onQueryParamsChange($event)">
            <thead>
              <tr>
                <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                  <th *ngIf="column.sortFn" [nzLeft]="column.left" [nzRight]="column.right" [nzSortFn]="column.sortFn"
                    [(nzSortOrder)]="column.sortOrder" [nzColumnKey]="column.key" [nzWidth]="column.width">
                    {{ column.name }}
                  </th>
                  <th *ngIf="!column.sortFn" [nzLeft]="column.left" [nzRight]="column.right" [nzColumnKey]="column.key"
                    [nzWidth]="column.width">
                    {{ column.name }}
                  </th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of fixedTable.data">
                <td nzLeft>
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Ticket">
                    <a class="btn btn-sm btn-light mr-2" (click)="updateProblemType(data, tplFooterUpdate)">
                      <i class="fe fe-eye"></i>
                    </a>
                  </span>
                  <span style="margin: 0 2px;"></span>
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Delete Ticket">
                    <a class="btn btn-sm btn-light mr-2" (click)="deleteProblemType(data.code)">
                      <i class="fe fe-trash-2"></i>
                    </a>
                  </span>
                </td>
                <td>{{ data.code }}</td>
                <td>{{ data.name }}</td>
                <td>{{ data.createdBy }}</td>
                <td>{{ data.updatedBy }}</td>
                <td nzRight>{{ data.status ? 'Active' : 'Inactive' }}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #tplFooterCreate let-ref="modalRef">
  <button nz-button (click)="ref.destroy()" [disabled]="ref.getContentComponent().isLoading" nzDanger>
    Cancel
  </button>
  <button nz-button nzType="primary" (click)="ref.getContentComponent().submitForm()"
    [nzLoading]="ref.getContentComponent().isLoading">
    Create
  </button>
</ng-template>

<ng-template #tplFooterUpdate let-ref="modalRef">
  <button nz-button (click)="ref.destroy()" [disabled]="ref.getContentComponent().isLoading" nzDanger>
    Cancel
  </button>
  <button nz-button nzType="primary" (click)="ref.getContentComponent().submitForm()"
    [nzLoading]="ref.getContentComponent().isLoading">
    Update
  </button>
</ng-template>