import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { Dept } from 'src/app/services/interface/department.model'
import { QueryTable } from 'src/app/services/interface/general.model'
import { NzTableFilterList, NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { CMSService } from 'src/app/services/cms.service'
import { AddDepartmentComponent } from './add-department/add-department.component'
import { EditDepartmentComponent } from './edit-department/edit-department.component'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss'],
})
export class DepartmentComponent implements OnInit {
  listOfData: Dept[] = []
  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  form: FormGroup
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private cmsService: CMSService,
    private notification: NzNotificationService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      keyword: [null],
      prefix: ['title', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '90px',
    },
    {
      name: 'Title',
      key: 'title',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Description',
      key: 'description',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '250px',
    },
    {
      name: 'Publish Status',
      key: 'status',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Created By',
      key: 'createdBy',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '230px',
    },
    {
      name: 'Updated By',
      key: 'updatedBy',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '230px',
    },
    {
      name: 'Updated At',
      key: 'updatedAt',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '190px',
    },
  ]

  ngOnInit(): void {
    this.fetchDept()
  }

  fetchDept() {
    this.isLoading = true
    this.cmsService.getDept(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count
    })
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchDept()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.fetchDept()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'title' })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchDept()
  }

  addDept() {
    const modal = this.modal.create({
      nzTitle: 'Add New Department',
      nzContent: AddDepartmentComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Create',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          instance.submitForm()
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchDept()
    })
  }

  async viewDept(id: string) {
    const modal = this.modal.create({
      nzTitle: 'View Department',
      nzContent: EditDepartmentComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        id: id,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Update',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve
          } else {
            reject
          }
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchDept()
    })
  }

  deleteDepts(id: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Are you sure you want to delete this department?',
      nzContent: '<b style="color: red;">Once deleted, there is no turning back.</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.cmsService.deleteDept(id).subscribe(
          r => {
            this.notification.success('Success', r.message)
            this.fetchDept()
          },
          err => {
            this.notification.error('Error', err.error.message)
            this.fetchDept()
          },
        )
      },
      nzCancelText: 'No',
      // nzOnCancel: () => console.log('Cancel'),
    })
  }
}
