import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzTableSortOrder, NzTableFilterList, NzTableQueryParams } from 'ng-zorro-antd/table'
import { HrService } from 'src/app/services/hr.service'
import { QueryTable } from 'src/app/services/interface/general.model'
import { Location } from 'src/app/services/interface/location-management.model'
import { AddLocationComponent } from './add-location/add-location.component'
import { EditLocationComponent } from './edit-location/edit-location.component'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-location-management',
  templateUrl: './location-management.component.html',
  styleUrls: ['./location-management.component.scss'],
})
export class LocationManagementComponent implements OnInit {
  listOfData: Location[] = []

  isLoading: boolean = false
  isDownloading: boolean = false
  totalData: number = 0
  pageSize: number = 10
  pageIndex: number = 1

  form: FormGroup

  constructor(
    private modal: NzModalService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private hrService: HrService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
      prefix: ['locationName'],
    })
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '65px',
    },
    {
      name: 'City',
      key: 'city',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '100px',
    },
    {
      name: 'Location Name',
      key: 'locationName',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '180px',
    },
    {
      name: 'Address',
      key: 'address',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '250px',
    },
  ]

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  ngOnInit(): void {
    this.fetchLocation()
  }

  fetchLocation() {
    this.isLoading = false
    // console.log('Query', this.queryTable)
    this.hrService.getLocation(this.queryTable).subscribe(
      (r: any) => {
        // console.log(r)
        this.isLoading = false
        this.listOfData = r.rows
        this.totalData = r.count
      },
      err => {
        this.isLoading = false
        this.notification.error('Error', err.error.message)
      },
    )
  }

  trackByName(_: number, item: ColumnItem): string {
    // console.log(item.name)
    return item.name
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchLocation()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.queryTable.pageIndex = 0
    this.fetchLocation()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'locationName' })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    // console.log('Kepencet')
    this.fetchLocation()
  }

  async viewLocation(code: string) {
    const modal = this.modal.create({
      nzTitle: 'View Location',
      nzContent: EditLocationComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        code,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Update',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve
          } else {
            reject
          }
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchLocation()
    })
  }

  // deleteLocation(id: number) {
  //   this.modal.confirm({
  //     nzTitle: 'Delete Location',
  //     nzContent:
  //       'Are you sure to delete this location? <br>  <b style="color: red;">This action cannot be undo</b>',
  //     nzOkText: 'Yes',
  //     nzOkType: 'primary',
  //     nzOkDanger: true,
  //     nzOnOk: () => {
  //       this.hrService.deleteLocation(id).subscribe(
  //         r => {
  //           this.notification.success('Success', r.message)
  //           this.fetchLocation()
  //         },
  //         err => {
  //           this.notification.error('Error', err.error.message)
  //           this.fetchLocation()
  //         },
  //       )
  //     },
  //   })
  // }

  addLocation() {
    const modal = this.modal.create({
      nzTitle: 'Add Location',
      nzContent: AddLocationComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Create',
      nzOkDisabled: true,
      nzWidth: '75%',
      nzOnOk: () => {
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve
          } else {
            reject
          }
        })
      },
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchLocation()
    })
  }

  exportLocation(): void {
    this.isDownloading = true
    var blankPage = window.open('http://example.com/waiting.html', '_blank')
    blankPage!.document.write('Loading preview...')
    this.hrService.exportLocation(this.queryTable).subscribe(
      r => {
        this.isDownloading = false
        blankPage!.location.href = r.link
        this.notification.success('Success', r.message)
      },
      err => {
        blankPage.close()
        this.isDownloading = false
        this.notification.error('Error', err.error.message)
      },
    )
  }
}
