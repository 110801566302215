import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { DropPointForm } from 'src/app/services/interface/drop-point.model'
import { CMSService } from 'src/app/services/cms.service'

@Component({
  selector: 'app-add-drop-point',
  templateUrl: './add-drop-point.component.html',
})
export class AddDropPointComponent implements OnInit {
  isLoading: boolean = false
  constructor(
    private fb: FormBuilder,
    private cmsService: CMSService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
  ) {
    this.form = this.fb.group({
      city: [null, Validators.compose([Validators.required])],
      locationName: [null, Validators.compose([Validators.required])],
      locationSide: [null, Validators.compose([Validators.required])],
      address: [null, Validators.compose([Validators.required])],
      phoneNumber: [null],
      mapLinks: [null],
      radiusSite: [null],
      latitude: [null],
      longitude: [null],
    })
  }

  form: FormGroup

  ngOnInit(): void {
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }

    if (this.form.valid) {
      var formSubs: DropPointForm = this.form.value
      this.cmsService.addDropPoint(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.modal.destroy()
          this.isLoading = false
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }
}
