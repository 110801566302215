import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { QueryTable } from 'src/app/services/interface/general.model'
import { NzTableQueryParams, NzTableFilterList, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { HrService } from 'src/app/services/hr.service'
import { Employee } from 'src/app/services/interface/employee.model'
import { AddEmployeeComponent } from './add-employee/add-employee.component'
import { EditEmployeeComponent } from './edit-employee/edit-employee.component'
import { ViewPlacementComponent } from './view-placement/view-placement.component'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}
@Component({
  selector: 'app-employee-management',
  templateUrl: './employee-management.component.html',
  styleUrls: ['./employee-management.component.scss'],
})
export class EmployeeManagementComponent implements OnInit {
  listOfData: Employee[] = []

  isLoading: boolean = false
  isDownloading: boolean = false
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1

  form: FormGroup
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private hrService: HrService,
    private fb: FormBuilder,
    private store: Store<any>,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
      prefix: ['name', Validators.compose([Validators.required])],
    })
  }

  typeEmployee = [
    {
      title: 'Intern Employee',
      key: 'internEmployee',
      selected: false,
      disabled: false,
    },
    {
      title: 'Fixed Employee',
      key: 'fixedEmployee',
      selected: true,
      disabled: false,
    },
    {
      title: 'Contract Employee',
      key: 'contractEmployee',
      selected: false,
      disabled: false,
    },
    {
      title: 'Freelancer Employee',
      key: 'freelancerEmployee',
      selected: false,
      disabled: false,
    },
    {
      title: 'Operations Employee',
      key: 'operationsEmployee',
      selected: false,
      disabled: false,
    },
  ]

  statusEmployee = [
    {
      title: 'Active Employee',
      key: 'activeEmployee',
      selected: false,
      disabled: false,
    },
    {
      title: 'Inactive Employee',
      key: 'disabledEmployee',
      selected: false,
      disabled: false,
    },
  ]

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '65px',
    },
    {
      name: 'Company',
      key: 'company',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '150px',
    },
    {
      name: 'Employee Code',
      key: 'code',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },
    {
      name: 'Employee Name',
      key: 'name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '220px',
    },
    {
      name: 'Position',
      key: 'position',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '190px',
    },
    {
      name: 'Role',
      key: 'role',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '110px',
    },
    {
      name: 'Placement',
      key: 'placement',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '120px',
    },
    {
      name: 'Status',
      key: 'disabled',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '120px',
    },
  ]

  ngOnInit(): void {
    this.fetchEmployee()
  }

  fetchEmployee() {
    this.isLoading = true
    this.hrService.getEmployee(this.queryTable).subscribe((r: any) => {
      // console.log(r)

      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count

      // console.log('LIST DATA', this.listOfData)
      // console.log('TOTAL DATA', this.totalData)
    })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchEmployee()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.queryTable.pageIndex = 0
    this.fetchEmployee()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'name' })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchEmployee()
  }

  changeType(event: any) {
    var filterObj = {
      field: '',
      keyword: '',
    }
    if (event.key == 'internEmployee') {
      ;(filterObj.field = 'employementStatus'), (filterObj.keyword = 'in')
    } else if (event.key == 'fixedEmployee') {
      ;(filterObj.field = 'employementStatus'), (filterObj.keyword = 'fe')
    } else if (event.key == 'contractEmployee') {
      ;(filterObj.field = 'employementStatus'), (filterObj.keyword = 'ce')
    } else if (event.key == 'freelancerEmployee') {
      ;(filterObj.field = 'employementStatus'), (filterObj.keyword = 'fr')
    } else if (event.key == 'operationsEmployee') {
      ;(filterObj.field = 'employementStatus'), (filterObj.keyword = 'op')
    }
    var isExist = false
    for (let i = 0; i < this.queryTable.filters.length; i++) {
      if (this.queryTable.filters[i].field == 'employementStatus') {
        isExist = true
        this.queryTable.filters[i].keyword = filterObj.keyword
      } else {
        this.queryTable.filters[i].field = 'employementStatus'
        isExist = true
        this.queryTable.filters[i].keyword = filterObj.keyword
      }
    }
    if (!isExist) this.queryTable.filters.push(filterObj)
    this.fetchEmployee()
  }

  changeStatus(event: any) {
    var filterObj = {
      field: '',
      keyword: '',
    }
    if (event.key == 'activeEmployee') {
      ;(filterObj.field = 'status'), (filterObj.keyword = 'false')
    } else if (event.key == 'disabledEmployee') {
      ;(filterObj.field = 'status'), (filterObj.keyword = 'true')
    }
    var isExist = false
    for (let i = 0; i < this.queryTable.filters.length; i++) {
      if (this.queryTable.filters[i].field == 'status') {
        isExist = true
        this.queryTable.filters[i].keyword = filterObj.keyword
      } else {
        this.queryTable.filters[i].field = 'status'
        isExist = true
        this.queryTable.filters[i].keyword = filterObj.keyword
      }
    }
    if (!isExist) this.queryTable.filters.push(filterObj)
    this.fetchEmployee()
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  async viewEmployee(code: string) {
    const modal = this.modal.create({
      nzTitle: 'View Employee',
      nzContent: EditEmployeeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        code,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Update',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve
          } else {
            reject
          }
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchEmployee()
    })
  }

  deleteEmployee(tag: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Are you sure delete this Employee?',
      nzContent: '<b style="color: red;">This action cannot be undo</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.hrService.deleteEmployee(tag).subscribe(
          r => {
            this.notification.success('Success', r.message)
            this.fetchEmployee()
          },
          err => {
            this.notification.error('Error', err.error.message)
            this.fetchEmployee()
          },
        )
      },
      nzCancelText: 'No',
      // nzOnCancel: () => console.log('Cancel'),
    })
  }

  addEmployee() {
    const modal = this.modal.create({
      nzTitle: 'Add New Employee',
      nzContent: AddEmployeeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Create',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve()
          } else {
            reject()
          }
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchEmployee()
    })
  }

  viewPlacement() {
    const modal = this.modal.create({
      nzContent: ViewPlacementComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzMaskClosable: false,
      nzClosable: false,
      nzWidth: '80%',
      // nzComponentParams: {
      //   config: config,
      //   company: this.selectedCompany,
      // },
    })
    modal.afterClose.subscribe(() => {
      this.fetchEmployee()
    })
  }

  exportEmployee(): void {
    this.isDownloading = true
    var blankPage = window.open('http://example.com/waiting.html', '_blank')
    blankPage!.document.write('Loading preview...')
    this.hrService.exportEmployee(this.queryTable).subscribe(
      r => {
        this.isDownloading = false
        blankPage!.location.href = r.link
        this.notification.success('Success', r.message)
      },
      err => {
        blankPage.close()
        this.isDownloading = false
        this.notification.error('Error', err.error.message)
      },
    )
  }
}
