<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <nz-form-item>
          <nz-form-label nzFor="name" class="text-left">Name</nz-form-label>
          <nz-form-control nzErrorTip="Please input name correctly!">
            <input nz-input id="name'" formControlName="name" placeholder="Input name ..." />
          </nz-form-control>
        </nz-form-item>
      </div>
  
      <div class="col-lg-6 col-md-12">
        <nz-form-item>
          <nz-form-label nzFor="capacity" class="text-left">Capacity</nz-form-label>
          <nz-form-control nzErrorTip="Please input a valid capacity">
            <input nz-input id="capacity" type="number" formControlName="capacity" placeholder="Input capacity ..." />
          </nz-form-control>
        </nz-form-item>
      </div>
      
      <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="status" class="text-left">Status</nz-form-label>
          <nz-form-control nzErrorTip="Please input a valid status">
            <nz-switch formControlName="status"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
      
      <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="notes" class="text-left">Notes</nz-form-label>
          <nz-form-control nzErrorTip="Please input a valid notes">
            <nz-textarea-count>
              <textarea rows="4" formControlName="notes" nz-input></textarea>
            </nz-textarea-count>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</nz-spin>