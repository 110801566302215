import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { QueryTable } from 'src/app/services/interface/general.model'
import { NzTableQueryParams, NzTableFilterList, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { LeaveService } from 'src/app/services/leave.service'
import { Leave } from 'src/app/services/interface/leave.model'
import { EditLeaveComponent } from './edit-leave/edit-leave.component'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-manage-leave',
  templateUrl: './manage-leave.component.html',
})
export class ManageLeaveComponent implements OnInit {
  listOfData: Leave[] = []
  date: FormControl = new FormControl(new Date())

  isLoading: boolean = false
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  form: FormGroup
  head: boolean
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private leaveService: LeaveService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      keyword: [null],
      prefix: ['name', Validators.compose([Validators.required])],
    })
  }

  statusType = [
    {
      title: 'Pending Status',
      key: 'pending',
      selected: false,
      disabled: false,
    },
    {
      title: 'Approved Status',
      key: 'approved',
      selected: false,
      disabled: false,
    },
    {
      title: 'Rejected Status',
      key: 'rejected',
      selected: true,
      disabled: false,
    },
  ]

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '70px',
    },
    {
      name: 'Applied At',
      key: 'created_at',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '190px',
    },
    {
      name: 'Applied By',
      key: 'created_by',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Substitute',
      key: 'substitute',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Leave Notes',
      key: 'leave_notes',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Status',
      key: 'status',
      sortOrder: null,
      sortFn: false,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    // {
    //   name: 'Status Notes',
    //   key: 'status_notes',
    //   sortOrder: null,
    //   sortFn: true,
    //   listOfFilter: [],
    //   filterFn: null,
    //   left: false,
    //   right: false,
    //   width: '200px',
    // },
  ]

  ngOnInit(): void {
    this.fetchLeave()
  }

  fetchLeave() {
    this.isLoading = true
    this.leaveService.listLeave(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      this.listOfData = r.data
      this.totalData = r.total
      this.head = r.data.head
    })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchLeave()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.fetchLeave()
  }

  reset() {
    this.form.reset()
    this.date.reset(new Date())
    this.form.patchValue({ prefix: null })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchLeave()
  }

  changeType(event: any) {
    var filterObj = {
      field: '',
      keyword: '',
    }
    if (event.key == 'approved') {
      ;(filterObj.field = 'statusType'), (filterObj.keyword = 'true')
    } else if (event.key == 'rejected') {
      ;(filterObj.field = 'statusType'), (filterObj.keyword = 'false')
    } else if (event.key == 'pending') {
      ;(filterObj.field = 'statusType'), (filterObj.keyword = null)
    }
    var isExist = false
    for (let i = 0; i < this.queryTable.filters.length; i++) {
      if (this.queryTable.filters[i].field == 'statusType') {
        isExist = true
        this.queryTable.filters[i].keyword = filterObj.keyword
      }
    }
    if (!isExist) this.queryTable.filters.push(filterObj)
    this.fetchLeave()
  }

  changeDate() {
    var filterObj = {
      field: '',
      keyword: '',
    }
    if (this.date !== null) {
      ;(filterObj.field = 'date'), (filterObj.keyword = this.date.value)
    }
    var isExist = false
    for (let i = 0; i < this.queryTable.filters.length; i++) {
      if (this.queryTable.filters[i].field == 'date') {
        isExist = true
        this.queryTable.filters[i].keyword = filterObj.keyword
      }
    }
    if (!isExist) this.queryTable.filters.push(filterObj)
    this.fetchLeave()
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  async viewLeave(id: string, head: boolean) {
    const modal = this.modal.create({
      nzTitle: 'View Leave Request',
      nzContent: EditLeaveComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        id,
        head,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Update',
      nzOkDisabled: true,
      nzOnCancel: () => {
        console.log('CANCEL')
      },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve
          } else {
            reject
          }
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchLeave()
    })
  }
}
