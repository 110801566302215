import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { Training } from 'src/app/services/interface/training.model'
import { QueryTable } from 'src/app/services/interface/general.model'
import { NzTableFilterList, NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { CMSService } from 'src/app/services/cms.service'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-training-views',
  templateUrl: './training-views.component.html',
})
export class TrainingViewsComponent implements OnInit {
  listOfData: Training[] = []

  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  form: FormGroup
  constructor(private cmsService: CMSService, private fb: FormBuilder) {
    this.form = this.fb.group({
      keyword: [null],
      // prefix: ['title', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    //   {
    //     name: 'Actions',
    //     key: 'actions',
    //     sortOrder: null,
    //     sortFn: null,
    //     listOfFilter: [],
    //     filterFn: null,
    //     left: true,
    //     right: false,
    //     width: '90px',
    //   },
    {
      name: 'Date Viewed',
      key: 'createdAt',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '190px',
    },
    {
      name: 'Title',
      key: 'title',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    // {
    //   name: '',
    //   key: 'training_id',
    //   sortOrder: null,
    //   sortFn: true,
    //   listOfFilter: [],
    //   filterFn: null,
    //   left: false,
    //   right: false,
    //   width: '200px',
    // },
    {
      name: 'Agent ID',
      key: 'agent_id',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Agent Name',
      key: 'full_name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Email',
      key: 'email',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '250px',
    },
  ]

  ngOnInit(): void {
    this.fetchViews()
  }

  fetchViews() {
    this.isLoading = true
    this.cmsService.getViews(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count
    })
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchViews()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: null,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.fetchViews()
  }

  reset() {
    this.form.reset()
    // this.form.patchValue({ prefix: 'title' })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchViews()
  }
}
