import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'
import store from 'store'
import { EmployeeForm, Placement, PlacementAdd } from './interface/employee.model'
import { PositionForm } from './interface/position.model'
import { QueryTable } from './interface/general.model'
import { LocationForm } from './interface/location-management.model'

@Injectable({
  providedIn: 'root',
})
export class HrService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get customHeader() {
    const accessToken = store.get('accessToken')
    return new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    })
  }

  //NOTE: API Employee Management
  shiftOptionLoad(value: string, type: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/employee/autocomplete',
      { value, type },
      params,
    )
  }

  getAgentLocation(location_name: string): Observable<any> {
    const accessToken =
      'b3NhczokMmEkMDgkYnA5TTR1Tmk0SGJHOUNGM2d4bDhUTy9xaWlIaFNHeFAuLk9RLktva09tdXAuSTE1WG9ZNDI='
    const params = accessToken
      ? {
          headers: {
            Authorization: `Basic ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_OSAS_SERVER + '/account/linked/find-agent-location',
      { location_name },
      params,
    )
  }

  rolesFromOsas(): Observable<any> {
    const accessToken =
      'b3NhczokMmEkMDgkYnA5TTR1Tmk0SGJHOUNGM2d4bDhUTy9xaWlIaFNHeFAuLk9RLktva09tdXAuSTE1WG9ZNDI='
    const params = accessToken
      ? {
          headers: {
            Authorization: `Basic ${accessToken}`,
          },
        }
      : {}
    return this.http.get(this.apiService.API_OSAS_SERVER + '/account/linked/roles', params)
  }

  createEmployee(form: EmployeeForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/employee/create',
      form,
      params,
    )
  }

  updateEmployee(form: EmployeeForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/employee/update',
      form,
      params,
    )
  }

  getEmployee(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/employees',
      queryTable,
      params,
    )
  }

  detailEmployee(code: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            code: code,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/employee/detail', params)
  }

  deleteEmployee(tag: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/employee/delete',
      { tag: tag },
      params,
    )
  }

  exportEmployee(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/employee/export',
      queryTable,
      params,
    )
  }

  getEmployeePlacement(): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/employee/placement', params)
  }

  addEmployeePlacement(form: PlacementAdd): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/employee/placement',
      form,
      params,
    )
  }

  editEmployeePlacement(form: PlacementAdd): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.put(
      this.apiService.API_SUNSHINE_SERVER + '/api/employee/placement',
      form,
      params,
    )
  }

  deleteEmployeePlacement(code_value: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.delete(
      this.apiService.API_SUNSHINE_SERVER + '/api/employee/placement?code=' + code_value,
      params,
    )
  }

  //NOTE: API Attendance Management

  getAttendance(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/attendance/report/get-data',
      queryTable,
      params,
    )
  }

  exportAttendance(date: any): Observable<any> {
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/attendance/report/export', {
      headers: this.customHeader,
      params: {
        date: date,
      },
    })
  }

  exportAttendanceByMonth(date: any): Observable<any> {
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/attendance/report/export-month',
      {
        headers: this.customHeader,
        params: {
          date: date,
        },
      },
    )
  }

  attendancePersonal(code: string, formattedDate: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/attendance/personal',
      { date: formattedDate, employeeCode: code },
      params,
    )
  }

  //NOTE: API Position Management

  createPosition(form: PositionForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/position/create',
      form,
      params,
    )
  }

  updatePosition(form: PositionForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/position/edit', form, params)
  }

  getPosition(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/position/list',
      queryTable,
      params,
    )
  }

  detailPosition(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/position/detail', params)
  }

  deletePosition(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/position/delete',
      { id: id },
      params,
    )
  }

  headOption(value: string, type: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/position/head-list',
      { value, type },
      params,
    )
  }

  //NOTE: API Location Management

  getLocation(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/attendance/location-management/get-data',
      queryTable,
      params,
    )
  }

  createLocation(form: LocationForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/attendance/location-management/create',
      form,
      params,
    )
  }

  // deleteLocation(id: number): Observable<any> {
  //   return this.http.delete(
  //     this.apiService.API_SUNSHINE_SERVER + '/api/attendance/location-management/delete' + id,
  //     { headers: this.getCustomHeaders() },
  //   )
  // }

  exportLocation(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/location/export',
      queryTable,
      params,
    )
  }
}
