<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table #fixedTable [nzShowSizeChanger]="false" class="table-driver" [nzData]="listOfData"
          [nzFrontPagination]="false" [nzLoading]="isLoading" [nzTotal]="totalData" [nzPageSize]="pageSize"
          [nzPageIndex]="pageIndex" (nzQueryParams)="onQueryParamsChange($event)" [nzScroll]="{ y: '42vh' }"
          [nzTitle]="headerTable" nzSize="small">
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <th *ngIf="column.sortFn" [nzLeft]="column.left" [nzRight]="column.right" [nzSortFn]="column.sortFn"
                  [(nzSortOrder)]="column.sortOrder" [nzColumnKey]="column.key" [nzWidth]="column.width">
                  {{ column.name }}
                </th>
                <th *ngIf="!column.sortFn" [nzLeft]="column.left" [nzRight]="column.right" [nzColumnKey]="column.key"
                  [nzWidth]="column.width">
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let data of fixedTable.data">
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <td *ngIf="column.key == 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Delete Warehouse"
                    *ngIf="role == 'head_hr' || role == 'administrator'">
                    <a class="btn btn-sm btn-light mr-2" (click)="deleteWh(data.whId)">
                      <i class="fe fe-trash-2"></i>
                    </a>
                  </span>
                </td>
                <td *ngIf="column.key != 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  <ng-container *ngIf="column.key === 'status'; else notStatus">
                    <span [ngClass]="[
                        data[column.key] === true
                        ? 'font-size-12 badge badge-success'
                          : data[column.key] === false
                          ? 'font-size-12 badge badge-warning'
                          : data[column.key] === 'Unavailable'
                          ? 'font-size-12 badge badge-danger'
                          : 'font-size-12 badge badge-success'
                      ]">
                      {{ data[column.key] === true ? 'Available' : 'Currently In-Use' }}
                    </span>
                  </ng-container>
                  <ng-template #notStatus>
                    {{ data[column.key] }}
                  </ng-template>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<ng-template #tplFooter let-ref="modalRef">
  <button nz-button (click)="ref.destroy()" [disabled]="ref.getContentComponent().isLoading" nzDanger>
    Cancel
  </button>
  <button nz-button nzType="primary" (click)="ref.getContentComponent().submitForm()"
    [nzLoading]="ref.getContentComponent().isLoading">
    Create
  </button>
</ng-template>

<ng-template #headerTable>
  <nz-spin [nzSpinning]="isDownloading">
    <div class="row justify-content-between">
      <div class="col">
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Add New Warehouse">
          <a class="btn btn-lg btn-light mr-2" (click)="addWh()">
            <i class="fe fe-plus-circle"></i>
          </a>
        </span>
        <span [ngClass]="'font-size-12 badge badge-success'">
          Available
        </span>
        <span>
          |
        </span>
        <span [ngClass]="'font-size-12 badge badge-warning'">
          Currently In-Use
        </span>
      </div>
      <div class="col">
        <form nz-form [formGroup]="form" (ngSubmit)="search()" class="float-right">
          <div formArrayName="filters" *ngFor="let filter of advancedFilters.controls; let i = index">
            <div nz-form [formGroupName]="i" [nzLayout]="'inline'">
              <nz-form-item>
                <nz-form-control>
                  <nz-select id="prefix" formControlName="prefix" nzPlaceHolder="Select Prefix ..."
                    style="width: 150px;">
                    <nz-option nzValue="whId" nzLabel="Warehouse ID"></nz-option>
                    <nz-option nzValue="whName" nzLabel="Warehouse Name"></nz-option>
                    <nz-option nzValue="whType" nzLabel="Warehouse Type"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control>
                  <ng-container *ngIf="
                      [
                        'whId',
                        'whName',
                        'whType'
                      ].includes(advancedFilters.at(0).get('prefix').value)
                    ">
                    <input formControlName="keyword" nz-input placeholder="Keyword ..." />
                  </ng-container>
                  <ng-container *ngIf="['updatedAt',].includes(advancedFilters.at(0).get('prefix').value)">
                    <nz-range-picker formControlName="keyword"></nz-range-picker>
                  </ng-container>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-control>
                  <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
                    Search
                  </button>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </div>
  </nz-spin>
</ng-template>
<router-outlet></router-outlet>