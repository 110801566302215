<form nz-form [formGroup]="form">
  <div class="row">
    <div class="marginBot col-lg-6 col-md-12" style="margin-left: 10px;">
      <h3 class="title-font">Title</h3>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input title correctly!">
          <input class="content" nz-input id="'title'" formControlName="title" placeholder="Input Career title.." />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="col marginBot">
    <h3 class="title-font">Upload an Icon</h3>

    <div class="col-lg-12 col-md-12">
      <nz-upload nzListType="picture-card" [(nzFileList)]="fileList" [nzPreview]="handlePreview"
        [nzDownload]="handleDownload" [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq"
        [nzAccept]="'image/png'" [nzFileType]="'image/png'" [nzDisabled]="fileList.length > 0"
        [nzShowButton]="!(fileList.length > 0)" (nzChange)="handleChange($event)">
        <div>
          <i nz-icon nzType="plus"></i>
          <div style="margin-top: 8px">Upload</div>
        </div>
      </nz-upload>
      <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
        (nzOnCancel)="previewVisible = false">
        <ng-template #modalContent>
          <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
        </ng-template>
      </nz-modal>
    </div>
  </div>
  <div class="marginBot col-lg-6 col-md-12">
    <h3 class="title-font">Description</h3>
    <textarea class="content" formControlName="description" nz-input placeholder="Input Description"
      [nzAutosize]="{ minRows: 1, maxRows: 5 }"></textarea>
  </div>

  <div class="widthContainer" style="margin-left: 15px;">
    <h3 class="title-font">Publishing Status</h3>
    <nz-form-item>

      <nz-form-control nzErrorTip="Select available mode!">
        <nz-select formControlName="status" nzPlaceHolder="Choose Publishing Status">
          <nz-option nzLabel="Publish" [nzValue]="true"></nz-option>
          <nz-option nzLabel="Draft" [nzValue]="false"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>