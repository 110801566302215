<form nz-form [formGroup]="form">
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <nz-form-item>
        <nz-form-label nzFor="code" class="text-left">Code</nz-form-label>
        <nz-form-control nzErrorTip="Please input Code correctly!">
          <input nz-input id="code" formControlName="code" placeholder="Input Code ..." />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-12 col-md-12">
      <nz-form-item>
        <nz-form-label nzFor="name" class="text-left">Name</nz-form-label>
        <nz-form-control nzErrorTip="Please input Name correctly!">
          <input nz-input id="name" formControlName="name" placeholder="Input Name ..." />
        </nz-form-control>
      </nz-form-item>
    </div>
    <!-- <div class="col-lg-12 col-md-12">
      <nz-form-item>
        <nz-form-label nzFor="status" class="text-left">Status</nz-form-label>
        <nz-form-control nzErrorTip="Please select status!">
          <nz-select
            id="status"
            formControlName="status"
            nzPlaceHolder="Select Status ..."
          >
            <nz-option [nzValue]="true" [nzLabel]="'Enable'"></nz-option>
            <nz-option [nzValue]="true" [nzLabel]="'Disable'"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div> -->
  </div>
</form>
