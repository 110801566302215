<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Basic Header'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-antd-8></vb-tables-antd-8></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12"></div>
    <div class="col-lg-4 col-md-12"></div>
    <div class="col-lg-4 col-md-12"></div>
  </div>
</div>
