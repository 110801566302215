import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { HrService } from 'src/app/services/hr.service'
import { LocationForm } from 'src/app/services/interface/location-management.model'

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss'],
})
export class AddLocationComponent implements OnInit {
  isLoading: boolean = false

  constructor(
    private fb: FormBuilder,
    private hrService: HrService,
    private modal: NzModalRef,
    private notification: NzNotificationService,
    private msg: NzMessageService,
  ) {
    this.form = this.fb.group({
      city: [null, Validators.compose([Validators.required])],
      locationName: [null, Validators.compose([Validators.required])],
      address: [null, Validators.compose([Validators.required])],
      phone: [null],
      mapLinks: [null],
      lat: [null],
      long: [null],
    })
  }

  form: FormGroup

  ngOnInit(): void {
    this.formChange()
  }

  formChange() {
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    if (this.form.valid) {
      var formSubs: LocationForm = this.form.value
      // console.log(formSubs)
      this.hrService.createLocation(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.modal.destroy()
          this.isLoading = false
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          // console.log(err)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }
}
