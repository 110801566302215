import { Injectable } from '@angular/core'

export interface NotificationSystem {
  brandingApproval: {
    todo: number
    completed: number
    cc: number
    activeAdmin: number
    completedAdmin: number
  }
  inventory: {
    PR: number
    ITOUT: number
    ITIN: number
    MD: number
    MR: number
  }
}

@Injectable()
export class Globals {
  roles: string = ''
  employeeArea: string = ''
  photoUrl: string = ''
  username: string = ''
  email: string = ''
  employeeCode: string = ''
  role: string = ''
  name: string = ''
  employeeAddress: string = ''
  phone: string = ''
  position: string = ''
  notif: NotificationSystem = {
    brandingApproval: {
      todo: 0,
      completed: 0,
      cc: 0,
      activeAdmin: 0,
      completedAdmin: 0,
    },
    inventory: {
      PR: 0,
      ITOUT: 0,
      ITIN: 0,
      MD: 0,
      MR: 0,
    },
  }
}
