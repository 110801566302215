import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { TicketingService } from 'src/app/services/ticketing.service'
import { customAlphabet } from 'nanoid'
import {
  NzTableQueryParams,
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzResizeEvent } from 'ng-zorro-antd/resizable'
import { Driver } from 'src/app/services/interface/driver.model'
import { FilterObj, QueryTable } from 'src/app/services/interface/general.model'
import { FleetService } from 'src/app/services/fleet.service'
import { CMSService } from 'src/app/services/cms.service'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}
@Component({
  selector: 'app-waybill-lottery',
  templateUrl: './waybill-lottery.component.html',
})
export class WaybillLotteryComponent implements OnInit {
  listOfData: Driver[] = []
  date
  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  form: FormGroup

  statusFiltered: string = 'Total'
  isDownloading: boolean = false
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private cmsService: CMSService,
    private fb: FormBuilder,
    private store: Store<any>,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      filters: this.fb.array([
        this.fb.group({
          prefix: ['waybill', Validators.compose([Validators.required])],
          keyword: [null],
        }),
      ]),
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.role = state.roleCode
      // console.log(this.role)
    })
  }

  role: string

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Waybill Number',
      key: 'waybill_number',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Date Entry',
      key: 'created_at',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
  ]

  ngOnInit(): void {
    this.fetchLottery()
    // console.log(this.advancedFilters.at(0).get('prefix').value)
  }

  fetchLottery() {
    this.isLoading = true
    if (this.queryTable.filters?.length == 0) {
      this.queryTable.filters = null
    }

    // console.log('APAPAPAPAPAPAPp')
    // console.log(this.queryTable)
    // console.log(this.advancedFilters.at(0).value)
    this.cmsService.waybillLotteryList(this.queryTable).subscribe((r: any) => {
      // console.log('QTQTQTQTQTYQTQTQTQT')
      // console.log(r)

      this.isLoading = false
      this.listOfData = r.data
      this.totalData = r.count
    })
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  get advancedFilters(): FormArray {
    return this.form.get('filters') as FormArray
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    // console.log(params)
    // console.log(this.pageIndex, this.pageSize)
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchLottery()
  }

  search() {
    // console.log('qpqpqpqpqpqpqpqp')
    // console.log(this.advancedFilters.controls[0].value.keyword)
    // console.log(this.advancedFilters.controls[0].value.prefix)
    // console.log(this.queryTable.filters)
    if (this.advancedFilters.controls[0].value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.advancedFilters.controls[0].value.prefix,
          keyword: this.advancedFilters.controls[0].value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    // console.log(this.queryTable)
    this.fetchLottery()
  }
}
