import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ConsoleSqlOutline } from '@ant-design/icons-angular/icons'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzUploadFile } from 'ng-zorro-antd/upload'
import { updateProfile, userInfo, userList } from 'src/app/models/freelance-marketing.models'
import { FreelanceMarketingService } from 'src/app/services/freelance-management/freelance-marketing.service'

@Component({
  selector: 'app-existing',
  templateUrl: './existing.component.html',
  styleUrls: ['./existing.component.scss'],
})
export class ExistingComponent implements OnInit {
  @Input() data: userList[]

  totalData: number = 0
  pageSize: number = 10
  pageIndex: number = 1

  isLoading: boolean
  isModalEditConfirm: boolean
  isModalEditVisible: boolean
  isSwitchLoading: boolean

  profile: FormGroup
  pictureList: NzUploadFile[] = []
  idCardList: NzUploadFile[] = []

  previewImage: string | undefined = ''
  previewVisible = false

  constructor(
    private freelanceMarketingService: FreelanceMarketingService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private modal: NzModalService,
  ) {
    this.profile = this.fb.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      gender: ['', Validators.compose([Validators.required])],
      dateOfBirth: ['', Validators.compose([Validators.required])],
      placeOfBirth: ['', Validators.compose([Validators.required])],
      phoneNumber: [null, Validators.compose([Validators.required])],
      address: ['', Validators.compose([Validators.required])],
      citizenNumber: [null, Validators.compose([Validators.minLength(16), Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      company: ['', Validators.compose([Validators.required])],
      bankName: ['', Validators.compose([Validators.required])],
      accountBankName: ['', Validators.compose([Validators.required])],
      accountNumber: ['', Validators.compose([Validators.required])],
      active: [0],
      id: [''],
      avatar: [''],
      idCard: [''],
    })
  }

  listOfColumns = [
    {
      name: 'Actions',
      key: 'actions',
      left: false,
      right: false,
      width: '125px',
    },
    {
      name: 'Agent Id',
      key: 'agent_id',
      left: false,
      right: false,
      width: '230px',
      compare: (a: userList, b: userList) => a.agent_id.localeCompare(b.agent_id),
      priority: false,
    },
    {
      name: 'Status',
      key: 'status',
      left: false,
      right: false,
      width: '230px',
      compare: (a: userList, b: userList) => a.status.localeCompare(b.status),
      priority: false,
    },
    {
      name: 'Name',
      key: 'name',
      left: false,
      right: false,
      width: '230px',
      compare: (a: userList, b: userList) => a.name.localeCompare(b.name),
      priority: false,
    },
    {
      name: 'Phone Number',
      key: 'phone_number',
      left: false,
      right: false,
      width: '230px',
      compare: (a: userList, b: userList) => a.phone_number.localeCompare(b.phone_number),
      priority: false,
    },
    {
      name: 'Email',
      key: 'email',
      left: false,
      right: false,
      width: '230px',
      compare: (a: userList, b: userList) => a.email.localeCompare(b.email),
      priority: false,
    },
    {
      name: 'Accept Reason',
      key: 'accept_reason',
      left: false,
      right: false,
      width: '250px',
      compare: (a: userList, b: userList) => a.accept_reason.localeCompare(b.accept_reason),
      priority: false,
    },
  ]

  ngOnInit(): void {
    this.totalData = this.data.length
    // console.log(this.data)
  }

  refreshData(): void {
    this.fetchFreelanceList('existing')
  }

  fetchFreelanceList(progress: string): void {
    this.isLoading = true
    this.freelanceMarketingService.fetchUser(progress).subscribe(
      (res: userList[]) => {
        this.isLoading = false
        this.data = res
        this.totalData = res.length
      },
      error => {
        console.error(error)
      },
    )
  }

  handleView(data: userList): void {
    // console.log(data.agent_id)
    this.isLoading = true
    this.profile.get('active')?.setValue(data.active)
    this.profile.get('id')?.setValue(data.agent_id)
    this.freelanceMarketingService.fetchUserInfo(data.agent_id).subscribe(
      (res: userInfo) => {
        this.pictureList = [
          {
            uid: '-1',
            name: data.agent_id,
            status: 'done',
            url: res.avatar,
          },
        ]
        this.profile.get('avatar')?.setValue(res.avatar)
        this.profile.get('firstName')?.setValue(res.first_name)
        this.profile.get('lastName')?.setValue(res.last_name)
        this.profile.get('gender')?.setValue(res.gender)
        this.profile
          .get('dateOfBirth')
          ?.setValue(res.birth_date ? new Date(res.birth_date) : res.birth_date)
        this.profile.get('placeOfBirth')?.setValue(res.birth_place)
        this.profile.get('phoneNumber')?.setValue(res.phone_number.slice(2))
        this.profile.get('address')?.setValue(res.address)
        this.profile.get('citizenNumber')?.setValue(res.citizen_number)
        this.profile.get('email')?.setValue(res.email)
        this.profile.get('company')?.setValue(res.company)
        this.profile.get('bankName')?.setValue(res.bank_info.bank_name)
        this.profile.get('accountBankName')?.setValue(res.bank_info.account_name)
        this.profile.get('accountNumber')?.setValue(res.bank_info.account_number)
        this.profile.get('idCard')?.setValue(res.id_card)

        this.fetchIdCard()
        this.isLoading = false
        this.isModalEditVisible = true
      },
      error => {
        console.log(error)
        this.isLoading = false
        this.notification.error('Error', error.error.message)
      },
    )
  }

  fetchIdCard(): void {
    if (!this.profile.value.idCard) return
    // console.log(this.profile.value.idCard)
    this.freelanceMarketingService.getFile(this.profile.value.idCard).subscribe(
      (res: Blob) => {
        const mimetype = res.type
        const blob = new Blob([res], { type: mimetype })
        const url = window.URL.createObjectURL(blob)
        // console.log(url)
        this.convertBlobToDataUrl(url, dataUrl => {
          // console.log(dataUrl)
          this.idCardList = [
            {
              uid: '1',
              name: this.profile.value.id,
              status: 'done',
              url: dataUrl,
            },
          ]
        })
      },
      err => {
        console.error(err)
      },
    )
  }

  convertBlobToDataUrl(blobUrl: string, callback: (dataUrl: string) => void) {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = () => {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (reader.result) {
          callback(reader.result.toString())
        }
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', blobUrl)
    xhr.send()
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await file.originFileObj
    }
    this.previewImage = file.url || file.preview
    this.previewVisible = true
  }

  customDownload = (item: NzUploadFile) => {
    // Here, you can implement your download logic
    // Replace this with the actual download logic for your application

    // You can create an anchor element and trigger a click event to initiate the download
    const downloadLink = document.createElement('a')
    downloadLink.href = item.url // Set the URL to the file you want to download
    downloadLink.target = '_blank' // Open in a new tab

    // Create a mouse click event
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    })

    // Dispatch the click event on the anchor element
    downloadLink.dispatchEvent(clickEvent)

    // Remove the anchor element
    document.body.removeChild(downloadLink)
  }

  handleEdit(): void {
    this.isModalEditConfirm = true
    const payload: updateProfile = {
      firstName: this.profile.value.firstName,
      lastName: this.profile.value.lastName,
      gender: this.profile.value.gender,
      birthPlace: this.profile.value.placeOfBirth,
      birthDate: this.profile.value.dateOfBirth,
      phoneNumber: this.profile.value.phone_number,
      address: this.profile.value.address,
      citizenNumber: String(this.profile.value.citizenNumber),
      email: this.profile.value.email,
      company: this.profile.value.company,
      bankName: this.profile.value.bankName,
      accountName: this.profile.value.accountBankName,
      accountNumber: this.profile.value.accountNumber,
    }

    // console.log(payload)
    this.freelanceMarketingService.updateInfo(this.profile.value.id, payload).subscribe(
      res => {
        this.notification.success('Success', res.message)
        this.isModalEditConfirm = false
        this.isModalEditVisible = false
      },
      err => {
        console.error(err)
        this.notification.error('Error', err.error.message)
        this.isModalEditConfirm = false
      },
    )
  }

  accountStateChange(event: any): void {
    // console.log(event)

    this.isSwitchLoading = true

    setTimeout(() => {
      const status = event ? 'Active' : 'Inactive'
      this.freelanceMarketingService.changeStatus(this.profile.value.id, status).subscribe(
        res => {
          this.notification.success('Success', res.message)
          this.isSwitchLoading = false
          this.refreshData()
        },
        error => {
          this.isSwitchLoading = false
          this.profile.get('status').setValue(false)
          this.notification.error('Error', error.error.message)
        },
      )
    }, 500)
  }

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?action=download', '_blank')
  }

  onCancelModal(): void {
    this.refreshData()
    this.isModalEditVisible = false
  }
}
