import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { ShiftService } from 'src/app/services/shift.service'
import { ShiftForm } from 'src/app/services/interface/shift.model'

@Component({
  selector: 'app-add-shift',
  templateUrl: './add-shift.component.html',
})
export class AddShiftComponent implements OnInit {
  form: FormGroup
  isLoading: boolean = false
  val = false
  days = 'Monday'
  idx = 0
  tabs = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  output: any = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  }
  newTabs: any = {
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: null,
  }
  constructor(
    private fb: FormBuilder,
    private shiftService: ShiftService,
    private notification: NzNotificationService,
    private modal: NzModalRef,
  ) {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      output: this.fb.group({
        // timeIn: [null],
        // timeOut: [null],
        // status: [null],
        // holiday: [null],
      }),
    })
    for (let i = 0; i < this.tabs.length; i++) {
      this.outputForm.addControl(
        this.tabs[i],
        this.fb.group({
          timeIn: [null],
          timeOut: [null],
          status: [null],
          holiday: [null],
        }),
      )
    }
  }

  get outputForm() {
    return this.form.get('output') as FormGroup
  }

  ngOnInit(): void {
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  newTab(event) {
    if (this.idx != event.index) {
      this.val = false
    }
    this.idx = event.index
    this.days = event.tab.nzTitle
    this.tabs.map(r => {
      if (r === this.days && this.output[r] != null) {
        if (this.output[r].holiday === true) {
          this.val = true
        }
      }
    })
    // this.form.controls['timeIn'].reset();
    // this.form.controls['timeOut'].reset();
    // this.form.controls['status'].reset();
    // this.form.controls['holiday'].reset();
  }

  cancel(): void {
    this.modal.destroy()
  }

  submitForm() {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
    var formSubs: ShiftForm = this.output
    formSubs.name = this.form.value.name
    this.shiftService.createShift(formSubs).subscribe(
      r => {
        this.notification.success('Success', r.message)
        this.modal.destroy()
        this.isLoading = false
        return true
      },
      err => {
        this.notification.error('Error', err.error.message)
        this.isLoading = false
        this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
        return false
      },
    )
  }

  names(value: any) {
    this.tabs.map(r => {
      if ((this.newTabs[r] = null)) {
        this.output[r] = null
      }
    })
  }

  checked(value: any) {
    if (value != 'true') {
      this.val = false
    } else {
      this.val = true
    }

    this.tabs.map(r => {
      if ((r = this.days)) {
        this.output[r] = {
          timeIn: null,
          timeOut: null,
          status: null,
          holiday: this.val,
        }
        this.newTabs[r] = this.val
      }
    })
    // this.form.controls['timeIn'].reset();
    // this.form.controls['timeOut'].reset();
    // this.form.controls['status'].reset();
  }

  time_in(value: any) {
    this.tabs.map(r => {
      if ((r = this.days)) {
        this.output[r] = {
          timeIn: this.outputForm.value[r].timeIn,
          timeOut: this.outputForm.value[r].timeOut,
          status: this.outputForm.value[r].status,
          holiday: this.val,
        }
        this.newTabs[r] = this.val
      }
    })
  }

  time_out(value: any) {
    this.tabs.map(r => {
      if ((r = this.days)) {
        this.output[r] = {
          timeIn: this.outputForm.value[r].timeIn,
          timeOut: this.outputForm.value[r].timeOut,
          status: this.outputForm.value[r].status,
          holiday: this.val,
        }
        this.newTabs[r] = this.val
      }
    })
  }

  clicked(value: any) {
    this.tabs.map(r => {
      if ((r = this.days)) {
        this.output[r] = {
          timeIn: this.outputForm.value[r].timeIn,
          timeOut: this.outputForm.value[r].timeOut,
          status: this.outputForm.value[r].status,
          holiday: this.val,
        }
        this.newTabs[r] = this.val
      }
    })
  }
}
