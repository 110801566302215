import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { ApiService } from 'src/app/services/api.service'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  authorized: boolean

  constructor(private store: Store<any>, public router: Router, private apiService: ApiService) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.authorized = state.authorized
    })
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // if (environment.authenticated) {
    //   // skip guard checking on demo environment serve/build, remove it in your app
    //   return true
    // }

    if (this.authorized) {
      return true
    }

    if (localStorage.getItem('pageSizeList') == null) {
      var obj = {
        'area-list': '',
        'large-area-list': '',
        'store-list': '',
        'supplier-list': '',
        'employee-list': '',
        'warehouse-list': '',
        'material-list': '',
        'todotask-ba-list': '',
        'completed-ba-list': '',
        'cctome-ba-list': '',
        'realtime-inventory': '',
        'active-admin-ba-list': '',
        'completed-admin-ba-list': '',
        'purchasing-order-list': '',
        'item-transfer-out-list': '',
        'item-transfer-in-list': '',
        'miscellaneous-delivery-list': '',
        'miscellaneous-receipt-list': '',
      }
      localStorage.setItem('pageSizeList', JSON.stringify(obj))
    }

    var questionMarkUrl = state.url
    var newUrlQuestionMark = questionMarkUrl.split('?')
    newUrlQuestionMark.pop()
    if (newUrlQuestionMark[0] == undefined) {
      this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } })
    } else {
      this.router.navigate(['auth/login'], { queryParams: { returnUrl: newUrlQuestionMark[0] } })
    }

    return true
  }
}
