import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { MeetingRoomService } from 'src/app/services/meeting-room.service'
import { MeetingRoomForm } from 'src/app/services/interface/meeting-room.model'

@Component({
  selector: 'app-edit-room',
  templateUrl: './edit-room.component.html',
  styleUrls: ['./edit-room.component.scss'],
})
export class EditRoomComponent implements OnInit {
  @Input() id: string
  form: FormGroup
  isLoading: boolean = false

  constructor(
    private fb: FormBuilder,
    private meetingRoomService: MeetingRoomService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
  ) {
    this.form = this.fb.group({
      id: [null],
      name: [null, Validators.compose([Validators.required])],
      capacity: [null, Validators.compose([Validators.required, Validators.min(1)])],
      status: [null, Validators.compose([Validators.required])],
      notes: [null],
    })
  }

  ngOnInit(): void {
    this.fetchMeetingRoomDetails()
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  fetchMeetingRoomDetails() {
    this.isLoading = true
    this.meetingRoomService.viewdetailMeetingRoom(this.id).subscribe(
      r => {
        this.form.patchValue(r)
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }

    if (this.form.valid) {
      var formSubs: MeetingRoomForm = this.form.value
      this.meetingRoomService.updateMeetingRoom(formSubs).subscribe(
        r => {
          this.notification.success('Success', 'Successfully Updating Room')
          this.modal.destroy()
          this.isLoading = false
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }
}
