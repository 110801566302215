<nz-spin [nzSpinning]="isLoading">
    <form nz-form [formGroup]="form">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <nz-form-item>
                    <nz-form-label nzFor="name" class="text-left">Name</nz-form-label>
                    <nz-form-control>
                        <input readonly class="content" nz-input id="'name'" formControlName="name" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
                <nz-form-item>
                    <nz-form-label nzFor="code" class="text-left">Employee Code</nz-form-label>
                    <nz-form-control>
                        <input readonly class="content" nz-input id="'code'" formControlName="code" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
                <nz-form-item>
                    <nz-form-label nzFor="days" class="text-left">Leave Days</nz-form-label>
                    <nz-form-control nzErrorTip="Please input amount of days!">
                        <input class="content" readonly nz-input type="number" min="1" id="'days'"
                            formControlName="days" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="substitute" class="text-left">Substitute</nz-form-label>
                    <nz-form-control nzErrorTip="Please choose a substitute!">
                        <input class="content" nz-input id="substitute" readonly formControlName="substitute" />
                        <!-- <nz-select nzShowSearch nzServerSearch nzAllowClear id="substitute" formControlName="substitute"
                            nzPlaceHolder="Choose a Substitute">
                            <ng-container *ngFor="let item of subsArr">
                                <nz-option *ngIf="!isLoadingOptions" [nzValue]="item.name" [nzLabel]="item.name">
                                </nz-option>
                            </ng-container>
                            <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                                <i nz-icon nzType="loading" class="loading-icon"></i>
                                Loading Data...
                            </nz-option>
                        </nz-select> -->
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12" style="overflow-y:scroll;height: 100px;">
                <ng-container formArrayName="date">
                    <div *ngFor="let _ of dateArray.controls; index as i">
                        <nz-form-item>
                            <nz-form-label nzFor="date" class="text-left">Date</nz-form-label>
                            <nz-form-control nzErrorTip="Please choose correct date!">
                                <nz-date-picker nzDisabled [formControlName]="i" nzFormat="dd-MM-yyyy"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </ng-container>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="leave_notes" class="text-left">Leave Notes</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid leave notes">
                        <nz-textarea-count>
                            <textarea rows="4" readonly formControlName="leave_notes" nz-input></textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div *ngIf="(((head === true) || (head_status !== 'Pending')) && (applicantHead !== true)); else wait4Head" class="row">
            <nz-divider></nz-divider>
            <div class="col-12">
                <h5>Approval By Head</h5>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="head_notes" class="text-left">Status Notes</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid reason for status">
                        <div *ngIf="head_status !== 'Pending'; else elseHere">
                            <nz-textarea-count>
                                <textarea rows="4" readonly formControlName="head_notes" nz-input></textarea>
                            </nz-textarea-count>
                        </div>
                        <ng-template #elseHere>
                            <nz-textarea-count>
                                <textarea rows="4" formControlName="head_notes" nz-input></textarea>
                            </nz-textarea-count>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-3">
                <nz-form-item>
                    <nz-form-label nzFor="head_status" class="text-left">Status</nz-form-label>
                    <nz-form-control nzErrorTip="Please select available status">
                        <div *ngIf="head_status !== 'Pending'; else elseHeree">
                            <input class="content" nz-input id="head_status" readonly
                                formControlName="head_status" />
                        </div>
                        <ng-template #elseHeree>
                            <nz-select formControlName="head_status" [nzPlaceHolder]="head_status">
                                <nz-option *ngFor="let item of statusArr" [nzLabel]="item.name" [nzValue]="item.id">
                                </nz-option>
                            </nz-select>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <ng-template #wait4Head>
            <div *ngIf="(applicantHead !== true)">
                <nz-divider nzText="Awaiting Approval from Head"></nz-divider>
            </div>
        </ng-template>
        <div *ngIf="((hr === true) && (((applicantHead !== true) && (head_status !== 'Pending')) || (applicantHead === true))) || ((hr !== true) && (hr_status !== 'Pending')); else wait4Hr"
            class="row">
            <nz-divider></nz-divider>
            <div class="col-12">
                <h5>Approval By HR</h5>
            </div>
            <div class="col-lg-6 col-md-12">
                <nz-form-item>
                    <nz-form-label nzFor="hr_notes" class="text-left">Status Notes</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid reason for status">
                        <div *ngIf="hr_status !== 'Pending'; else elseHere">
                            <nz-textarea-count>
                                <textarea rows="4" readonly formControlName="hr_notes" nz-input></textarea>
                            </nz-textarea-count>
                        </div>
                        <ng-template #elseHere>
                            <nz-textarea-count>
                                <textarea rows="4" formControlName="hr_notes" nz-input></textarea>
                            </nz-textarea-count>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-3">
                <nz-form-item>
                    <nz-form-label nzFor="hr_status" class="text-left">Status</nz-form-label>
                    <nz-form-control nzErrorTip="Please select available status">
                        <div *ngIf="hr_status !== 'Pending'; else elseHeree">
                            <input class="content" nz-input id="hr_status" readonly formControlName="hr_status" />
                        </div>
                        <ng-template #elseHeree>
                            <nz-select formControlName="hr_status" [nzPlaceHolder]="hr_status">
                                <nz-option *ngFor="let item of statusArr" [nzLabel]="item.name" [nzValue]="item.id">
                                </nz-option>
                            </nz-select>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-3">
                <div *ngIf="((statusFile === null) && (hr_status === 'Pending')); else elseFile">
                    <nz-form-label nzFor="status_img" class="text-left">Status Image</nz-form-label>
                    <nz-upload nzListType="picture-card" [(nzFileList)]="fileList" [nzPreview]="handlePreview"
                        [nzDownload]="handleDownload" [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq" [nzBeforeUpload]="handleBeforeUpload"
                        [nzAccept]="'image/jpeg, image/jpg, image/png'"
                        [nzFileType]="'image/jpeg, image/jpg, image/png'" [nzDisabled]="fileList.length > 0"
                        [nzShowButton]="!(fileList.length > 0)" (nzChange)="handleChange($event)">
                        <div>
                            <i nz-icon nzType="plus"></i>
                            <div style="margin-top: 8px">Upload</div>
                        </div>
                    </nz-upload>
                    <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                        (nzOnCancel)="previewVisible = false">
                        <ng-template #modalContent>
                            <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                        </ng-template>
                    </nz-modal>
                </div>
                <ng-template #elseFile>
                    <div *ngIf="statusFile !== null">
                        <nz-form-label nzFor="status_img" class="text-left">Status Image</nz-form-label>
                        <nz-upload nzListType="picture-card" [(nzFileList)]="fileList" [nzPreview]="handlePreview"
                            [nzDownload]="handleDownload" [nzAccept]="'image/jpeg, image/jpg, image/png'"
                            [nzFileType]="'image/jpeg, image/jpg, image/png'" [nzDisabled]="fileList.length > 0"
                            [nzShowButton]="!(fileList.length > 0)" [nzShowUploadList]="showUploadList">
                        </nz-upload>
                        <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                            (nzOnCancel)="previewVisible = false">
                            <ng-template #modalContent>
                                <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                            </ng-template>
                        </nz-modal>
                    </div>
                </ng-template>
            </div>
        </div>
        <ng-template #wait4Hr>
            <div *ngIf="((applicantHead !== true) && (head_status !== 'Pending'))">
                <nz-divider nzText="Awaiting Approval from HR"></nz-divider>
            </div>
        </ng-template>
    </form>
    <div *nzModalFooter>
        <div
            *ngIf="((head === true) && (head_status !== 'Pending')) || ((head !== true) && ((hr_status !== 'Pending') || (head_status === 'Pending'))); else elseHereee">
            <button nz-button nzType="default" (click)="cancel()" [nzLoading]="isLoading">Close</button>
        </div>
        <ng-template #elseHereee>
            <button nz-button nzType="default" (click)="cancel()" [nzLoading]="isLoading">Cancel</button>
            <button nz-button nzType="primary" (click)="submitForm()" [nzLoading]="isLoading">Update</button>
        </ng-template>
    </div>
</nz-spin>