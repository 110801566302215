import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ApiService } from '../api.service'
import { Observable } from 'rxjs'
import store from 'store'
import { QueryTable } from '../interface/general.model'
import { VoucherForm } from '../interface/voucher.model'

@Injectable({
  providedIn: 'root',
})
export class NewVoucherService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  getConfigsVoucher(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/onapps/voucher/config',
      queryTable,
      params,
    )
  }

  detailConfigsVoucher(id: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/onapps/voucher/config/details',
      params,
    )
  }

  createVoucherConfig(form: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/onapps/voucher/config/create',
      form,
      params,
    )
  }

  updateVoucherConfig(form: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/onapps/voucher/config/update',
      form,
      params,
    )
  }
}
