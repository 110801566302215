<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table #sortTable class="custom-table" [nzData]="getConfigValue" nzTableLayout="fixed"
          [nzScroll]="{ y: '50vh' }" nzSize="small" [nzPageIndex]="pageIndex" [nzPageSize]="pageSize"
          [nzLoading]="isLoading" [nzTitle]="headerTable">
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumn">
                <th [nzWidth]="column.width" [nzSortFn]="column.compare" [nzSortPriority]="column.priority">
                  {{ column.title }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of sortTable.data">
              <td>
                <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Edit Configuration">
                  <button class="btn btn-sm btn-light mr-2" (click)="edit(data.value,data.code)">
                    <i class="fe fe-more-vertical"></i>
                  </button>
                </span>
                <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Delete Configuration">
                  <button class="btn btn-sm btn-light mr-2" (click)="delete(data.value)">
                    <i class="fe fe-trash-2"></i>
                  </button>
                </span>
                <span style="margin: 0 2px;"></span>
              </td>
              <td>{{ data.value }}</td>
              <td>{{ data.created_by }}</td>
              <td>{{ data.updated_by }}</td>
              <td>{{ data.created_at }}</td>
              <td>{{ data.updated_at }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<ng-template #headerTable>
  <nz-spin [nzSpinning]="isDownloading">
    <div class="row justify-content-between">
      <div class="col">
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Add Configuration Value">
          <a class="btn btn-lg btn-light mr-2" (click)="add()">
            <i class="fe fe-plus-circle"></i>
          </a>
        </span>
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Refresh Data">
          <a class="btn btn-lg btn-light mr-2" (click)="ngOnInit()">
            <i class="fe fe-refresh-cw"></i>
          </a>
        </span>
        <span *ngIf="selectedConfig==='Vendor'" nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Export Vendor">
          <a class="btn btn-lg btn-light mr-2" (click)="exportConfig()">
            <i class="fe fe-download"></i>
          </a>
        </span>
        <nz-upload [nzCustomRequest]="customImport" [(nzFileList)]="importExcelFile"
          (nzChange)="handleChangeImport($event) ">
          <span *ngIf="selectedConfig==='Vendor'" nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Upload">
            <a class="btn btn-lg btn-light mr-2">
              <i class="fe fe-upload"></i>
            </a>
          </span>
        </nz-upload>
      </div>

      <div class="col">
        <form nz-form [nzLayout]="'inline'" [formGroup]="filterForm" (ngSubmit)="apply()"
          style="display: flex; flex-direction: row; justify-content: flex-end; ">
          <nz-form-label nzFor="config" class="text-left">Configuration</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <nz-select id="config" style="width: 120px;" formControlName="config">
                <ng-container *ngFor="let item of configData">
                  <nz-option [nzValue]="item" [nzLabel]="item">
                  </nz-option>
                </ng-container>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <nz-input-group id="search" style="width: 130px;">
                <input type="text" nz-input placeholder="Search" style=" border-radius: 8px;"
                  formControlName="search" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <button type="submit" class="btn btn-primary">
                Apply
              </button>
            </nz-form-control>
          </nz-form-item>
        </form>
      </div>
    </div>
  </nz-spin>
</ng-template>

<nz-modal [(nzVisible)]="isAddConfigValueVisible" [nzTitle]="modalTitle" [nzContent]="modalContent"
  [nzFooter]="modalFooter" (nzOnCancel)="handleAddCancel()">
  <ng-template #modalTitle>Add Configuration</ng-template>

  <ng-template #modalContent>
    <div *ngIf="selectedConfig !=='Vendor' else vendorInput">
      <form nz-form [formGroup]="addForm" (ngSubmit)="apply()">
        <nz-form-label nzFor="new_config" class=" text-left">Configuration Value</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-input-group id="new_config">
              <input type="text" nz-input formControlName="new_config_value" style=" border-radius: 8px;" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-label *ngIf="selectedConfig==='Type' || selectedConfig==='Company'" nzFor="code"
          class=" text-left">Code</nz-form-label>
        <nz-form-item *ngIf="selectedConfig==='Type' || selectedConfig==='Company'">
          <nz-form-control>
            <nz-input-group id="cancelAddInventories">
              <input type="text" nz-input formControlName="code" style=" border-radius: 8px;" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </ng-template>


  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleAddCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="handleAddOk()" [nzLoading]="isAddConfigValueConfirm">Confirm</button>
  </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="isEditConfigValueVisible" [nzTitle]="editModalTitle" [nzContent]="editModalContent"
  [nzFooter]="editModalFooter" (nzOnCancel)="handleEditCancel()">
  <ng-template #editModalTitle>Edit Configuration</ng-template>

  <ng-template #editModalContent>
    <div *ngIf="selectedConfig !=='Vendor' else vendorInput">
      <form nz-form [formGroup]="editForm" (ngSubmit)="apply()">

        <nz-form-label nzFor="new_config" class=" text-left">Configuration Value</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-input-group id="new_config">
              <input type="text" nz-input formControlName="new_config_value" [(ngModel)]="displayedConfigValue"
                style=" border-radius: 8px;" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-label *ngIf="selectedConfig==='Type' || selectedConfig==='Company'" nzFor="code"
          class=" text-left">Code</nz-form-label>
        <nz-form-item *ngIf="selectedConfig==='Type' || selectedConfig==='Company'">
          <nz-form-control>
            <nz-input-group id="cancelAddInventories">
              <input type="text" nz-input formControlName="code" style=" border-radius: 8px;"
                [(ngModel)]="displayedCode" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </ng-template>

  <ng-template #editModalFooter>
    <button nz-button nzType="default" (click)="handleEditCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="handleEditOk()" [nzLoading]="isAddConfigValueConfirm">Confirm</button>

  </ng-template>
</nz-modal>

<ng-template #vendorInput>
  <form nz-form [formGroup]="vendorForm" (ngSubmit)="apply()">
    <div class="row">
      <div class="col">
        <nz-form-label nzFor="company" class=" text-left">Company Name</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-input-group id="company">
              <input type="text" nz-input formControlName="companyName" style=" border-radius: 8px;" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-label nzFor="industry" class=" text-left">Industry</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-input-group id="industry">
              <input type="text" nz-input formControlName="industry" style=" border-radius: 8px;" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-label nzFor="picname" class=" text-left">PIC Name</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-input-group id="picname">
              <input type="text" nz-input formControlName="PICName" style=" border-radius: 8px;" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="col">
        <nz-form-label nzFor="address" class=" text-left">Address</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-input-group id="address">
              <input type="text" nz-input formControlName="address" style=" border-radius: 8px;" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-label nzFor="phone" class=" text-left">Phone</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-input-group id="phone">
              <input type="text" nz-input formControlName="phoneNumber" style=" border-radius: 8px;"
                (input)="formatNumber($event, 'phoneNumber')" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-label nzFor="email" class=" text-left">Email</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <nz-input-group id="email">
              <input type="text" nz-input formControlName="email" style=" border-radius: 8px;" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

  </form>
</ng-template>

<ng-template #toasterConfig>
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon">
        <span nz-icon [nzType]="iconConfigValue" [ngStyle]="{ color: colorConfigValue }"></span>
      </span>
      <div class="ant-notification-notice-message">Message :</div>
      <div class="ant-notification-notice-description">
        {{messageConfigValue}}
      </div>
    </div>
  </div>
</ng-template>