import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'
import store from 'store'
import { MeetingRoomForm } from './interface/meeting-room.model'

@Injectable({
  providedIn: 'root',
})
export class MeetingRoomService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  createMeetingRoom(form: MeetingRoomForm): Observable<any> {
    form.name = form.name.toUpperCase().trim()
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/meeting/add', form, params)
  }

  updateMeetingRoom(form: MeetingRoomForm): Observable<any> {
    form.name = form.name.toUpperCase().trim()
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/meeting/update', form, params)
  }

  viewlistMeetingRoom(query): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/meeting/viewlist',
      query,
      params,
    )
  }

  viewdetailMeetingRoom(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/meeting/viewdetail', params)
  }

  deleteMeetingRoom(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/meeting/delete', params)
  }

  roomOption(): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/meeting/option', params)
  }
}
